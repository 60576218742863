import React from 'react';
import { TProp } from 'store/globalEntities';
import css from './index.module.scss';

type TProps = {
  prop: TProp
};

export const ProductProp = ({ prop }: TProps) => {

  return (
    <div className={css.wrap}>
      <p className={css.value}>
        { prop.value }
        { 
          prop.measureUnit && 
          <span className={css.measureUnit}>
            { ` ${prop.measureUnit}` }
          </span> 
        }
      </p>
      <p className={css.name}>
        { prop.name }
      </p>
    </div>
  );
};
