import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TCatalogSection_Preview } from 'store/slices/catalogAPI/entities';
import { Title, ProductGroupCard, Button } from 'components';
import { ChevronIcon } from 'resources/icons';
import { getOrderNumber } from 'resources/helpers';
import css from './index.module.scss';

type TProps = {
  orderNumber: number,
  section: TCatalogSection_Preview,
  showPreviewOnStart?: boolean,
};

export const CatalogSection = ({ orderNumber, section, showPreviewOnStart = false }: TProps) => {
  const navigate = useNavigate();
  const sectionLink = `/catalog/${section.key}`;

  const order = getOrderNumber(orderNumber);

  const goSectionPage = (url: string) => () => navigate(url);

  return (
    <div className={css.wrap}>
      <header className={css.sectionHeader}>
        <Title level='p' type='base_page' className={css.orderNumber}>{ order }</Title>
        <Link className={css.titleWrap} to={sectionLink}>
          <Title level='h3' type='modal'className={css.title} >
            { section.name }
          </Title>
          <p className={css.subtitle}>{ section.description }</p>
        </Link>
      </header>
      <div className={css.sectionBody}>
        <div className={css.previewRow}>
          {
            !!section.previewGroups.length
            && section.previewGroups.map(group => (
              <ProductGroupCard 
                key={group.id}
                group={group}
                sectionKey={section.key}
              />
            ))
          }
        </div>
        <div className={css.buttons}>
          <Button 
            text='Показать все товары' 
            style='light-accent'
            size='tall'
            onClick={goSectionPage(sectionLink)} 
            maxWidth='100%' 
          />
        </div>
      </div>
    </div>
  );
};
