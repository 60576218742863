import { 
  T_RawResponse_GetCatalogPageData, T_DoneResponse_GetCatalogPageData,
  T_RawResponse_GetCatalogSectionWithKey, T_DoneResponse_GetCatalogSectionWithKey,
} from "./entities";

export const transformGetCatalogPageData = (
  response: T_RawResponse_GetCatalogPageData
): T_DoneResponse_GetCatalogPageData => {

  const transformedResponse: T_DoneResponse_GetCatalogPageData = response.data.map(catalogSection => ({
    id: catalogSection.id, 
    ...catalogSection.attributes,
    previewGroups: catalogSection.attributes.previewGroups.map(group => ({
      ...group,
      image: {
        url: group.image.url,
        thumbnail: group.image.formats.thumbnail.url,
      }
    }))
  }));

  return transformedResponse;
}


export const transformGetCatalogSectionWithKey = (
  response: T_RawResponse_GetCatalogSectionWithKey
): T_DoneResponse_GetCatalogSectionWithKey => {
  
  return response?.data || null;
}