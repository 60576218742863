import { createSlice } from "@reduxjs/toolkit";
import { TUserState } from "./entities";
import { UserSliceActions } from "./actions";

const initialState: TUserState = {
  data: {
    name: '',
    phone: '',
    email: '',
    place: '',
  },
  searchQueries: [],
}

const UserSlice = createSlice({
  name: 'UserSlice',
  initialState,
  reducers: UserSliceActions,
});

export const UserReducer = UserSlice.reducer;
export const { editField, setData, saveSearchQuery, removeSearchQuery } = UserSlice.actions;