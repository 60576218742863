import React from 'react';
import classNames from 'classnames';
import { TelegramIcon, WhatsAppIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {
  telegramLink: string,
  whatsappLink: string,
  className?: string,
};

export const SocialMediaTile = ({ telegramLink, whatsappLink, className }: TProps) => {

  return (
    <div className={classNames(css.wrap, className)}>
      <a 
        href={telegramLink}
        target="_blank"
        rel="noreferrer"
        className={classNames(css.card, css.card_telegram)} 
      >
        <div className={css.iconWrap}>
          <TelegramIcon className={css.icon} />
        </div>
        <div className={css.text}>
          <p className={css.title}>Наш телеграм</p>
          <p className={css.description}>Все актуальные скидки и акции здесь</p>
        </div>
      </a>

      <a 
        href={whatsappLink}
        target="_blank"
        rel="noreferrer"
        className={classNames(css.card, css.card_whatsapp)} 
      >
        <div className={css.iconWrap}>
          <WhatsAppIcon className={css.icon} />
        </div>
        <div className={css.text}>
          <p className={css.title}>whats app</p>
          <p className={css.description}>Наш бизнес аккаунт по любым вопросам</p>
        </div>
      </a>
    </div>
  );
};
