import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CatalogAPI, ProductGroupAPI } from 'store/slices';
import { Loader, ProductGroupCard, Title } from 'components';
import { getNounGroups } from 'resources/helpers';
import { useIOPagination } from 'resources/helpers/hooks';
import { TProductGroup_Tiny } from 'store/slices/productGroupAPI/entities';
import { PaginationObserver } from 'features/PaginationObserver';
import css from './index.module.scss';
import { BreadcrumbsBar, CatalogSectionPageMetaTags } from 'features';

type TProps = {};

export const CatalogSectionPage = ({  }: TProps) => {
  const navigate = useNavigate();
  const { catalogSectionKey } = useParams();

  const [ fetchCatalogSection, { data: section, isFetching: isLoadingSection, isSuccess } 
    ] = CatalogAPI.useLazyGetCatalogSectionWithKeyQuery();

  const [ fetchProductGroupsList, { data: fetchedProductGroups, isFetching: isLoadingProductGroups } 
    ] = ProductGroupAPI.useLazyGetProductGroupsListQuery();

  const loadNextPage = (currentPage: number) => {
    if (section)
      fetchProductGroupsList({ catalogSectionId: section.id, currentPage });
  }

  const { paginationList, paginationObserverProps } = useIOPagination<TProductGroup_Tiny>({
    list: fetchedProductGroups,
    isLoading: isLoadingProductGroups,
    startCondition: !!section && isSuccess,
    loadNextPage
  });

  useEffect(() => {
    if (catalogSectionKey) 
      fetchCatalogSection(catalogSectionKey);
  }, [catalogSectionKey]);

  useEffect(() => {
    if (!section && isSuccess)
      navigate('/catalog');
  }, [section, isSuccess]);

  return (
    <div className={css.wrap}>
      {
        !!section && (
          <header className={css.header}>
            <BreadcrumbsBar />
            <Title type='base_page' level='h1'>{ section.name }</Title>
            <div className={css.subtitleRow}>
              <p className={css.subtitle}>{ section.description }</p>
              <p className={css.amountGroups}>
                { 
                  !!fetchedProductGroups 
                    ? `( ${fetchedProductGroups.pagination.total} ${getNounGroups(fetchedProductGroups.pagination.total)} )` 
                    : ''
                }
              </p>
            </div>
          </header>
        )
      }

      <div className={css.productGroups}>
        {
          paginationList.map(group => (
            <ProductGroupCard 
              key={group.id} 
              group={group} 
              sectionKey={catalogSectionKey || 'section'} 
            />
          ))
        }
      </div>
      <PaginationObserver { ...paginationObserverProps } />

      <Loader isLoading={isLoadingSection && isLoadingProductGroups} className={css.loader} />

      {/* meta tags */}
      <CatalogSectionPageMetaTags 
        title={ section?.name || '' }
        description={ section?.description && section.name ? `${section.name} - ${section.description}`.toLocaleLowerCase() : '' }
      />
    </div>
  );
};
