import { useState, useEffect } from 'react';
import { TPaginationObserverProps } from 'features/PaginationObserver';
import { TDataPaginationPattern } from 'store/globalEntities';


type TUseIOPaginationHookConfig<TListElement> = {
  list: TDataPaginationPattern<TListElement[]> | undefined,
  isLoading: boolean,
  startCondition: boolean,
  loadNextPage: (currentPage: number) => void,
}

type TUseIOPaginationHookResult<TListElement> = {
  paginationList: TListElement[],
  paginationObserverProps: TPaginationObserverProps,
}


export function useIOPagination <TListElement> ({
  list, isLoading, startCondition, loadNextPage
}: TUseIOPaginationHookConfig<TListElement>): TUseIOPaginationHookResult<TListElement> {

  const [currentPage, setCurrentPage] = useState(1);
  const [accumulationList, setAccumulationList] = useState<TListElement[]>([]);

  const onViewAction = () => {
    if (startCondition) {
      loadNextPage(currentPage);
      setCurrentPage(currentPage + 1);
    }
  }

  useEffect(() => {
    onViewAction();
  }, [startCondition]);

  useEffect(() => {
    if (list?.data)
      setAccumulationList(accumulationList => [ ...accumulationList, ...list.data ]);
  }, [list]);

  return {
    paginationList: accumulationList,
    paginationObserverProps: { 
      isLoading, 
      onViewAction, 
      stopUse: currentPage > (list?.pagination.pageCount || 0) && (!!list && !!list.data.length)
    }
  }
}