import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BasketIcon, BurgerMenuIcon, LogoIcon, NavSearchIcon, UserIcon } from 'resources/icons';
import { useNavigateContext } from 'app/context';
import { CartLocal } from 'store/slices';
import { AppPagesInfo } from 'app/AppRouter';
import css from './index.module.scss';

type TProps = {
  styles?: React.CSSProperties,
};

export const Header = ({ styles }: TProps) => {
  const { toggleMenuMode, openSearch } = useNavigateContext();

  const amountCartUnits = useSelector(CartLocal.getUniqUnitsAmount);

  return (
    <header className={css.wrap} style={styles}>
      <div className={css.logo}>
        <button className={css.burgerButton} onClick={toggleMenuMode}>
          <BurgerMenuIcon className={css.icon} />
        </button>
        <Link className={css.link} to='/'><LogoIcon className={css.icon} />{ "EcoTrees" }</Link>
      </div>
      <ul className={css.menu}>
        <li><Link to={AppPagesInfo.catalog.path}>{ AppPagesInfo.catalog.name }</Link></li>
        <li><Link to={AppPagesInfo.ourWorks.path}>{ AppPagesInfo.ourWorks.name }</Link></li>
        <li><Link to={AppPagesInfo.contacts.path}>{ AppPagesInfo.contacts.name }</Link></li>
        <li><Link to={AppPagesInfo.services.path}>{ AppPagesInfo.services.name }</Link></li>
      </ul>
      <ul className={css.buttons}>
        <li className={css.basketButtonLI}>
          <Link to={AppPagesInfo.basket.path} className={css.basketButton}>
            { !!amountCartUnits && <span>{amountCartUnits}</span> }
            <BasketIcon />
          </Link>
        </li>
        <li className={css.userButtonLI}><Link to={AppPagesInfo.user.path}><UserIcon /></Link></li>
        <li><button onClick={openSearch}><NavSearchIcon /></button></li>
      </ul>
    </header>
  );
};
