import React, { useEffect } from 'react';
import { TProduct } from 'store/globalEntities';
import css from './index.module.scss';
import { SizeUnitsAPI } from 'store/slices';
import { PageSection, SizeUnitCard, MoldedSizeUnitsGroup } from 'components';

type TProps = {
  product: TProduct,
};

export const AvailableSizes = ({ product }: TProps) => {
  const [ fetchSizeUnits, { data: units, isFetching: isUnitsLoading }
    ] = SizeUnitsAPI.useLazyGetSizeUnitsForProductQuery();

  const [ fetchSizeGroups, { data: moldedGroups, isFetching: isMoldedGroupsLoading }
    ] = SizeUnitsAPI.useLazyGetSizeGroupsForMoldedProductQuery();

  useEffect(() => {
    if (product.product_group.type == 'molded')
      fetchSizeGroups(product.id);
    else
      fetchSizeUnits(product.id);
  }, []);

  return (
    <PageSection title='Доступные варианты'>

      {
        !!units && (
          <div className={css.sizes} itemProp='offers' itemScope itemType='https://schema.org/Offer'>
            <meta itemProp='price' content={String(product.minPrice)} />
            <meta itemProp='priceCurrency' content="RUB" />
            {
              [ ...units].sort((u1, u2) => u1.price - u2.price).map(unit => 
                <SizeUnitCard 
                  key={unit.id}
                  sizeUnit={unit}
                  productGroup={product.product_group}
                  product={product}
                />  
              )
            }
          </div>
        )
      }

      {
        !!moldedGroups && (
          <div className={css.groups} itemProp='offers' itemScope itemType='https://schema.org/Offer'>
            <meta itemProp='price' content={String(product.minPrice)} />
            <meta itemProp='priceCurrency' content="RUB" />
            {
              moldedGroups.map(group => 
                <MoldedSizeUnitsGroup 
                  key={group.id}
                  sizeGroup={group}
                  productGroup={product.product_group}
                  product={product}
                />
              )
            }
          </div>
        )
      }

    </PageSection>
  );
};
