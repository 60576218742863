import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl, prepareHeadersBase } from 'resources/api';
import { 
  T_DoneResponse_GetSizeUnitsForProduct,
  T_DoneResponse_GetSizeGroupsForMoldedProduct
} from './entities';
import { 
  transformGetSizeUnitsForProduct,
  transformGetSizeGroupsForMoldedProduct, 
} from './transform';


export const SizeUnitsAPI = createApi({
  reducerPath: 'SizeUnitsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    
    getSizeUnitsForProduct: builder.query<T_DoneResponse_GetSizeUnitsForProduct, number>({
      query: (productId) => ({
        url: `size-units/product/${productId}`,
      }),
      transformResponse: transformGetSizeUnitsForProduct,
    }),

    getSizeGroupsForMoldedProduct: builder.query<T_DoneResponse_GetSizeGroupsForMoldedProduct, number>({
      query: (productId) => ({
        url: `molded-size-groups/product/${productId}`,
      }),
      transformResponse: transformGetSizeGroupsForMoldedProduct
    }),
  })
});