import React from 'react';

type TProps = {
  className?: string
}

export const LogoIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M150 300C67.1573 300 0 232.843 0 150C0 67.1573 67.1573 0 150 0C232.843 0 300 67.1573 300 150H150C232.843 150 300 217.157 300 300H150Z" fill="url(#paint0_linear_1014_93)"/>
    <defs>
    <linearGradient id="paint0_linear_1014_93" x1="229" y1="-3.00001" x2="56.5" y2="333" gradientUnits="userSpaceOnUse">
    <stop/>
    <stop offset="0.5625" stopColor="#6D5849"/>
    <stop offset="1" stopColor="#905D38"/>
      </linearGradient>
    </defs>
  </svg>
);