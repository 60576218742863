import { useEffect } from "react"

type THookProps = {
  ref: React.RefObject<HTMLElement>,
  handler: (arg: MouseEvent) => void,
}

export const useOnClickOutside = ({ ref, handler }: THookProps) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) return;

      handler(event);
    }

    document.addEventListener('mousedown', listener);
    return () => document.removeEventListener('mousedown', listener);
    
  }, [ref, handler]);
}