import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CatalogAPI } from 'store/slices';
import { useBreakPoint } from 'resources/helpers/hooks';
import { CatalogSection, CatalogSectionCard, PageSection } from 'components';
import { GridViewGrid, GridViewRows } from 'resources/icons';
import css from './index.module.scss';
import { CatalogPageMetaTags } from 'features';

type TGridViewTypes = 'grid' | 'rows';

export const CatalogPage = () => {
  const [gridView, setGridView] = useState<TGridViewTypes>('rows');
  const isTwoColumnsView = useBreakPoint(800);

  const { data: catalogSections } = CatalogAPI.useGetCatalogPageDataQuery();

  useEffect(() => {
    setGridView(isTwoColumnsView ? 'grid' : 'rows');
  }, [isTwoColumnsView]);

  return (
    <>
      <PageSection 
        title='Каталог'
        controls={(
          <>
            {
              !isTwoColumnsView && (
                  <div className={css.gridViewButtons}>
                <button className={css.gridViewBtn} onClick={() => setGridView('rows')}>
                  <GridViewRows className={classNames(css.icon, { [css.active]: gridView === 'rows' })} />
                </button>
                <button className={css.gridViewBtn} onClick={() => setGridView('grid')}>
                  <GridViewGrid className={classNames(css.icon, { [css.active]: gridView === 'grid' })} />
                </button>
              </div>
              )
            }
          </>
        )}
      >
        {
          gridView === 'grid'
            ? (
              <div className={css.sectionsGrid}>
                {
                  !!catalogSections && catalogSections.map((section, index) => (
                    <CatalogSectionCard
                      key={section.id} 
                      section={section} 
                      orderNumber={index + 1}
                    />
                  ))
                }
              </div>
            )
            : (
              <div className={css.sectionsRows}>
                {
                  !!catalogSections && catalogSections.map((section, index) => (
                    <CatalogSection 
                      key={section.id} 
                      section={section} 
                      orderNumber={index + 1}
                    />
                  ))
                }
              </div>
            )
        }
      </PageSection>

      {/* meta tags */}
      <CatalogPageMetaTags 
        description={ !catalogSections ? '' : catalogSections.map(s => s.name).join(', ').toLocaleLowerCase() }
      />
    </>
  );
};
