import React from 'react';
import { OrderList, Title, UserDataForm } from 'components';
import css from './index.module.scss';
import { UserPageMetaTags } from 'features';

type TProps = {};

export const UserPage = ({  }: TProps) => {

  return (
    <div className={css.wrap}>
      <Title type='base_page' level='h1'>Учетная запись</Title>
      <div className={css.columns}>
        <div className={css.leftColumn}>
          <UserDataForm />
        </div>
        <div className={css.rightColumn}>
          <OrderList />
        </div>
      </div>

      {/* meta tags */}
      <UserPageMetaTags />
    </div>
  );
};
