import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowIcon, BasketIcon, ChevronIcon, CrossIcon, UserIcon } from 'resources/icons';
import { useNavigateContext } from 'app/context';
import { useOnClickOutside } from 'resources/helpers/hooks';
import { CallRequestModal } from 'modals';
import css from './index.module.scss';
import { AppPagesInfo } from 'app/AppRouter';

type TProps = {};

export const MobileNavigatePanel = ({  }: TProps) => {
  const { isMenuOpen, toggleMenuMode } = useNavigateContext();
  const modalWindowHTMLElement = useRef(null);
  
  const [isModalView, setIsModalView] = useState(false);

  const openModalWindow = () => setIsModalView(true);
  const closeModalWindow = () => setIsModalView(false);

  useOnClickOutside({ 
    ref: modalWindowHTMLElement, 
    handler: () => {
      closeModalWindow();
      toggleMenuMode();
    }
  });

  useEffect(() => {
    if (isMenuOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }, [isMenuOpen]);

  if (!isMenuOpen) {
    return <></>;
  }

  return (
    <div className={css.wrap}>
      <div className={css.window} ref={modalWindowHTMLElement}>
        <button
          className={css.btnCloseMenu}
          onClick={toggleMenuMode}
        >
          <CrossIcon className={css.icon} />
        </button>

        <div className={css.content}>
          <Link to='/' className={css.header} onClick={toggleMenuMode}>
            <h2 className={css.logo}>Ecotrees</h2>
            <p className={css.aboutShop}>Отделочные материалы, мебель и другие изделия из натуральной древесины</p>
          </Link>

          <nav className={css.navigation}>
            <Link to={AppPagesInfo.catalog.path} onClick={toggleMenuMode}>
              <p>{ AppPagesInfo.catalog.name }</p>
              <ArrowIcon className={css.icon} />
            </Link><Link to={AppPagesInfo.ourWorks.path} onClick={toggleMenuMode}>
              <p>{ AppPagesInfo.ourWorks.name }</p>
              <ArrowIcon className={css.icon} />
            </Link><Link to={AppPagesInfo.contacts.path} onClick={toggleMenuMode}>
              <p>{ AppPagesInfo.contacts.name }</p>
              <ArrowIcon className={css.icon} />
            </Link><Link to={AppPagesInfo.services.path} onClick={toggleMenuMode}>
              <p>{ AppPagesInfo.services.name }</p>
              <ArrowIcon className={css.icon} />
            </Link>
          </nav>

          <div className={css.buttons}>
            <Link to={AppPagesInfo.basket.path} onClick={toggleMenuMode}>
              <BasketIcon className={css.icon} />
              <p className={css.text}>{ AppPagesInfo.basket.name }</p>
            </Link>
            <Link to={AppPagesInfo.user.path} onClick={toggleMenuMode}>
              <UserIcon className={css.icon} />
              <p className={css.text}>{ AppPagesInfo.user.name }</p>
            </Link>
          </div>

        </div>

        <button 
          className={css.btnCallRequest}
          onClick={openModalWindow}
        >
          <p className={css.text}>Заказать звонок</p>
          <ChevronIcon className={css.icon} />
        </button>

        
        <CallRequestModal isView={isModalView} closeFn={closeModalWindow} />
      </div>
    </div>
  );
};
