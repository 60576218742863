import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

type TPosition = {
  horizontal: 'left' | 'right',
  vertical: 'top' | 'bottom',
}

type TContextState = {
  tooltipRef: React.RefObject<HTMLDivElement> | null,
  setTargetRef: (arg: React.RefObject<HTMLElement>) => void,

  isShow: boolean,
  showTooltip: () => void,
  hideTooltip: () => void,

  content: React.ReactNode,
  setContent: (content: React.ReactNode) => void,

  left: number,
  top: number,
  setPosition: (arg: TPosition) => void,
}

const initialState: TContextState = {
  tooltipRef: { current: null },
  setTargetRef: () => {},

  isShow: false,
  showTooltip: () => {},
  hideTooltip: () => {},

  content: null,
  setContent: () => {},

  left: 0, 
  top: 0,
  setPosition: () => {},
}

const TOOLTIP_OFFSET = 4;

const TooltipContext = createContext<TContextState>(initialState);


export const TooltipState = ({ children }: React.PropsWithChildren) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [targetRef, setTargetRef] = useState<React.RefObject<HTMLElement> | null>(null);

  const [onTargetHover, setOnTargetHover] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [content, setContent] = useState<React.ReactNode>(null);

  const [position, setPosition] = useState<TPosition>({ horizontal: 'right', vertical: 'bottom' });
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);

  const showTooltip = () => setOnTargetHover(true); 
  const hideTooltip = () => setOnTargetHover(false);

  useEffect(() => {
    if (!onTargetHover) {
      setIsShow(false);
      return;
    }

    const timer = setTimeout(() => {
      setIsShow(true);
    }, 300);

    return () => clearTimeout(timer);
  }, [onTargetHover]);

  useEffect(() => {
    if (targetRef?.current)
      targetRef.current.style.cursor = 'pointer';
  }, [targetRef]);

  useEffect(() => {
    if (!tooltipRef.current || !targetRef?.current) return;

    const targetParams = targetRef.current.getBoundingClientRect();
    const tooltipParams = tooltipRef.current.getBoundingClientRect();

    let offset = 0;
    const neededHeight = tooltipParams.height + TOOLTIP_OFFSET;
    const neededWidth = tooltipParams.width + TOOLTIP_OFFSET;

    if (position.vertical === 'top') {
      offset = targetParams.top > neededHeight
        ? targetParams.top - neededHeight
        : targetParams.bottom + TOOLTIP_OFFSET
    } else {
      offset = window.innerHeight - targetParams.bottom > neededHeight
        ? targetParams.bottom + TOOLTIP_OFFSET
        : targetParams.top - neededHeight
    }

    setTop(offset + window.pageYOffset);

    if (position.horizontal === 'left') {
      offset = targetParams.left > neededWidth
        ? targetParams.left - neededWidth
        : 10
    } else {
      offset = window.innerWidth - targetParams.right > neededWidth
        ? targetParams.right + TOOLTIP_OFFSET
        : window.innerWidth - neededWidth - 10
    }

    setLeft(offset);
  }, [content]);

  return (
    <TooltipContext.Provider
      value={{ 
        tooltipRef,
        setTargetRef,
        isShow, 
        showTooltip, 
        hideTooltip,
        content,
        setContent,
        setPosition,
        left,
        top
      }}
    >
      { children }
    </TooltipContext.Provider>
  );
}

export const useTooltipContext = () => useContext(TooltipContext);