import React from 'react';
import css from './index.module.scss';
import { Link } from 'react-router-dom';
import { BasketIcon, UserIcon } from 'resources/icons';
import { AppPagesInfo } from 'app/AppRouter';

type TProps = {};

export const Footer = ({  }: TProps) => {

  return (
    <div className={css.wrap}>
      <div className={css.container}>
        <div className={css.content}>
          <div className={css.shopInfo}>
            <p className={css.logo}>Ecotrees</p>
            <p className={css.description}>Отделочные материалы, мебель и другие изделия из натуральной древесины</p>
          </div>
          <nav className={css.navigation}>
            <Link to={AppPagesInfo.catalog.path}>Каталог</Link>
            <Link to={AppPagesInfo.ourWorks.path}>Наши работы</Link>
            <Link to={AppPagesInfo.contacts.path}>Контакты</Link>
            <Link to={AppPagesInfo.services.path}>Услуги</Link>
          </nav>
        </div>
        <div className={css.buttons}>
          <Link to={AppPagesInfo.basket.path}>
            <BasketIcon className={css.icon} />
            <p className={css.text}>Корзина</p>
          </Link>
          <Link to={AppPagesInfo.user.path}>
            <UserIcon className={css.icon} />
            <p className={css.text}>Аккаунт</p>
          </Link>
        </div>
      </div>
    </div>
  );
};
