import React from 'react';
import { TGalleryImageComment } from 'store/globalEntities';
import { CommentIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {
  comment: TGalleryImageComment | null,
};

export const GalleryImageComment = ({ comment }: TProps) => {

  if (!comment)
    return <></>;

  return (
    <div className={css.wrap}>
      <div className={css.label}>
        <CommentIcon className={css.icon} />
        <p className={css.text}>Комментарий покупателя</p>
      </div>
      <div className={css.content}>
        <p className={css.author}>{ `${ comment.name }, г. ${ comment.city }` }</p>
        <p className={css.comment}>{ comment.text }</p>
      </div>
    </div>
  );
};
