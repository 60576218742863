import React from 'react';
import { useTooltipContext } from 'app/context';
import css from './index.module.scss';

export const HoverTooltip = () => {
  const { tooltipRef, isShow, content, showTooltip, hideTooltip, left, top } = useTooltipContext();

  return (
    <div 
      className={css.tooltip} 
      ref={tooltipRef}
      style={{
        visibility: isShow ? 'visible' : 'hidden',
        top,
        left,
      }}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      { content }
    </div>
  );
};
