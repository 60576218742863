import React from 'react';

type TProps = {
  className?: string
}

export const MapIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M114.384 32.56L82.3843 16.56C81.6434 16.1892 80.8262 15.9962 79.9977 15.9962C79.1691 15.9962 78.3519 16.1892 77.611 16.56L48.0003 31.3707L18.3843 16.56C17.571 16.1536 16.6673 15.9618 15.759 16.003C14.8508 16.0441 13.9681 16.3168 13.1948 16.795C12.4216 17.2733 11.7834 17.9413 11.341 18.7356C10.8986 19.5299 10.6666 20.4241 10.667 21.3333V90.6667C10.667 92.688 11.8083 94.5333 13.6163 95.44L45.6163 111.44C46.3573 111.811 47.1744 112.004 48.003 112.004C48.8315 112.004 49.6487 111.811 50.3897 111.44L80.0003 96.6293L109.616 111.435C110.427 111.845 111.33 112.039 112.238 111.999C113.146 111.959 114.029 111.686 114.8 111.205C116.374 110.229 117.334 108.517 117.334 106.667V37.3333C117.334 35.312 116.192 33.4667 114.384 32.56ZM53.3337 40.6293L74.667 29.9627V87.3707L53.3337 98.0373V40.6293ZM21.3337 29.9627L42.667 40.6293V98.0373L21.3337 87.3707V29.9627ZM106.667 98.0373L85.3337 87.3707V29.9627L106.667 40.6293V98.0373Z" fill="#D7D7D7"/>
  </svg>
);