import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl, prepareHeadersBase } from 'resources/api';
import { TShopContacts } from 'store/globalEntities';
import { 
  T_Body_CallRequest, 
  T_Body_ChooseHelp, 
  T_Body_InteriorDecoration, 
  T_Body_MakeOrder, 
  T_ResponseDone_GetShopServices, 
  T_Response_GetShopContacts, 
  T_Response_MakeOrder, 
  T_Response_SimpleRequestsAnswer 
} from './entities';
import { transformGetShopServicesResponse } from './transform';


export const RequestsAPI = createApi({
  reducerPath: 'RequestsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({

    getShopContacts: builder.query<TShopContacts, void>({
      query: () => ({
        url: 'shop-contact',
        method: 'get',
      }),
      transformResponse: (data: T_Response_GetShopContacts) => data.data.attributes,
    }),

    getShopServices: builder.query<T_ResponseDone_GetShopServices, void>({
      query: () => ({
        url: 'shop-service',
        method: 'get',
      }),
      transformResponse: transformGetShopServicesResponse,
    }),
    
    callRequest: builder.mutation<T_Response_SimpleRequestsAnswer, T_Body_CallRequest>({
      query: (body: T_Body_CallRequest) => ({
        url: 'requests/call-request',
        method: 'post',
        body,
        cache: 'no-cache',
      }),
    }),

    chooseHelpRequest: builder.mutation<T_Response_SimpleRequestsAnswer, T_Body_ChooseHelp>({
      query: (body: T_Body_ChooseHelp) => ({
        url: 'requests/choose-help-request',
        method: 'post',
        body,
        cache: 'no-cache',
      }),
    }),

    interiorDecorationRequest: builder.mutation<T_Response_SimpleRequestsAnswer, T_Body_InteriorDecoration>({
      query: (body: T_Body_InteriorDecoration) => ({
        url: 'requests/interior-decoration-request',
        method: 'post',
        body,
        cache: 'no-cache',
      }),
    }),

    makeOrder: builder.mutation<T_Response_MakeOrder, T_Body_MakeOrder>({
      query: (body: T_Body_MakeOrder) => ({
        url: 'requests/make-order',
        method: 'post',
        body,
        cache: 'no-cache',
      }),
    }),

  })
});