import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl, prepareHeadersBase } from 'resources/api';
import { 
  T_Params_GetProductsList, T_DoneResponse_GetProductsList,
  T_DoneResponse_GetProductWithId,
  T_DoneResponse_SearchProducts,
  TNormilizedSearchQuery,
} from './entities';
import { 
  transformGetProductsList, transformGetProductWithId, transformSearchProducts,
} from './transform';


export const ProductsAPI = createApi({
  reducerPath: 'ProductsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    
    getProductsList: builder.query<T_DoneResponse_GetProductsList, T_Params_GetProductsList>({
      query: ({ productGroupId, currentPage }) => ({
        url: 'products',
        params: {
          'filters[product_group]': productGroupId,
          'pagination[page]': currentPage,
          'pagination[pageSize]': 20,
          'populate[images][fields][0]': 'url',
          'populate[images][fields][1]': 'formats'
        }
      }),
      transformResponse: transformGetProductsList,
    }),

    getProductWithId: builder.query<T_DoneResponse_GetProductWithId, number>({
      query: (productId) => ({
        url: `products/id/${productId}`,
      }),
      transformResponse: transformGetProductWithId,
    }),

    searchProducts: builder.query<T_DoneResponse_SearchProducts, TNormilizedSearchQuery>({
      query: ({ query, keywords }) => ({
        url: 'products/search',
        params: { 
          limit: 14,
          query, 
          keywords: JSON.stringify(keywords) 
        },
      }),
      transformResponse: transformSearchProducts,
    }),
  })
});