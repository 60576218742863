import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Title, Image } from 'components';
import { ChevronIcon } from 'resources/icons';
import { TProduct_Tiny } from 'store/slices/productsAPI/entities';
import { getNounRubleFrom } from 'resources/helpers';
import css from './index.module.scss';

type TProps = {
  product: TProduct_Tiny,
  sectionKey: string,
  groupKey: string,
  className?: string,
};

export const ProductCard = ({ product, sectionKey, groupKey, className }: TProps) => {

  return (
    <Link 
      to={`/catalog/${sectionKey}/${groupKey}/${product.id}`}
      className={classNames(css.wrap, className)}
    >
      <div className={css.imgWrap}>
        <Image className={css.img} image={product.images[0]} />
      </div>
      <div className={css.content}>
        <div className={css.textWrap}>
          <Title type='card' level='h3'>{ product.name }</Title>
          <p className={css.subtitle}>{ `от ${product.minPrice} ${getNounRubleFrom(product.minPrice)}` }</p>
        </div>
        <div className={css.pseudoButton}>
          <p className={css.btnText}>{ "Открыть" }</p>
          <ChevronIcon className={css.btnIcon} />
        </div>
      </div>
    </Link>
  );
};
