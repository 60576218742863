import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useNavigateContext } from 'app/context';
import { ProductsAPI, UserLocal } from 'store/slices';
import { CrossIcon, SearchIcon } from 'resources/icons';
import { normalizeSearchQuery } from 'resources/helpers';
import { ModalWindow } from 'modals';
import { SearchResults } from 'components';
import { TPageLabelInfo, getRelevantSearchPages } from 'resources/helpers/searchQueryConfiguration';
import css from './index.module.scss';

type TProps = {};

type TFormData = {
  query: string,
};

export const CatalogSearch = ({  }: TProps) => {
  const dispatch = useDispatch();
  const recentSearchQueries = useSelector(UserLocal.getSearchQueries);

  const [relevantPages, setRelevantPages] = useState<TPageLabelInfo[]>([]);
  const { isSearchOpen, closeSearch, setCurrentSearchQuery, currentSearchQuery } = useNavigateContext();
  const { register, reset, handleSubmit, formState: { errors, isValid }, setValue } = useForm<TFormData>({ mode: 'all' });

  const [ searchRequest, { data, isLoading } ] = ProductsAPI.useLazySearchProductsQuery();

  const onSearchClose = () => {
    reset();
    closeSearch();
  }

  const onSearchSubmit = (data: TFormData) => {
    const normalizedQuery = normalizeSearchQuery(data.query);
    setCurrentSearchQuery(normalizedQuery.query);
    setRelevantPages(getRelevantSearchPages(normalizedQuery));
    searchRequest(normalizedQuery);

    dispatch(UserLocal.saveSearchQuery(normalizedQuery.query));
    reset();
  }

  const onReadyQueryLabelClick = (query: string) => () => {
    setValue('query', query, { shouldValidate: true });
  }

  const onRecentQueryLabelRemoveClick = (query: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(UserLocal.removeSearchQuery(query));
  }

  return (
    <ModalWindow
      title='Поиск на сайте'
      isView={isSearchOpen}
      closeFn={onSearchClose}
      scrollAreaClassName={css.area}
      modalWindowClassName={css.window}
    >
      <>
        <div className={css.searchBar}>
          <form className={css.form} onSubmit={handleSubmit(onSearchSubmit)}>
            <input
              className={css.input}
              type="text" 
              placeholder='Введите запрос...'
              autoFocus
              { ...register( 'query', {
                  required: true,
                  minLength: { value: 3, message: 'Минимальная длина поискового запроса - 3 символа' },
                  maxLength: { value: 50, message: 'Максимальная длина поискового запроса - 50 символов' },
                }
              )}
            />
            <button className={css.submit} type="submit" disabled={!isValid}>
              <p>Найти</p>
              <SearchIcon className={css.icon} />
            </button>
          </form>
          <p className={classNames(css.error, { [css.active]: errors.query?.message })}>{ errors.query?.message }</p>
          <div className={css.readyQueries}>
            <p className={css.title}>{ recentSearchQueries.length ? 'Вы искали:' : 'Часто ищут:' }</p>
            {
              recentSearchQueries.length
              ? recentSearchQueries.map(q => (
                <div className={css.queryLabel} key={q} onClick={onReadyQueryLabelClick(q)}>
                  <p className={css.text}>{ q }</p>
                  <button className={css.btnRemove} onClick={onRecentQueryLabelRemoveClick(q)}>
                    <CrossIcon className={css.icon} />
                  </button>
                </div>
              ))
              : ['Евровагонка', 'Полок', 'Дверь массив'].map(q => (
                <button className={css.queryLabel} key={q} onClick={onReadyQueryLabelClick(q)}>
                  <p className={css.text}>{ q }</p>
                </button>
              ))
            }
          </div>
        </div>
        { (!!data || isLoading) && (
          <SearchResults
            relevantPages={relevantPages}
            results={data}
            isLoading={isLoading}
          />
        )}
      </>
    </ModalWindow>
  );
};
