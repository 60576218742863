import React from 'react';
import css from './index.module.scss';
import classNames from 'classnames';

type TProps = {
  children: React.ReactNode,
  type: 'card' | 'section' | 'base_page' | 'product_page' | 'modal' | 'main_page_deco',
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'p',
  className?: string,
};

export const Title = ({ children, type, level = 'p', className = '' }: TProps) => {
  
  return React.createElement(
    level, 
    { className: classNames(css.title, css[`type_${type}`], className) }, 
    children
  );
};
