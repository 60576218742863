import React from 'react';

type TProps = {
  className?: string
}

export const SaleIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.523 20.8441C15.5309 20.8441 14.5611 20.5499 13.7362 19.9987C12.9113 19.4476 12.2683 18.6641 11.8887 17.7475C11.509 16.831 11.4097 15.8224 11.6032 14.8493C11.7968 13.8763 12.2745 12.9825 12.976 12.281C13.6776 11.5794 14.5714 11.1017 15.5444 10.9082C16.5174 10.7146 17.526 10.8139 18.4426 11.1936C19.3592 11.5733 20.1426 12.2162 20.6938 13.0411C21.245 13.866 21.5392 14.8358 21.5392 15.8279C21.5378 17.1579 21.0089 18.433 20.0685 19.3734C19.1281 20.3138 17.853 20.8427 16.523 20.8441ZM16.523 13.3899C16.0408 13.3899 15.5694 13.5329 15.1685 13.8008C14.7676 14.0687 14.4551 14.4494 14.2705 14.8949C14.086 15.3404 14.0377 15.8306 14.1318 16.3036C14.2259 16.7765 14.4581 17.2109 14.7991 17.5519C15.14 17.8929 15.5744 18.1251 16.0474 18.2191C16.5203 18.3132 17.0105 18.2649 17.456 18.0804C17.9015 17.8959 18.2823 17.5834 18.5502 17.1824C18.8181 16.7815 18.9611 16.3101 18.9611 15.8279C18.9604 15.1815 18.7033 14.5618 18.2462 14.1047C17.7891 13.6477 17.1694 13.3906 16.523 13.3899Z" fill="#D7D7D7"/>
    <path d="M27.6646 31.9851C26.6725 31.9851 25.7027 31.6909 24.8778 31.1397C24.0529 30.5885 23.4099 29.8051 23.0303 28.8885C22.6506 27.972 22.5513 26.9634 22.7448 25.9903C22.9384 25.0173 23.4161 24.1235 24.1176 23.422C24.8192 22.7204 25.713 22.2427 26.686 22.0491C27.659 21.8556 28.6676 21.9549 29.5842 22.3346C30.5008 22.7143 31.2842 23.3572 31.8354 24.1821C32.3866 25.007 32.6808 25.9768 32.6808 26.9689C32.679 28.2987 32.1499 29.5736 31.2096 30.5139C30.2693 31.4542 28.9944 31.9833 27.6646 31.9851ZM27.6646 24.5309C27.1824 24.5309 26.711 24.6739 26.3101 24.9418C25.9092 25.2097 25.5967 25.5904 25.4121 26.0359C25.2276 26.4814 25.1793 26.9716 25.2734 27.4446C25.3675 27.9175 25.5997 28.3519 25.9407 28.6929C26.2816 29.0339 26.716 29.2661 27.189 29.3601C27.6619 29.4542 28.1521 29.4059 28.5976 29.2214C29.0431 29.0369 29.4239 28.7244 29.6918 28.3234C29.9597 27.9225 30.1027 27.4511 30.1027 26.9689C30.102 26.3225 29.8449 25.7028 29.3878 25.2457C28.9307 24.7887 28.311 24.5316 27.6646 24.5309Z" fill="#D7D7D7"/>
    <path d="M17.03 30.1838C16.7962 30.1836 16.5668 30.1198 16.3665 29.9992C16.1662 29.8786 16.0024 29.7058 15.8927 29.4993C15.7831 29.2928 15.7317 29.0603 15.744 28.8268C15.7563 28.5933 15.8319 28.3676 15.9627 28.1737L26.087 13.1802C26.2783 12.8968 26.5744 12.701 26.9101 12.6359C27.2458 12.5708 27.5936 12.6417 27.8771 12.833C28.1605 13.0244 28.3563 13.3205 28.4214 13.6562C28.4865 13.9919 28.4156 14.3397 28.2243 14.6231L18.0991 29.6166C17.9809 29.7915 17.8217 29.9346 17.6353 30.0335C17.4489 30.1324 17.241 30.184 17.03 30.1838Z" fill="#D7D7D7"/>
    <path d="M22.0938 38.6719C12.5693 38.6719 4.82031 30.9229 4.82031 21.3984C4.82031 11.874 12.5693 4.125 22.0938 4.125C31.6182 4.125 39.3672 11.874 39.3672 21.3984C39.3672 30.9229 31.6182 38.6719 22.0938 38.6719ZM22.0938 6.70312C13.9907 6.70312 7.39844 13.2954 7.39844 21.3984C7.39844 29.5015 13.9907 36.0938 22.0938 36.0938C30.1968 36.0938 36.7891 29.5015 36.7891 21.3984C36.7891 13.2954 30.1968 6.70312 22.0938 6.70312Z" fill="#D7D7D7"/>
  </svg>
);