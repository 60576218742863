import React from 'react';
import { DeliveryScreen } from './DeliveryScreen';
import { DecorationScreen } from './DecorationScreen';
import css from './index.module.scss';

type TProps = {};

export const ServicesPage = ({  }: TProps) => {

  return (
    <div className={css.wrap}>
      <DecorationScreen />
      <DeliveryScreen />
    </div>
  );
};
