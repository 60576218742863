import { PayloadAction } from "@reduxjs/toolkit";
import { TAmountChangePayload, TLocalCartState } from "./entities";
import { calcVolume } from "resources/helpers/cartHelpers";

export const CartSliceActions = {

  addUnit (state: TLocalCartState, { payload: data }: PayloadAction<TAmountChangePayload>) {

    const { productGroup, sizeUnit, amount, productId } = data;
    const unitVolume = calcVolume(sizeUnit, amount);

    let cartGroup = state.groups.find(group => group.id === data.productGroup.id);
    const cartUnit = state.units.find(unit => unit.id === sizeUnit.id);

    // WAY 1. NO GROUP
    if (!cartGroup) {
      state.groups = [ ...state.groups, { ...productGroup, commonVolume: unitVolume } ];
    } else {
      // update saved data
      cartGroup.commonVolume += unitVolume;
      cartGroup.saleLimit = productGroup.saleLimit;
    }

    // WAY 2. NO UNIT
    if (!cartUnit) {
      state.units = [ 
        ...state.units, 
        {
          id: sizeUnit.id,
          groupId: productGroup.id,
          productId,
          amount,
          volume: unitVolume,
        } 
      ];
    }

    // WAY 3. UNIT EXIST
    if (cartUnit) {
      cartUnit.volume += unitVolume;
      cartUnit.amount += amount;
    }
  },

  removeUnit (state: TLocalCartState, { payload: data }: PayloadAction<TAmountChangePayload>) {
    
    const { productGroup, sizeUnit, amount } = data;
    const unitVolume = calcVolume(sizeUnit, amount);

    const cartGroup = state.groups.find(group => group.id === data.productGroup.id);
    const cartUnit = state.units.find(unit => unit.id === sizeUnit.id);

    if (cartGroup && cartUnit) {
      cartGroup.commonVolume -= unitVolume;

      const isUnitExistAfterRemove = amount < cartUnit.amount;
      const isCurrentUnitAloneInGroup = state.units.filter(unit => unit.groupId === productGroup.id).length === 1;

      // WAY 1. AFTER REMOVE UNIT AMOUNT != 0
      if (isUnitExistAfterRemove) {
        cartUnit.volume -= unitVolume;
        cartUnit.amount -= amount;
      }

      // WAY 2. AFTER REMOVE UNIT AMOUNT == 0
      if (!isUnitExistAfterRemove) {
        state.units = state.units.filter(unit => unit.id !== sizeUnit.id);
      }

      // WAY 3. AFTER REMOVE UNIT AMOUNT == 0 && GROUP DOESNT HAVE ANOTHER UNITS
      if (!isUnitExistAfterRemove && isCurrentUnitAloneInGroup) {
        state.groups = state.groups.filter(group => group.id !== productGroup.id);
      }
    }
  },

  clearCart (state: TLocalCartState) {
    state.groups = [];
    state.units = [];
  },
}