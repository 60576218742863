import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useNavigateContext } from 'app/context';
import { BoxIcon, FileIcon } from 'resources/icons';
import { TPageLabelInfo } from 'resources/helpers/searchQueryConfiguration';
import css from './index.module.scss';

type TProps = {
  relevantBasePages: TPageLabelInfo[],
  relevantGroupsPages: TPageLabelInfo[],
};

export const SearchLabels = ({ relevantBasePages, relevantGroupsPages }: TProps) => {
  const { closeSearch } = useNavigateContext();

  return (
    <div className={css.wrap}>
      <div className={css.list}>
        {
          relevantBasePages.map(page => (
            <Link className={classNames(css.label, css.pageLabel)} key={page.path} to={page.path} onClick={closeSearch}>
              <FileIcon className={css.icon} />
              <div className={css.content}>
                <p className={css.type}>Страница</p>
                <p className={css.name}>{ page.name }</p>
              </div>
            </Link>
          ))
        }
      </div>
      <div className={css.list}>
        {
          relevantGroupsPages.map(page => (
            <Link className={classNames(css.label, css.groupLabel)} key={page.path} to={page.path} onClick={closeSearch}>
              <BoxIcon className={css.icon} />
              <div className={css.content}>
                <p className={css.type}>Группа товаров</p>
                <p className={css.name}>{ page.name }</p>
              </div>
            </Link>
          ))
        }
      </div>
    </div>
  );
};
