import React, { useState } from 'react';
import classNames from 'classnames';
import { Image } from 'components';
import { ChevronIcon } from 'resources/icons';
import { TImage } from 'store/globalEntities';
import css from './index.module.scss';

type TProps = {
  images: TImage[],
};

export const ImageSlider = ({ images }: TProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goPrevImage = () => setCurrentIndex(value => value - 1);
  const goNextImage = () => setCurrentIndex(value => value + 1);

  if (!images.length)
    return <></>

  return (
    <div className={css.wrap}>
      <Image image={images[currentIndex]} className={css.image} />
      {
        images.length > 1 && (
          <div className={css.controlBar}>
            <button onClick={goPrevImage}>
              <ChevronIcon className={classNames(css.icon, css.left)} />
            </button>
            <p className={css.counter}>{ `( ${currentIndex + 1} / ${images.length} )` }</p>
            <button onClick={goNextImage}>
              <ChevronIcon className={css.icon} />
            </button>
          </div>
        )
      }
    </div>
  );
};
