import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { TImage } from 'store/globalEntities';
import { baseUrl } from 'resources/api';
import css from './index.module.scss';

type TProps = {
  image: TImage,
  className?: string,
} & React.ImgHTMLAttributes<HTMLImageElement>;

export const Image = ({ image, className, ...props }: TProps) => {
  const [src, setSrc] = useState(image.thumbnail);

  useEffect(() => {
    const loadedImage = new window.Image();
    loadedImage.src = baseUrl() + image.url;
    loadedImage.onload = () => setSrc(image.url);
  }, [image]);

  return (
    <img
      className={
        classNames(className, { 
          [css.blur]: src === image.thumbnail,
          [css.clear]: src === image.url,
        })
      }
      itemProp='image'
      src={baseUrl() + src}
      { ...props }
    />
  );
};
