import React from 'react';
import { annotationIcons } from 'resources/helpers';
import { TAnnotation } from 'store/globalEntities';
import css from './index.module.scss';

type TProps = {
  annotation: TAnnotation,
};

export const ProductAnnotation = ({ annotation }: TProps) => {
  
  return (
    <div className={css.wrap}>
      { annotationIcons[annotation.type]({ className: css.icon }) }
      <p className={css.text}>
        { annotation.text }
      </p>
    </div>
  );
};
