import { createSelector } from "@reduxjs/toolkit";
import { TState } from "store/configureStore";

const getState = (state: TState) => state;

// получение среза заказов
export const getOrdersState = createSelector(
  getState, 
  state => state.ORDERS
);

// получение списка заказов
export const getOrdersList = createSelector(
  getOrdersState,
  state => state.orders
);