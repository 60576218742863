import React, { useState } from 'react';
import { TGroupDiscountInfo } from 'resources/helpers/cartHelpers';
import { TServerCartUnit } from 'store/slices/cartAPI/entities';
import { CartUnitCard } from 'components/CartUnitCard';
import { SaleIcon } from 'resources/icons';
import classNames from 'classnames';
import css from './index.module.scss';

type TProps = {
  group: TGroupDiscountInfo,
  units: TServerCartUnit[],
};

export const CartGroupSection = ({ group, units }: TProps) => {
  const [isListShow, setIsListShow] = useState(true);
  const changeListShow = () => setIsListShow(prev => !prev);

  return (
    <div className={css.wrap}>
      <button 
        className={classNames(css.header, { [css.isSale]: group.isHasDiscount })} 
        onClick={changeListShow}
      >
        <div className={css.textWrap}>
          <p className={css.comment}>
            {
              group.isHasDiscount
              ? `применена скидка (добавлено ${group.commonVolume === group.saleLimit ? '' : '>'} 
                  ${group.saleLimit} ${group.measureUnit})`
              : `еще ${group.amountBeforeSale} ${group.measureUnit} до скидки на товары группы`
            }
          </p>
          <p className={css.groupName}>
            { group.name }
          </p>
        </div>
        <SaleIcon className={classNames(css.icon, { [css.rotate]: isListShow })} />
      </button>
      <ul className={classNames(css.list, { [css.hidden]: !isListShow })}>
        {
          units.map(unit => (
            <li className={css.item} key={unit.id}>
              <CartUnitCard unit={unit} group={group} className={css.card} />
            </li>
          ))
        }
      </ul>
    </div>
  );
};
