import React from 'react';

type TProps = {
  className?: string
}

export const DecoLeafOneIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="267" height="285" viewBox="0 0 267 285" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2" clipPath="url(#clip0_832_362)">
      <path d="M231.808 117.409C231.839 116.844 231.672 116.285 231.336 115.83C231 115.374 230.516 115.049 229.967 114.912C229.418 114.774 228.838 114.831 228.327 115.075C227.816 115.318 227.405 115.731 227.166 116.244C227.02 116.568 212.165 148.653 143.422 146.234C118.665 145.361 100.368 151.272 89.0424 163.812C76.8085 177.356 76.5751 194.343 76.9348 200.358C66.9584 201.55 56.2403 202.516 44.6529 203.135C44.063 203.234 43.5301 203.546 43.1558 204.013C42.7814 204.48 42.5918 205.068 42.6229 205.665C42.654 206.263 42.9038 206.828 43.3246 207.253C43.7454 207.678 44.3079 207.934 44.905 207.971C57.1007 207.314 68.3617 206.31 78.7879 205.054C83.1097 210.207 88.2921 214.572 94.1048 217.955C110.64 227.502 137.107 231.613 177.445 211.389C225.466 187.307 231.57 120.251 231.808 117.409ZM175.253 207.06C124.615 232.45 97.4456 217.848 84.5323 204.261C153.848 194.955 182.812 174.38 184.211 173.362C184.653 172.957 184.93 172.402 184.989 171.804C185.047 171.206 184.883 170.608 184.528 170.124C184.173 169.64 183.651 169.304 183.064 169.181C182.476 169.057 181.864 169.155 181.344 169.456C181.01 169.699 151.486 190.604 81.7781 199.721C81.5144 194.197 81.8861 178.969 92.6564 167.066C102.976 155.644 120.001 150.27 143.269 151.09C193.208 152.855 216.019 137.208 225.544 126.852C221.954 146.33 210.243 189.518 175.253 207.06Z" fill="#275938"/>
      <path d="M88.9434 150.31C89.1017 150.487 89.2876 150.637 89.4937 150.754C89.9964 151.016 90.5748 151.095 91.1293 150.977C91.6838 150.859 92.1798 150.551 92.532 150.107C92.8841 149.663 93.0703 149.109 93.0584 148.543C93.0466 147.976 92.8376 147.431 92.4673 147.001C88.4545 142.662 85.201 137.679 82.843 132.26C88.2564 131.464 100.409 128.647 106.126 119.029C110.53 111.604 109.999 101.953 104.538 90.3516C88.4381 56.147 117.417 31.7101 118.67 30.6933C119.03 30.3862 119.293 29.9809 119.427 29.5269C119.561 29.0729 119.559 28.5899 119.423 28.1366C119.287 27.6834 119.022 27.2797 118.66 26.9746C118.298 26.6696 117.855 26.4766 117.385 26.419C116.987 26.3769 77.4847 23.0376 56.0493 72.7422C48.825 89.4883 48.1171 103.495 53.9295 114.366C57.3748 120.797 62.5268 125.052 67.3116 127.815C70.4878 129.643 73.8812 131.065 77.412 132.047C80.0902 138.797 84.0005 144.99 88.9434 150.31ZM58.1966 112.077C53.1103 102.573 53.8789 89.9809 60.4887 74.6486C75.858 39.0094 100.112 32.4332 111.132 31.351C102.73 40.5732 86.558 63.5146 100.159 92.4112C104.885 102.467 105.499 110.585 101.971 116.541C96.8744 125.157 84.5279 127.205 80.9186 127.63C70.7029 98.1509 84.3456 63.6822 84.5133 63.2536C84.6391 62.9613 84.7047 62.6466 84.7062 62.3284C84.7077 62.0102 84.6451 61.695 84.522 61.4015C84.399 61.108 84.218 60.8424 83.9901 60.6204C83.7621 60.3984 83.4917 60.2246 83.195 60.1095C82.9007 59.9896 82.5857 59.9291 82.2679 59.9314C81.9502 59.9336 81.636 59.9986 81.3435 60.1226C81.0509 60.2466 80.7857 60.4271 80.5631 60.6539C80.3405 60.8806 80.1649 61.1491 80.0463 61.4439C79.329 63.2063 66.3673 96.1216 75.392 126.25C70.281 124.313 62.5558 120.233 58.1966 112.077Z" fill="#275938"/>
    </g>
    <defs>
      <clipPath id="clip0_832_362">
        <rect width="208" height="208" fill="white" transform="translate(86 0.000488281) rotate(30)"/>
      </clipPath>
    </defs>
  </svg>
);