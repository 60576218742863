import React, { useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useNavigate } from 'react-router-dom';
import { Title } from 'components';
import { ArrowIcon, CalcIcon, CatalogIcon, DecoBoxIcon, HouseIcon } from 'resources/icons';
import css from './index.module.scss';
import { ChooseHelpModal, InteriorDecorationModal } from 'modals';

type TProps = {};

export const OffersScreen = ({  }: TProps) => {
  const navigate = useNavigate();
  const goCatalog = () => navigate('/catalog');

  const [isChooseHelpModalOpen, setIsChooseHelpModalOpen] = useState(false);
  const [isInteriorDecorationModalOpen, setIsInteriorDecorationModalOpen] = useState(false);
  
  return (
    <div className={css.wrap}>
      <div className={css.decoContainer}>
        <DecoBoxIcon className={css.iconL} />
        <DecoBoxIcon className={css.iconC} />
        <DecoBoxIcon className={css.iconR} />
      </div>

      <div className={css.container}>
        <div className={css.titleWrap}>
          <Title type='main_page_deco' level='h2'>Что мы предлагаем?</Title>
        </div>

        <ScrollContainer className={css.list} vertical={false}>
          <div className={css.item}>
            <button className={css.offer} onClick={goCatalog}>
              <CatalogIcon className={css.icon} />
              <div className={css.content}>
                <h4 className={css.title}>Широкий ассортимент</h4>
                <p className={css.description}>Экологичные изделия из натуральной древесины для дома и дачи, бань и саун, стройки и ремонта</p>
              </div>
              <div className={css.marker}>
                <p className={css.text}>Перейти в каталог</p>
                <ArrowIcon className={css.icon} />
              </div>
            </button>
          </div>
          <div className={css.item}>
            <button className={css.offer} onClick={() => setIsChooseHelpModalOpen(true)}>
              <CalcIcon className={css.icon} />
              <div className={css.content}>
                <h4 className={css.title}>Подбор материалов</h4>
                <p className={css.description}>Бесплатно помогаем с выбором и расчетом необходимого объема материалов</p>
              </div>
              <div className={css.marker}>
                <p className={css.text}>Оставить заявку</p>
                <ArrowIcon className={css.icon} />
              </div>
            </button>
          </div>
          <div className={css.item}>
            <button className={css.offer} onClick={() => setIsInteriorDecorationModalOpen(true)}>
              <HouseIcon className={css.icon} />
              <div className={css.content}>
                <h4 className={css.title}>Отделка помещений</h4>
                <p className={css.description}>Осуществляем отделочные работы в частных домах, банях и саунах в Нижнем Новгороде и области</p>
              </div>
              <div className={css.marker}>
                <p className={css.text}>Оставить заявку</p>
                <ArrowIcon className={css.icon} />
              </div>
            </button>
          </div>
        </ScrollContainer>
      </div>

      <ChooseHelpModal isView={isChooseHelpModalOpen} closeFn={() => setIsChooseHelpModalOpen(false)} />
      <InteriorDecorationModal isView={isInteriorDecorationModalOpen} closeFn={() => setIsInteriorDecorationModalOpen(false)} />
    </div>
  );
};
