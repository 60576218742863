import React from 'react';

type TProps = {
  className?: string
}

export const BoxIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 8.00013C22.0097 7.93046 22.0097 7.85979 22 7.79013V7.71013C21.9828 7.65431 21.9593 7.60063 21.93 7.55013C21.917 7.52133 21.9002 7.49441 21.88 7.47013L21.78 7.34013L21.7 7.28013L21.58 7.19013L12.58 2.19013C12.428 2.10236 12.2555 2.05615 12.08 2.05615C11.9045 2.05615 11.732 2.10236 11.58 2.19013L2.58 7.19013L2.49 7.26013L2.38 7.34013C2.35095 7.37283 2.32732 7.40996 2.31 7.45013C2.27801 7.47882 2.25097 7.51261 2.23 7.55013C2.20403 7.59401 2.18387 7.64106 2.17 7.69013C2.16441 7.72323 2.16441 7.75703 2.17 7.79013C2.10125 7.84941 2.04367 7.92054 2 8.00013V16.0001C2.0013 16.1783 2.05019 16.3529 2.14161 16.5059C2.23303 16.6588 2.36367 16.7846 2.52 16.8701L11.52 21.8701C11.5613 21.8942 11.6049 21.9143 11.65 21.9301H11.75C11.9143 21.97 12.0857 21.97 12.25 21.9301H12.35L12.49 21.8701L21.49 16.8701C21.6445 16.7833 21.7731 16.657 21.8627 16.5041C21.9523 16.3513 21.9997 16.1773 22 16.0001V8.00013ZM12 11.8701L5.06 8.00013L7.82 6.48013L14.65 10.3801L12 11.8701ZM12 4.15013L18.94 8.00013L16.7 9.25013L9.87 5.34013L12 4.15013ZM4 9.70013L11 13.6201V19.3001L4 15.4101V9.70013ZM13 19.3001V13.6201L16 11.9401V15.0001L18 14.0001V10.8201L20 9.71013V15.4101L13 19.3001Z" fill="black"/>
  </svg>
);