import React from 'react';

type TProps = {
  className?: string
}

export const HouseIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4998 54.1667H16.6664V83.3334C16.6664 87.9292 20.4039 91.6667 24.9998 91.6667H74.9998C79.5956 91.6667 83.3331 87.9292 83.3331 83.3334V54.1667H87.4998C88.3237 54.1665 89.1291 53.922 89.8142 53.4642C90.4992 53.0063 91.0331 52.3557 91.3484 51.5944C91.6637 50.8332 91.7462 49.9955 91.5855 49.1874C91.4248 48.3793 91.0281 47.6369 90.4456 47.0542L52.9456 9.55419C52.559 9.16692 52.0998 8.85968 51.5943 8.65005C51.0888 8.44042 50.547 8.33252 49.9998 8.33252C49.4525 8.33252 48.9107 8.44042 48.4052 8.65005C47.8997 8.85968 47.4405 9.16692 47.0539 9.55419L9.55393 47.0542C8.97139 47.6369 8.5747 48.3793 8.414 49.1874C8.2533 49.9955 8.33581 50.8332 8.65111 51.5944C8.9664 52.3557 9.50031 53.0063 10.1854 53.4642C10.8704 53.922 11.6758 54.1665 12.4998 54.1667ZM41.6664 83.3334V62.5H58.3331V83.3334H41.6664ZM49.9998 18.3917L74.9998 43.3917V62.5L75.0039 83.3334H66.6664V62.5C66.6664 57.9042 62.9289 54.1667 58.3331 54.1667H41.6664C37.0706 54.1667 33.3331 57.9042 33.3331 62.5V83.3334H24.9998V43.3917L49.9998 18.3917Z" fill="#ECECEC"/>
  </svg>
);