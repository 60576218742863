import React from 'react';

type TProps = {
  className?: string
}

export const WarningIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.2538 37.5H48.7538V56.25H41.2538V37.5ZM41.25 60H48.75V67.5H41.25V60Z" fill="#B9B9B9"/>
    <path d="M51.6301 15.7501C50.3251 13.2938 47.7826 11.7676 45.0001 11.7676C42.2176 11.7676 39.6751 13.2938 38.3701 15.7538L10.8526 67.7401C10.2419 68.8817 9.93946 70.1628 9.97497 71.4569C10.0105 72.7511 10.3827 74.0137 11.0551 75.1201C11.7177 76.2315 12.6587 77.1509 13.7852 77.7875C14.9118 78.4241 16.1849 78.7559 17.4788 78.7501H72.5213C75.1763 78.7501 77.5801 77.3926 78.9488 75.1201C79.62 74.0133 79.9917 72.7508 80.0272 71.4569C80.0627 70.1629 79.7608 68.882 79.1513 67.7401L51.6301 15.7501ZM17.4788 71.2501L45.0001 19.2638L72.5401 71.2501H17.4788Z" fill="#B9B9B9"/>
  </svg>
);