import React, { useState } from 'react';
import classNames from 'classnames';
import { ArrowIcon, ChevronIcon } from 'resources/icons';
import { Button } from 'components';
import css from './index.module.scss';
import { CallRequestModal } from 'modals';

type TProps = {
  className?: string,
};

export const CallRequestTile = ({ className }: TProps) => {
  const [isModalView, setIsModalView] = useState(false);

  const openModalWindow = () => setIsModalView(true);
  const closeModalWindow = () => setIsModalView(false);

  return (
    <>
      <div className={classNames(css.wrap, className)}>
        <h3 className={css.title}>Есть вопрос?</h3>
        <div className={css.description}>
          <ChevronIcon className={css.icon} />
          <p className={css.text}>Мы перезвоним вам и проконсультируем по интересующим вас вопросам</p>
        </div>
        <Button 
          text='Оставить заявку'
          onClick={openModalWindow}
          className={css.button}
        />

        <ArrowIcon className={css.arrow} />
      </div>

      <CallRequestModal isView={isModalView} closeFn={closeModalWindow} />
    </>
  );
};
