import React from 'react';

type TProps = {
  className?: string
}

export const ArrowIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.9395 25.9395L19.0605 28.0605L29.121 18L19.0605 7.93951L16.9395 10.0605L23.379 16.5H9V19.5H23.379L16.9395 25.9395Z" fill="#333333"/>
  </svg>
);