import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {};

export const NoOrdersTile = ({  }: TProps) => {

  return (
    <div className={css.wrap}>
      <div className={css.text}>
        <p className={css.title}>Похоже, вы еще не заказывали у нас, и ваш список заказов сейчас пуст</p>
        <p className={css.subtitle}>Хорошо, что это легко исправить, у нас наверняка найдется то, что вам нужно!</p>
      </div>
      <Link to='/catalog' className={css.link}>
        <p className={css.label}>{ 'Вперед, за покупками!' }</p>
        <ArrowIcon className={css.icon} />
      </Link>
    </div>
  );
};
