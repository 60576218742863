import React from 'react';
import { InView } from 'react-intersection-observer';
import classNames from 'classnames';
import { GridViewGrid } from 'resources/icons';
import css from './index.module.scss';

export type TPaginationObserverProps = {
  isLoading: boolean,
  onViewAction: () => void,
  stopUse: boolean,
};

export const PaginationObserver = ({ isLoading, onViewAction, stopUse }: TPaginationObserverProps) => {

  const onViewChanged = (isLoading: boolean) => (inView: boolean, entry: IntersectionObserverEntry) => {
    if (inView && entry.isIntersecting && !isLoading) {
      onViewAction();
    }
  };

  if (stopUse) 
    return <></>;

  return (
    <div className={css.wrap}>
      <InView threshold={0} onChange={onViewChanged(isLoading)}>
        {({ ref }) => (
          <button 
            className={classNames(css.observer, { [css.loading]: isLoading })} 
            ref={ref} 
            key="_observer_point" 
            onClick={onViewAction}
          >
            { !isLoading && <span className={css.text}>Показать больше</span> }
            <GridViewGrid className={css.icon} />
          </button>
        )}
      </InView>
    </div>
  );
};
