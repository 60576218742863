import React from 'react';

type TProps = {
  className?: string
}

export const EditIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.5 1.50879L14.75 3.75879L13.0347 5.47479L10.7848 3.22479L12.5 1.50879ZM3.5 10.4998V12.7498H5.75L11.9742 6.53454L9.72425 4.28454L3.5 10.4998ZM3.5 14.9998H15.5V16.4998H3.5V14.9998Z" fill="white"/>
  </svg>
);