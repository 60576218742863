import React from 'react';

type TProps = {
  className?: string
}

export const DeliveryIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_264_188)">
      <path d="M3.2 2.4C3.2 1.0745 4.2745 0 5.6 0H18.4C19.725 0 20.8 1.0745 20.8 2.4V12.8H30.4V17.6C31.285 17.6 32 18.315 32 19.2C32 20.085 31.285 20.8 30.4 20.8H28.8C28.8 23.45 26.65 25.6 24 25.6C21.305 25.6 19.2 23.45 19.2 20.8H12.8C12.8 23.45 10.65 25.6 8 25.6C5.305 25.6 3.2 23.45 3.2 20.8V14.4H10.4C10.84 14.4 11.2 14.04 11.2 13.6C11.2 13.16 10.84 12.8 10.4 12.8H0.8C0.3582 12.8 0 12.44 0 12C0 11.56 0.3582 11.2 0.8 11.2H12C12.44 11.2 12.8 10.84 12.8 10.4C12.8 9.96 12.44 9.6 12 9.6H2.4C1.958 9.6 1.6 9.24 1.6 8.8C1.6 8.36 1.958 8 2.4 8H13.6C14.04 8 14.4 7.64 14.4 7.2C14.4 6.76 14.04 6.4 13.6 6.4H0.8C0.3582 6.4 0 6.04 0 5.6C0 5.16 0.3582 4.8 0.8 4.8H3.2V2.4ZM8 23.2C9.325 23.2 10.4 22.125 10.4 20.8C10.4 19.475 9.325 18.4 8 18.4C6.675 18.4 5.6 19.475 5.6 20.8C5.6 22.125 6.675 23.2 8 23.2ZM24 18.4C22.675 18.4 21.6 19.475 21.6 20.8C21.6 22.125 22.675 23.2 24 23.2C25.325 23.2 26.4 22.125 26.4 20.8C26.4 19.475 25.325 18.4 24 18.4ZM23.335 8H20V4.8H23.335C24.185 4.8 24.955 5.135 25.6 5.735L29.465 9.6C30.065 10.2 30.4 11.015 30.4 11.865V14.4H27.2V11.865L23.335 8Z" fill="#D7D7D7"/>
    </g>
    <defs>
      <clipPath id="clip0_264_188">
        <rect width="32" height="25.6" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);