import React from 'react';
import { TOrder } from 'store/slices/ordersLocal/entities';
import { FilePdfIcon } from 'resources/icons';
import { downloadBase64File, getNounProduct } from 'resources/helpers';
import { useDispatch } from 'react-redux';
import css from './index.module.scss';
import { OrdersLocal } from 'store/slices';

type TProps = {
  order: TOrder,
};

export const OrderListUnit = ({ order }: TProps) => {
  const dispatch = useDispatch();

  const downloadInvoice = () => {
    if (order.pdfInvoiceBase64)
      downloadBase64File(order.pdfInvoiceBase64, `Заказ №${order.number}.pdf`);
  }

  return (
    <div className={css.wrap}>
      <header className={css.header}>
        <h3 className={css.number}>{ `№ ${order.number}` }</h3>
        <p className={css.date} onClick={() => dispatch(OrdersLocal.removeOrder(order.number))}>{ order.date }</p>
      </header>
      <div className={css.content}>
        {
          order.pdfInvoiceBase64
          ? (
            <button 
              className={css.downloadInvoiceButton}
              onClick={downloadInvoice}
            >
              <FilePdfIcon className={css.icon} />
              <div className={css.text}>
                <p className={css.title}>Накладная</p>
                <p className={css.description}>Нажмите, чтобы загрузить файл в формате PDF</p>
              </div>
            </button>
          )
          : (
            <div className={css.notFileLabel}>
              <FilePdfIcon className={css.icon} />
              <div className={css.text}>
                <p className={css.title}>Файл недоступен</p>
                <p className={css.description}>Вы можете запросить файл накладной у менеджера по телефону</p>
              </div>
            </div>
          )
        }
        <div className={css.params}>
          <div className={css.field}>
            <p className={css.label}>в заказе:</p>
            <p className={css.value}>{ `${order.amount} ${getNounProduct(order.amount)}` }</p>
          </div>
          <div className={css.field}>
            <p className={css.label}>на сумму:</p>
            <p className={css.value}>{ `${order.totalPrice?.toLocaleString('ru') || '-' } руб` }</p>
          </div>
        </div>
      </div>
    </div>
  );
};
