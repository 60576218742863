import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl, prepareHeadersBase } from 'resources/api';
import { 
  T_Params_GetProductGroupsList, T_DoneResponse_GetProductGroupsList,
  T_DoneResponse_GetProductGroupWithKey,
} from './entities';
import { 
  transformGetProductGroupWithKey, 
  transformGetProductGroupsList 
} from './transform';


export const ProductGroupAPI = createApi({
  reducerPath: 'ProductGroupAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    
    getProductGroupsList: builder.query<T_DoneResponse_GetProductGroupsList, T_Params_GetProductGroupsList>({
      query: ({ catalogSectionId, currentPage }) => ({
        url: 'product-groups',
        params: {
          'filters[catalog_section]': catalogSectionId,
          'pagination[page]': currentPage,
          'pagination[pageSize]': 20,
          'populate[image][fields][0]': 'url',
          'populate[image][fields][1]': 'formats'
        }
      }),
      transformResponse: transformGetProductGroupsList,
    }),

    getProductGroupWithKey: builder.query<T_DoneResponse_GetProductGroupWithKey, string>({
      query: (productGroupKey) => ({
        url: `product-groups/key/${productGroupKey}`,
      }),
      transformResponse: transformGetProductGroupWithKey,
    }),
  })
});