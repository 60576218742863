export const MAX_SAVED_SEARCH_QUERIES = 7;

export type TUserData = {
  name: string,
  phone: string,
  email: string,
  place: string,
}

export type TUserDataFields = keyof TUserData;

export type TUserState = {
  data: TUserData,
  searchQueries: string[],
}

export type TUserDataChangePayload = {
  field: TUserDataFields,
  value: string,
}