import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ChevronIcon } from 'resources/icons';
import { TBreadcrumb } from 'store/globalEntities';
import css from './index.module.scss';


type TProps = {
  breadcrumbs?: TBreadcrumb[],
  currentCrumb?: string,
}

export const BreadcrumbsBar = ({ breadcrumbs = [], currentCrumb = '' }: TProps) => {
  breadcrumbs = [ { name: 'Каталог', crumb: 'catalog' }, ...breadcrumbs ];
  
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (currentCrumb) {
      const crumbspath = breadcrumbs.reduce((acc, current) => `${acc}/${current.crumb}`, '') + `/${currentCrumb}`;
      
      if (crumbspath !== pathname)
        navigate(crumbspath);
    } 
  }, [pathname, breadcrumbs, currentCrumb]);


  let currentLinkTo = '';
  const points = (
    breadcrumbs.map((point, index) => {
        currentLinkTo += `/${point.crumb}`;

        return (
          <div className={css.linkWrap} key={currentLinkTo} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            { !!index && <ChevronIcon className={css.icon} /> }
            <Link
              className={css.link}
              to={currentLinkTo}
              key={currentLinkTo}
              itemProp="item"
            >
              { point.name }
              <meta itemProp="position" content={String(index + 1)} />
              <meta itemProp="name" content={point.name} />
            </Link>
          </div>
        );
      }
    )
  );

  return (
    <div className={css.wrap} itemScope itemType="https://schema.org/BreadcrumbList">
      { points }
    </div>
  );
};
