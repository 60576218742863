import React from 'react';

type TProps = {
  className?: string
}

export const UndoIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 10H15C16.654 10 18 11.346 18 13C18 14.654 16.654 16 15 16H12V18H15C17.757 18 20 15.757 20 13C20 10.243 17.757 8 15 8H9V5L4 9L9 13V10Z" fill="black"/>
  </svg>
);