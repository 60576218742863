import { PayloadAction } from "@reduxjs/toolkit";
import { TOrdersState } from "./entities";
import { T_Response_MakeOrder } from "../requestsAPI/entities";

export const OrdersSliceActions = {

  addOrder (state: TOrdersState, { payload }: PayloadAction<T_Response_MakeOrder>) {
    if (state.orders.length > 10)
      state.orders[9].pdfInvoiceBase64 = null;

    state.orders = [ {
      number: payload.orderNumber,
      date: payload.orderDate,
      amount: payload.amountProducts,
      totalPrice: payload.totalPrice,
      pdfInvoiceBase64: payload.pdfInvoiceBase64,
    }, ...state.orders ];
  },

  removeOrder (state: TOrdersState, { payload: number }: PayloadAction<string>) {
    state.orders = state.orders.filter(order => order.number !== number);
  },
}