import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import {
  AppOutlet,
  CartPage,
  CatalogPage,
  CatalogSectionPage,
  ServicesPage,
  MainPage,
  ProductGroupPage,
  ProductPage,
  ContactsPage,
  UserPage,
  GalleryPage,
  GalleryImagePage,
} from 'pages';

type TPageInfo = {
  path: string,
  name: string,
  keywords: string[],
}

export type TPagesNames = 'contacts' | 'services' | 'basket' | 'user' | 'ourWorks' | 'catalog';

export const AppPagesInfo: { [arg in TPagesNames]: TPageInfo } = {
  contacts: {
    path: '/contacts',
    name: 'Контакты',
    keywords: ['контакты', 'телефон', 'почта', 'связь', 'менеджер', 'отделка', 'заявка', 'звонок'],
  },
  services: {
    path: '/services',
    name: 'Услуги',
    keywords: ['услуги', 'доставка', 'отделка', 'помощь', 'работа', 'заявка', 'звонок'],
  },
  basket: {
    path: '/basket',
    name: 'Корзина',
    keywords: ['корзина', 'товары', 'сохраненное', 'заказ'],
  },
  user: {
    path: '/user',
    name: 'Аккаунт',
    keywords: ['данные', 'информация', 'заказ', 'записи', 'файл', 'накладная'],
  },
  ourWorks: {
    path: '/our-works',
    name: 'Наши работы',
    keywords: ['работы', 'примеры', 'фото', 'картинки', 'отделка'],
  },
  catalog: {
    path: '/catalog',
    name: 'Каталог',
    keywords: ['каталог', 'товары', 'группы', 'продукты', 'купить'],
  },
}


export const AppRouter = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <MainPage />,
    },
    {
      path: AppPagesInfo.basket.path,
      element: <CartPage />,
    },
    {
      path: '/',
      element: <AppOutlet />,
      children: [
        {
          path: AppPagesInfo.contacts.path,
          element: <ContactsPage />
        },
        {
          path: AppPagesInfo.ourWorks.path,
          element: <GalleryPage />
        },
        {
          path: `${AppPagesInfo.ourWorks.path}/:imageId`,
          element: <GalleryImagePage />
        },
        {
          path: AppPagesInfo.services.path,
          element: <ServicesPage />,
        },
        {
          path: AppPagesInfo.user.path,
          element: <UserPage />,
        },
        {
          path: AppPagesInfo.catalog.path,
          element: <CatalogPage />,
        },
        {
          path: '/catalog/:catalogSectionKey',
          element: <CatalogSectionPage />
        },
        {
          path: '/catalog/:catalogSectionKey/:productGroupKey',
          element: <ProductGroupPage />
        },
        {
          path: '/catalog/:catalogSectionKey/:productGroupKey/:productId',
          element: <ProductPage />
        },
        {
          path: '*',
          element: <Navigate to='/' />
        }
      ]
    },
  ]);

  return routes;
}
