import React from 'react';

type TProps = {
  className?: string
}

export const DocumentIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M66.3435 28.6198C66.1855 28.2581 65.9642 27.9273 65.6902 27.6432L45.6902 7.64317C45.406 7.36911 45.0753 7.14788 44.7135 6.98984C44.6135 6.94317 44.5068 6.9165 44.4002 6.87984C44.1213 6.78492 43.8309 6.72775 43.5368 6.70984C43.4668 6.70317 43.4035 6.6665 43.3335 6.6665H20.0002C16.3235 6.6665 13.3335 9.6565 13.3335 13.3332V66.6665C13.3335 70.3432 16.3235 73.3332 20.0002 73.3332H60.0002C63.6768 73.3332 66.6668 70.3432 66.6668 66.6665V29.9998C66.6668 29.9298 66.6302 29.8665 66.6235 29.7932C66.6072 29.4989 66.55 29.2083 66.4535 28.9298C66.4202 28.8232 66.3902 28.7198 66.3435 28.6198ZM55.2868 26.6665H46.6668V18.0465L55.2868 26.6665ZM20.0002 66.6665V13.3332H40.0002V29.9998C40.0002 30.8839 40.3514 31.7317 40.9765 32.3569C41.6016 32.982 42.4494 33.3332 43.3335 33.3332H60.0002L60.0068 66.6665H20.0002Z" fill="#B9B9B9"/>
    <path d="M26.6665 39.9998H53.3332V46.6665H26.6665V39.9998ZM26.6665 53.3332H53.3332V59.9998H26.6665V53.3332ZM26.6665 26.6665H33.3332V33.3332H26.6665V26.6665Z" fill="#B9B9B9"/>
  </svg>
);