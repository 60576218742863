export const baseUrl = () => (
  'https://api.ecotrees-shop.ru'
);

export const apiUrl = () => (
  'https://api.ecotrees-shop.ru/api'
);

export const prepareHeadersBase = (headers: Headers): Headers => {
  headers.set('Content-Type', 'application/json');
  return headers;
}
