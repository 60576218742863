import React from 'react';

type TProps = {
  className?: string
}

export const CatalogIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 29.3333H28V26.6667H8.016C7.4 26.6507 6.66667 26.4067 6.66667 25.3333C6.66667 24.26 7.4 24.016 8.016 24H28V5.33334C28 3.86267 26.804 2.66667 25.3333 2.66667H8C6.392 2.66667 4 3.73201 4 6.66667V25.3333C4 28.268 6.392 29.3333 8 29.3333ZM6.66667 10.6667V6.66667C6.66667 5.59334 7.4 5.34934 8 5.33334H25.3333V21.3333H6.66667V10.6667Z" fill="#D7D7D7"/>
    <path d="M10.6667 8H22.6667V10.6667H10.6667V8Z" fill="#D7D7D7"/>
  </svg>
);