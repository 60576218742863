import React from 'react';
import classNames from 'classnames';
import css from './index.module.scss';

type TProps = {
  isLoading: boolean,
  className?: string
};

export const Loader = ({ isLoading, className = '' }: TProps) => {

  if (!isLoading) return <></>;
  
  return (
    <div className={classNames(css.wrap, className)}>
      <div className={css.loader}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  );
};
