import React from 'react';

type TProps = {
  className?: string
}

export const NavSearchIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.39179 18.3918C5.89917 17.9057 6.393 17.4058 6.87271 16.8924C7.27571 16.4829 7.51837 16.185 7.51837 16.185L10.5517 14.7366C9.3373 13.3593 8.66707 11.5862 8.66671 9.75C8.66671 5.56942 12.0684 2.16667 16.25 2.16667C20.4317 2.16667 23.8334 5.56942 23.8334 9.75C23.8334 13.9306 20.4317 17.3333 16.25 17.3333C14.3401 17.3333 12.5992 16.6183 11.2635 15.4494L9.81504 18.4828C9.81504 18.4828 9.51712 18.7254 9.10762 19.1284C8.68837 19.5217 8.13696 20.0536 7.60829 20.6093L6.13712 22.1173L5.48279 22.8172L3.18504 20.5194L3.88487 19.8651C4.29546 19.4621 4.84362 18.9269 5.39179 18.3918ZM16.25 15.1667C19.2368 15.1667 21.6667 12.7368 21.6667 9.75C21.6667 6.76325 19.2368 4.33333 16.25 4.33333C13.2633 4.33333 10.8334 6.76325 10.8334 9.75C10.8334 12.7368 13.2633 15.1667 16.25 15.1667Z" fill="#333333"/>
  </svg>
);