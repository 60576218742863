import React from 'react';

type TProps = {
  className?: string
}

export const TelegramIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M86.1042 15.4877L12.2292 43.9752C7.18749 46.0002 7.21666 48.8127 11.3042 50.0669L30.2708 55.9836L74.1542 28.2961C76.2292 27.0336 78.125 27.7127 76.5667 29.0961L41.0125 61.1836H41.0042L41.0125 61.1877L39.7042 80.7377C41.6208 80.7377 42.4667 79.8586 43.5417 78.8211L52.7542 69.8627L71.9167 84.0169C75.45 85.9627 77.9875 84.9627 78.8667 80.7461L91.4458 21.4627C92.7333 16.3002 89.475 13.9627 86.1042 15.4877Z" fill="#D7D7D7"/>
  </svg>
);