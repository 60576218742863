import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { apiUrl, prepareHeadersBase } from "resources/api";
import { transformGetGalleryImageResponse, transformGetGalleryPageResponse } from "./transform";
import { TGalleryImage } from "store/globalEntities";
import { T_GetGalleryPage_DoneResponse, T_GetGalleryPage_Params } from "./entities";

export const GalleryAPI = createApi({
  reducerPath: 'GalleryAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    
    getGalleryPage: builder.query<T_GetGalleryPage_DoneResponse, T_GetGalleryPage_Params>({
      query: ({ page, amount = 20 }: T_GetGalleryPage_Params) => ({
        url: 'gallery-images',
        method: 'get',
        params: {
          'populate[0]': 'image',
          'populate[1]': 'products',
          'populate[2]': 'comment',
          'pagination[pageSize]': amount,
          'pagination[page]': page,
        }
      }),
      transformResponse: transformGetGalleryPageResponse,
    }),

    getGalleryImage: builder.query<TGalleryImage | null, number>({
      query: (id: number) => ({
        url: `gallery-images/id/${id}`,
        method: 'get'
      }),
      transformResponse: transformGetGalleryImageResponse,
    }),
  })
});