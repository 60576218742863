import React from 'react';

type TProps = {
  className?: string
}

export const SearchIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3333 33C21.5874 32.9993 24.7478 31.9099 27.3111 29.9053L35.3705 37.9647L37.9628 35.3723L29.9035 27.313C31.9091 24.7494 32.9992 21.5883 33 18.3333C33 10.2465 26.4201 3.66666 18.3333 3.66666C10.2465 3.66666 3.66663 10.2465 3.66663 18.3333C3.66663 26.4202 10.2465 33 18.3333 33ZM18.3333 7.33332C24.3998 7.33332 29.3333 12.2668 29.3333 18.3333C29.3333 24.3998 24.3998 29.3333 18.3333 29.3333C12.2668 29.3333 7.33329 24.3998 7.33329 18.3333C7.33329 12.2668 12.2668 7.33332 18.3333 7.33332Z" fill="#D7D7D7"/>
    <path d="M20.922 15.741C21.6168 16.4377 22 17.358 22 18.3333H25.6667C25.6683 17.3698 25.4789 16.4155 25.1095 15.5256C24.7401 14.6356 24.1979 13.8278 23.5143 13.1487C20.7387 10.3767 15.9262 10.3767 13.1523 13.1487L15.741 15.7447C17.1343 14.355 19.536 14.3587 20.922 15.741Z" fill="#D7D7D7"/>
  </svg>
);