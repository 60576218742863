import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import { ArrowIcon } from 'resources/icons';
import { Image, Title } from 'components';
import { AppPagesInfo } from 'app/AppRouter';
import css from './index.module.scss';
import { GalleryAPI } from 'store/slices';

type TProps = {};

export const OurWorksScreen = ({  }: TProps) => {
  const navigate = useNavigate();
  const goGallery = () => navigate(AppPagesInfo.ourWorks.path);

  const { data, isFetching } = GalleryAPI.useGetGalleryPageQuery({ page: 1, amount: 4 });
  
  return (
    <div className={css.wrap}>
      <div className={css.container}>
        <header className={css.header}>
          <Title type='main_page_deco' level='h2' className={css.title}>Наши работы и материалы</Title>
          <button className={css.button} onClick={goGallery}>
            <p className={css.text}>Больше фото</p>
            <ArrowIcon className={css.icon} />
          </button>
        </header>
        <ScrollContainer className={css.content} vertical={false}>
          {
            data && data.images.map(img => (
              <Link className={css.imageWrap} key={img.id} to={`${AppPagesInfo.ourWorks.path}/${img.id}`}>
                <Image image={img.image} className={css.image} />
                <div className={css.filter}/>
              </Link>
            ))
          }
        </ScrollContainer>
      </div>
    </div>
  );
};
