import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MakeOrderErrorModal, MakeOrderModal, MakeOrderSuccessModal, ModalWindow } from 'modals';
import { CartLocal, OrdersLocal, RequestsAPI } from 'store/slices';
import { TUserData } from 'store/slices/userLocal/entities';
import { transformTelForMask } from 'resources/helpers';
import css from './index.module.scss';
import { Loader } from 'components';

type TProps = {
  inWork: boolean,
  finishWork: () => void,
};

type TControllerStatus = 'unwork' | 'register' | 'loading' | 'success' | 'error';

export const MakeOrderController = ({ inWork, finishWork }: TProps) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<TControllerStatus>('unwork');

  const cartUnits = useSelector(CartLocal.getUnitsList);
  const [ makeOrder, { data: response, isLoading } ] = RequestsAPI.useMakeOrderMutation();
  const registerSubmit = (data: TUserData) => {
    makeOrder({
      customer: { ...data, phone: transformTelForMask(data.phone) },
      units: cartUnits.map(unit => ({ id: unit.id, amount: unit.amount })),
    });
  }

  const onModalClose = () => {
    setStatus('unwork');
    finishWork();
  }

  const onSuccesClose = () => {
    if (response && response.isToShopSended) 
      dispatch(CartLocal.clearCart());

    onModalClose();
  }

  useEffect(() => {
    if (isLoading) setStatus('loading');
  }, [isLoading]);

  useEffect(() => {
    if (response) {
      if (response.isToShopSended) {
        setStatus('success');
        dispatch(OrdersLocal.addOrder(response));
      } else {
        setStatus('error');
      }
    } else {
      setStatus('register');
    }
  }, [response]);

  useEffect(() => {
    if (!inWork) setStatus('unwork');
    else setStatus('register');
  }, [inWork]);

  
  return (
    <>
      <MakeOrderModal 
        isView={status === 'register'} 
        closeFn={onModalClose} 
        onSubmit={registerSubmit}
      />

      <ModalWindow 
        title='Оформление заказа' 
        closeFn={onModalClose} 
        isView={status === 'loading'}
      >
        <div className={css.loadingWrap}>
          <Loader isLoading />
          <p>Оформляем ваш заказ<br />Ожидайте...</p>
        </div>
      </ModalWindow>

      <MakeOrderSuccessModal 
        isView={status === 'success'}
        closeFn={onSuccesClose}
        response={response}
      />

      <MakeOrderErrorModal
        isView={status === 'error'}
        closeFn={onModalClose}
      />
    </>
  );
};
