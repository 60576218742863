import React from 'react';

type TProps = {
  className?: string
}

export const CalcIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M79.1667 8.33331H20.8333C16.2375 8.33331 12.5 12.0708 12.5 16.6666V83.3333C12.5 87.9292 16.2375 91.6667 20.8333 91.6667H79.1667C83.7625 91.6667 87.5 87.9292 87.5 83.3333V16.6666C87.5 12.0708 83.7625 8.33331 79.1667 8.33331ZM20.8333 83.3333V16.6666H79.1667L79.1708 83.3333H20.8333Z" fill="#ECECEC"/>
    <path d="M29.167 50H37.5003V58.3333H29.167V50ZM29.167 66.6667H37.5003V75H29.167V66.6667ZM45.8337 50H54.167V58.3333H45.8337V50ZM29.167 25H70.8337V41.6667H29.167V25ZM45.8337 66.6667H54.167V75H45.8337V66.6667ZM62.5003 50H70.8337V75H62.5003V50Z" fill="#ECECEC"/>
  </svg>
);