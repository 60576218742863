import React from 'react';
import { useSelector } from 'react-redux';
import { DocumentIcon } from 'resources/icons';
import { OrderListUnit } from 'components/OrderListUnit';
import { OrdersLocal } from 'store/slices';
import { NoOrdersTile } from 'tiles';
import css from './index.module.scss';

type TProps = {};

export const OrderList = ({  }: TProps) => {
  const orders = useSelector(OrdersLocal.getOrdersList);

  return (
    <div className={css.wrap}>
      <header className={css.header}>
        <DocumentIcon className={css.icon} />
        <div className={css.text}>
          <h2 className={css.title}>Ваши заказы</h2>
          <p className={css.description}>Для скачивания доступны файлы накладных последних десяти заказов, более старые заказы доступны как запись в списке</p>
        </div>
      </header>
      { !orders.length && <NoOrdersTile /> }
      <ul className={css.list}>
        {
          orders.map(order => (
            <li className={css.item} key={order.number}>
              <OrderListUnit order={order} />
            </li>
          ))
        }
      </ul>
    </div>
  );
};
