import React from 'react';

type TProps = {
  className?: string
}

export const PhotoIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 6C7.374 6 6 7.374 6 9C6 10.626 7.374 12 9 12C10.626 12 12 10.626 12 9C12 7.374 10.626 6 9 6ZM9 10.5C8.20125 10.5 7.5 9.79875 7.5 9C7.5 8.20125 8.20125 7.5 9 7.5C9.79875 7.5 10.5 8.20125 10.5 9C10.5 9.79875 9.79875 10.5 9 10.5Z" fill="#9F4545"/>
    <path d="M15 3.75H13.0605L11.0302 1.71975C10.9607 1.64997 10.8781 1.59463 10.7871 1.55691C10.6961 1.5192 10.5985 1.49986 10.5 1.5H7.5C7.40149 1.49986 7.30393 1.5192 7.21293 1.55691C7.12192 1.59463 7.03928 1.64997 6.96975 1.71975L4.9395 3.75H3C2.17275 3.75 1.5 4.42275 1.5 5.25V13.5C1.5 14.3273 2.17275 15 3 15H15C15.8273 15 16.5 14.3273 16.5 13.5V5.25C16.5 4.42275 15.8273 3.75 15 3.75ZM3 13.5V5.25H5.25C5.4495 5.25 5.64 5.17125 5.78025 5.03025L7.8105 3H10.1895L12.2197 5.03025C12.2893 5.10003 12.3719 5.15537 12.4629 5.19309C12.5539 5.2308 12.6515 5.25014 12.75 5.25H15L15.0015 13.5H3Z" fill="#9F4545"/>
  </svg>
);