import React from 'react';
import css from './index.module.scss';
import { ContactsIcon, CopiedIcon, MailIcon } from 'resources/icons';
import { transformTelForMask } from 'resources/helpers';
import { useNavigate } from 'react-router-dom';
import { useAlertContext } from 'app/context';
import classNames from 'classnames';

type TProps = {
  tel: string,
  email: string,
  className?: string,
};

export const ContactsTile = ({ tel, email, className }: TProps) => {
  const navigate = useNavigate();
  const { alert } = useAlertContext();
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints;

  const telCb = () => {
    navigator.clipboard.writeText(tel);
    alert({ text: 'Телефон скопирован в буфер обмена', icon: <CopiedIcon /> });
  }

  const emailCb = () =>  {
    navigator.clipboard.writeText(email);
    alert({ text: 'Почта скопирована в буфер обмена', icon: <CopiedIcon /> });
  }

  return (
    <div className={classNames(css.wrap, className)} itemScope itemType="https://schema.org/Organization">
      <meta itemProp='name' content='Ecotrees' />
      <div className={css.bracketLeft} />
      <div className={css.content}>
        <a 
          href={isTouchScreen ? `tel:${tel}` : undefined}
          onClick={telCb}
          className={css.card}
        >
          <ContactsIcon className={css.icon} />
          <div className={css.text}>
            <p className={css.comment}>Мы работаем с 8:00 до 20:00 по МСК</p>
            <p className={css.info} itemProp="telephone">{ transformTelForMask(tel) }</p>
          </div>
        </a>

        <a 
          href={isTouchScreen ? `mailto:${email}&body=Добрый день, у меня есть вопрос по товару ?subject=Вопрос по товару` : undefined}
          onClick={emailCb}
          className={css.card}
        >
          <MailIcon className={css.icon} />
          <div className={css.text}>
            <p className={css.comment}>Почта для вопросов и предложений</p>
            <p className={css.info} itemProp="email">{ email }</p>
          </div>
        </a>
      </div>
      <div className={css.bracketRight} />
    </div>
  );
};
