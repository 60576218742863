import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Title } from 'components';
import { DecoLeafOneIcon, DecoLeafTwoIcon, DeliveryIcon, LabelIcon, PeopleIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {};

export const AdvantagesScreen = ({  }: TProps) => {
  const navigate = useNavigate();

  const goToCatalog = () => navigate('/catalog');

  return (
    <div className={css.wrap}>
      <Title type='main_page_deco' level='h2' className={css.screenTitle}>Вы в хорошей компании!</Title>
      <div className={css.content}>
        <div className={css.experience}>
          <p className={css.number}>15</p>
          <p className={css.title}>лет на рынке</p>
          <p className={css.subtitle}>Мы знаем, что значит качество</p>
        </div>
        <div className={classNames(css.advatage, css['advantage-1'])}>
          <DeliveryIcon className={css.icon} />
          <div className={css.text}>
            <p className={css.title}>Бесплатная доставка по всей России</p>
            <p className={css.subtitle}>Быстро и с гарантией сохранности товара</p>
          </div>
        </div>
        <div className={classNames(css.advatage, css['advantage-2'])}>
          <LabelIcon className={css.icon} />
          <div className={css.text}>
            <p className={css.title}>Честная и понятная система скидок</p>
            <p className={css.subtitle}>Берите больше и забирайте товар по оптовой цене</p>
          </div>
        </div>
        <div className={classNames(css.advatage, css['advantage-3'])}>
          <PeopleIcon className={css.icon} />
          <div className={css.text}>
            <p className={css.title}>Наши клиенты довольны</p>
            <p className={css.subtitle}>Сотни людей и компаний<br />за годы работы</p>
          </div>
        </div>
        <Button 
          className={css.button}
          text='Перейти в каталог'
          style='black'
          size='tall'
          onClick={goToCatalog}
        />
      </div>

      <div className={css.deco}>
        <DecoLeafOneIcon className={css.deco1} />
        <DecoLeafTwoIcon className={css.deco2} />
      </div>
    </div>
  );
};
