import React from 'react';

type TProps = {
  className?: string
}

export const CheckIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33314 12.9883L5.58898 10.2442L4.41064 11.4225L8.33314 15.345L16.4223 7.25585L15.244 6.07751L8.33314 12.9883Z" fill="#9F4545"/>
  </svg>
);