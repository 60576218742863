import { createSlice } from "@reduxjs/toolkit";
import { TOrdersState } from "./entities";
import { OrdersSliceActions } from "./actions";

const initialState: TOrdersState = {
  orders: []
}

const OrdersSlice = createSlice({
  name: 'OrdersSlice',
  initialState,
  reducers: OrdersSliceActions,
});


export const OrdersReducer = OrdersSlice.reducer;
export const { addOrder, removeOrder } = OrdersSlice.actions;