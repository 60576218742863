import React, { useEffect } from 'react';
import { FormElements, Loader } from 'components';
import { useForm } from 'react-hook-form';
import { ChevronIcon, InfoIcon } from 'resources/icons';
import { ModalWindow, TModalWindowProps } from 'modals';
import { T_Body_ChooseHelp } from 'store/slices/requestsAPI/entities';
import { useAlertContext } from 'app/context';
import { RequestsAPI } from 'store/slices';
import css from './index.module.scss';

type TProps = Omit<TModalWindowProps, 'title'>;

type TFormData = T_Body_ChooseHelp;

export const ChooseHelpModal = ({ isView, closeFn }: TProps) => {
  const { alert } = useAlertContext();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<TFormData>({
    mode: 'onBlur',
  });

  const [ request, { data: response, isLoading, isError, isSuccess } ] = RequestsAPI.useChooseHelpRequestMutation();

  const onClose = () => {
    reset();
    closeFn();
  }

  const onSubmit = (data: TFormData) => {
    request(data);
  }

  useEffect(() => {
    if (isError || response && !response.answer) {
      reset();
      onClose();
      alert({ text: 'При обработке заявки произошла ошибка, попробуйте позже или перезвоните нам' });
    }

    if (isSuccess && response && response.answer) {
      reset();
      onClose();
      alert({ text: 'Ваша заявка на помощь в подборе материалов принята, мы сделаем рассчет и перезвоним вам' });
    }

  }, [response, isError, isSuccess]);

  return (
    <ModalWindow 
      title='Помощь в подборе материалов'
      isView={isView}
      closeFn={onClose}
    >
      <div className={css.description}>
        <InfoIcon className={css.icon} />
        <p className={css.text}>
          Используйте эту форму, если у вас есть сомнения в выборе подходящих отделочных материалов 
          или, если вам нужна помощь в расчете необходимого количества материалов для отделки. Наши
          сотрудники помогут вам подобрать подходящие для вашего помещения изделия и сделают рассчет
          необходимого их объема
        </p>
      </div>
      <Loader isLoading={isLoading} className={css.loader} />
      {
        !isLoading && (
        <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
          <FormElements.Input 
            label='Как к вам обращаться?' 
            type='text'
            placeholder='Александр Александрович'
            errorMessage={errors.name?.message}
            required
            register={register('name', {
              required: 'Это поле обязательно для заполнения',
              minLength: { value: 2, message: 'Минимальная длина 2 символа' },
              maxLength: { value: 50, message: 'Максимальная длина 30 символов' }
            })}
          />
          <FormElements.Input 
            label='Ваш номер телефона'
            type='tel'
            placeholder='8 (910) 888 1010'
            errorMessage={errors.phone?.message}
            required
            register={register('phone', {
              required: 'Это поле обязательно для заполнения',
              pattern: {
                value: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
                message: 'Некорректный формат телефонного номера'
              }
            })}
          />
          <FormElements.Textarea
            label='Опишите ваш объект'
            description='Укажите размеры помещения (или помещений): назначение помещения, 
                        длины стен, высоту потолков, размеры дверных и оконных проемов'
            placeholder='Парная: 2,6 х 1,9 м, высота потолков 2,1 м, одна форточка 0,4 х 0,5 м, дверь 2,0 х 0,7 м'
            errorMessage={errors.room?.message}
            register={register('room', {
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' }
            })}
          />
          <FormElements.Textarea
            label='Желаемые материалы'
            description='Укажите какие материалы вы хотели бы использовать для отделки, можете 
                        указать несколько или пропустить этот пункт, если сомневаетесь в выборе'
            placeholder='Вагонка липовая или осиновая сорт А для стен, насчет пола не уверен'
            errorMessage={errors.materials?.message}
            register={register('materials', {
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' }
            })}
          />
          <button type="submit" className={css.submitButton}>
            <span>Отправить заявку</span>
            <ChevronIcon className={css.icon} />
          </button>
          </form>
        )}
    </ModalWindow>
  );
};
