import React from 'react';

type TProps = {
  className?: string
}

export const BasketIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.7501 4.33333H2.16675V6.5H4.65842L8.47283 16.9899C8.62523 17.4075 8.90217 17.7683 9.26621 18.0235C9.63025 18.2786 10.0639 18.4159 10.5084 18.4167H19.5001V16.25H10.5084L9.71975 14.0833H19.5001C19.9334 14.0833 20.3256 13.8255 20.4957 13.4268L23.7457 5.8435C23.8169 5.67876 23.846 5.49889 23.8303 5.32011C23.8146 5.14132 23.7547 4.96925 23.6559 4.81941C23.5571 4.66957 23.4226 4.54668 23.2645 4.46182C23.1063 4.37696 22.9296 4.33281 22.7501 4.33333Z" fill="#333333"/>
    <path d="M11.375 22.75C12.2725 22.75 13 22.0225 13 21.125C13 20.2275 12.2725 19.5 11.375 19.5C10.4775 19.5 9.75 20.2275 9.75 21.125C9.75 22.0225 10.4775 22.75 11.375 22.75Z" fill="#333333"/>
    <path d="M17.875 22.75C18.7725 22.75 19.5 22.0225 19.5 21.125C19.5 20.2275 18.7725 19.5 17.875 19.5C16.9775 19.5 16.25 20.2275 16.25 21.125C16.25 22.0225 16.9775 22.75 17.875 22.75Z" fill="#333333"/>
  </svg>
);