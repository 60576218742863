import { 
  T_RawResponse_GetProductGroupsList, T_DoneResponse_GetProductGroupsList,
  T_RawResponse_GetProductGroupWithKey, T_DoneResponse_GetProductGroupWithKey,
} from "./entities";

export const transformGetProductGroupsList = (
  response: T_RawResponse_GetProductGroupsList
): T_DoneResponse_GetProductGroupsList => {

  const transformedResponse: T_DoneResponse_GetProductGroupsList = {
    data: response.data.map(productGroup => ({
      id: productGroup.id,
      ...productGroup.attributes,
      image: {
        url: productGroup.attributes.image.data.attributes.url,
        thumbnail: productGroup.attributes.image.data.attributes.formats.thumbnail.url,
      }
    })),
    pagination: response.meta.pagination,
  }

  return transformedResponse;
}


export const transformGetProductGroupWithKey = (
  response: T_RawResponse_GetProductGroupWithKey
): T_DoneResponse_GetProductGroupWithKey => {

  if (response.data === null) {
    return { data: null, breadcrumbs: [] };
  }

  const transformedResponse: T_DoneResponse_GetProductGroupWithKey = {
    data: {
      ...response.data,
      image: {
        url: response.data.image.url,
        thumbnail: response.data.image.formats.thumbnail.url,
      }
    },
    breadcrumbs: response.breadcrumbs
  }

  return transformedResponse;
}