import React, { useEffect, useState } from 'react';
import { Loader, Title } from 'components';
import { ArrowIcon, LabelIcon, MapIcon } from 'resources/icons';
import { RequestsAPI } from 'store/slices';
import { InteriorDecorationModal } from 'modals';
import { TService } from 'store/slices/requestsAPI/entities';
import { groupServicesTwoColumns } from 'resources/helpers';
import css from './index.module.scss';
import { ServicesPageMetaTags } from 'features';

export const DecorationScreen = () => {
  const [ isModalView, setIsModalView ] = useState(false);
  const [ services, setServices ] = useState<[TService[], TService[]] | null>(null);
  const { data, isLoading } = RequestsAPI.useGetShopServicesQuery();

  const openWindow = () => setIsModalView(true);
  const closeWindow = () => setIsModalView(false);

  useEffect(() => {
    if (data) setServices(groupServicesTwoColumns(data));
  }, [data]);

  return (
    <div className={css.wrap}>
      <Title type='base_page' level='h2'>Отделка бань и саун</Title>

      <div className={css.panel}>
        <div className={css.params}>
          <div className={css.item}>
            <MapIcon className={css.icon} />
            <div className={css.text}>
              <p className={css.title}>В Нижнем Новгороде и области</p>
              <p className={css.subtitle}>Выполняем отделочные и монтажные работы</p>
            </div>
          </div>
          <div className={css.item}>
            <LabelIcon className={css.icon} />
            <div className={css.text}>
              <p className={css.title}>Договорная стоимость работ</p>
              <p className={css.subtitle}>Оцениваем объем работ и предлагаем доступные цены</p>
            </div>
          </div>
        </div>
        <button className={css.action} onClick={openWindow}>
          <div className={css.text}>
            <p className={css.title}>Требуются отделочные работы?</p>
            <p className={css.subtitle}>Мы перезвоним вам и проконсультируем в ближайшее время</p>
          </div>
          <p className={css.pseudoButton}>Оставить заявку</p>
        </button>
      </div>

      <Loader isLoading={isLoading} />
      <div className={css.services}>
        {
          !!services && services.map((column, index) => (
            <div className={css.column} key={index}>
              {
                column.map(item => (
                  <div className={css.item} key={item.description} itemProp='offers' itemScope itemType='https://schema.org/Offer'>
                    <ArrowIcon className={css.icon} />
                    <div className={css.content}>
                      <p className={css.title} itemProp='name'>{ item.title }</p>
                      <p className={css.description} itemProp='description'>{ item.description }</p>
                      <link itemProp='availability' href='https://schema.org/InStock' />
                    </div>
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>

      <InteriorDecorationModal isView={isModalView} closeFn={closeWindow} />

      
      {/* meta tags */}
      <ServicesPageMetaTags 
        description={ data ? `Наши специалисты выполняют отделочные работы, такие как ${ data.map(s => s.title).join(', ').toLocaleLowerCase() }. \
Кроме того, мы предлагаем бесплатную доставку наших товаров по всей России` : ''}
      />
    </div>
  );
};
