import { PayloadAction } from "@reduxjs/toolkit";
import { MAX_SAVED_SEARCH_QUERIES, TUserData, TUserDataChangePayload, TUserState } from "./entities";
import { transformTelForMask } from "resources/helpers";

export const UserSliceActions = {

  editField (state: TUserState, { payload }: PayloadAction<TUserDataChangePayload>) {
    const value = payload.field === 'phone' 
      ? transformTelForMask(payload.value) 
      : payload.value;

    state.data[payload.field] = value;
  },

  setData (state: TUserState, { payload }: PayloadAction<TUserData>) {
    state.data = {
      ...payload,
      phone: payload.phone ? transformTelForMask(payload.phone) : '',
    };
  },

  saveSearchQuery (state: TUserState, { payload: query }: PayloadAction<string>) {
    if (!state.searchQueries.find(q => q === query) && query) {
      if (state.searchQueries.length + 1 > MAX_SAVED_SEARCH_QUERIES) {
        state.searchQueries = state.searchQueries.slice(0, MAX_SAVED_SEARCH_QUERIES);
      }

      state.searchQueries = [ query, ...state.searchQueries ];
    }
  },

  removeSearchQuery (state: TUserState, { payload: query }: PayloadAction<string>) {
    state.searchQueries = state.searchQueries.filter(q => q != query);
  } 
}