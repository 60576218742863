import React from 'react';

type TProps = {
  className?: string
}

export const CommentIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.3327 2.66666H10.666C6.25402 2.66666 2.66602 6.25466 2.66602 10.6667V28C2.66602 28.3536 2.80649 28.6927 3.05654 28.9428C3.30659 29.1928 3.64573 29.3333 3.99935 29.3333H21.3327C25.7447 29.3333 29.3327 25.7453 29.3327 21.3333V10.6667C29.3327 6.25466 25.7447 2.66666 21.3327 2.66666ZM26.666 21.3333C26.666 24.2747 24.274 26.6667 21.3327 26.6667H5.33268V10.6667C5.33268 7.72532 7.72468 5.33332 10.666 5.33332H21.3327C24.274 5.33332 26.666 7.72532 26.666 10.6667V21.3333Z" fill="#333333"/>
    <path d="M9.33398 12H22.6673V14.6667H9.33398V12ZM9.33398 17.3333H18.6673V20H9.33398V17.3333Z" fill="#333333"/>
  </svg>
);