import React from 'react';
import { Link } from 'react-router-dom';
import { TCatalogSection } from 'store/globalEntities';
import { getOrderNumber } from 'resources/helpers';
import { ArrowIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {
  orderNumber: number,
  section: TCatalogSection,
};

export const CatalogSectionCard = ({ orderNumber, section }: TProps) => {
  const order = getOrderNumber(orderNumber);

  return (
    <Link className={css.wrap} to={`/catalog/${section.key}`}>
      <div className={css.topRow}>
        <p className={css.orderNumber}>{ order }</p>
        <ArrowIcon className={css.arrowIcon} />
      </div>
      <div className={css.content}>
        <h4 className={css.title}>{ section.name }</h4>
        <p className={css.description}>{ section.description }</p>
      </div>
    </Link>
  );
};
