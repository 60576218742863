import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormElements, Loader } from 'components';
import { ChevronIcon, InfoIcon } from 'resources/icons';
import { ModalWindow, TModalWindowProps } from 'modals';
import { T_Body_InteriorDecoration } from 'store/slices/requestsAPI/entities';
import { useAlertContext } from 'app/context';
import { RequestsAPI } from 'store/slices';
import { AppPagesInfo } from 'app/AppRouter';
import css from './index.module.scss';

type TProps = Omit<TModalWindowProps, 'title'>;

type TFormData = T_Body_InteriorDecoration;

export const InteriorDecorationModal = ({ isView, closeFn }: TProps) => {
  const { alert } = useAlertContext();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<TFormData>({
    mode: 'onBlur',
  });

  const [ request, { data: response, isLoading, isError, isSuccess } ] = RequestsAPI.useInteriorDecorationRequestMutation();

  const onClose = () => {
    reset();
    closeFn();
  }

  const onSubmit = (data: TFormData) => {
    request(data);
  }

  useEffect(() => {
    if (isError || response && !response.answer) {
      reset();
      onClose();
      alert({ text: 'При обработке заявки произошла ошибка, попробуйте позже или перезвоните нам' });
    }

    if (isSuccess && response && response.answer) {
      reset();
      onClose();
      alert({ text: 'Ваша заявка на отделочные работы принята, мы перезвоним вам в ближайшее время' });
    }

  }, [response, isError, isSuccess]);

  return (
    <ModalWindow 
      title='Оставить заявку на отделочные работы'
      isView={isView}
      closeFn={onClose}
    >
      <div className={css.description}>
        <InfoIcon className={css.icon} />
        <p className={css.text}>
          Мы производим отделочные работы только в Нижнем Новгороде и области. Ознакомиться 
          со списком выполняемых нами работ можно на странице 
          <Link to={AppPagesInfo.services.path} className={css.link}>Услуги</Link>
        </p>
      </div>
      <Loader isLoading={isLoading} className={css.loader} />
      {
        !isLoading && (
        <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
          <FormElements.Input 
            label='Как к вам обращаться?'
            type='text'
            placeholder='Александр Александрович'
            errorMessage={errors.name?.message}
            required
            register={register('name', {
              required: 'Это поле обязательно для заполнения',
              minLength: { value: 2, message: 'Минимальная длина 2 символа' },
              maxLength: { value: 50, message: 'Максимальная длина 30 символов' }
            })}
          />
          <FormElements.Input 
            label='Ваш номер телефона'
            type='tel'
            placeholder='8 (910) 888 1010'
            errorMessage={errors.phone?.message}
            required
            register={register('phone', {
              required: 'Это поле обязательно для заполнения',
              pattern: {
                value: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
                message: 'Некорректный формат телефонного номера'
              }
            })}
          />
          <FormElements.Input 
            label='Населенный пункт'
            type='text'
            placeholder='Нижегородская область, г. Дзержинск'
            errorMessage={errors.city?.message}
            required
            register={register('city', {
              required: 'Это поле обязательно для заполнения',
              min: { value: 3, message: 'Минимальная длина 3 символа' },
              max: { value: 100, message: 'Максимальная длина 100 символов' },
            })}
          />
          <FormElements.Input 
            label='Когда необходимо произвести работы?'
            type='text'
            description='Требуется произвести работы в ближайшее время или, к примеру, через пару месяцев'
            placeholder='В ближайшее время'
            errorMessage={errors.whenNeed?.message}
            required
            register={register('whenNeed', {
              required: 'Это поле обязательно для заполнения',
              min: { value: 3, message: 'Минимальная длина 3 символа' },
              max: { value: 100, message: 'Максимальная длина 100 символов' },
            })}
          />
          <FormElements.Textarea
            label='Опишите ваш объект'
            description='Укажите размеры помещения (или помещений): назначение помещения, 
                        длины стен, высоту потолков, размеры дверных и оконных проемов'
            placeholder='Парная: 2,6 х 1,9 м, высота потолков 2,1 м, одна форточка 0,4 х 0,5 м, дверь 2,0 х 0,7 м'
            errorMessage={errors.room?.message}
            register={register('room', {
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' }
            })}
          />
          <FormElements.Textarea
            label='Желаемые материалы'
            description='Укажите какие материалы вы хотели бы использовать для отделки, можете 
                        указать несколько или пропустить этот пункт, если сомневаетесь в выборе'
            placeholder='Вагонка липовая или осиновая сорт А для стен, насчет пола не уверен'
            errorMessage={errors.materials?.message}
            register={register('materials', {
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' }
            })}
          />
          <FormElements.Textarea
            label='Необходимые работы'
            description='Укажите какие работы необходимо произвести, можете написать кратко и описать
                        их подробнее в разговоре с нашим менеджером по телефону'
            errorMessage={errors.jobs?.message}
            register={register('jobs', {
              maxLength: { value: 1000, message: 'Максимальная длина 1000 символов' }
            })}
          />
          <button type="submit" className={css.submitButton}>
            <span>Отправить заявку</span>
            <ChevronIcon className={css.icon} />
          </button>
          </form>
        )}
    </ModalWindow>
  );
};
