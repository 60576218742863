import React from 'react';
import classNames from 'classnames';
import { ModalWindow, TModalWindowProps } from 'modals/ModalWindow';
import { T_Response_MakeOrder } from 'store/slices/requestsAPI/entities';
import { ArrowIcon, FilePdfIcon } from 'resources/icons';
import { downloadBase64File } from 'resources/helpers';
import { Button } from 'components';
import css from './index.module.scss';

type TProps = Omit<TModalWindowProps, 'title'> & {
  response?: T_Response_MakeOrder,
};

export const MakeOrderSuccessModal = ({ isView, closeFn, response }: TProps) => {

  const downloadInvoice = () => {
    if (response)
      downloadBase64File(response.pdfInvoiceBase64, `Заказ №${response.orderNumber}.pdf`);
  }

  if (!response) return <></>;

  return (
    <ModalWindow
      title='Заказ принят в обработку'
      isView={isView}
      closeFn={closeFn}
    >
      <div className={css.wrap}>
        <div className={classNames(css.block, css.descBlock)}>
          <div className={css.label}>
            <ArrowIcon className={css.icon} />
            <h3>Что дальше?</h3>
          </div>
          <div className={css.content}>
            <div className={css.left}>
              <p className={css.descText}>
                Мы получили ваш заказ и начинаем работу над ним. 
                Наш менеджер свяжется с вами в ближайшее время по указанному вами номеру 
                <span>{ ` ${response.customerPhone}` }</span>
              </p>
              <p className={css.descText}>
                Если телефонный номер содержит ошибку, свяжитесь с нами, 
                сообщите номер заказа и актуальный телефон для связи с вами.
              </p>
            </div>
            <div className={css.right}>
              <div className={css.orderNumber}>
                <span className={css.deco}>№</span>
                <p className={css.text}>Номер вашего заказа</p>
                <p className={css.number}>{ response.orderNumber }</p>
              </div>
              <p className={css.aboutContract}>
                Товары с договорной стоимостью требуют уточнения деталей реализации. Они не указаны в накладной, наш менеджер обсудит их с вами по телефону.
              </p>
            </div>
          </div>
        </div>

        <div className={classNames(css.block, css.docsBlock)}>
          <div className={css.label}>
            <ArrowIcon className={css.icon} />
            <h3>Документы</h3>
          </div>
          <div className={css.content}>
            <div className={css.left}>
              <p className={css.docAbout}>
                Файлы ваших заказов доступны в разделе <span>Учетная запись</span> на нашем сайте
              </p>
              {
                response.customerEmail
                ? (
                  <>
                    <p className={css.docAbout}>
                      Также, файл был отправлен на указанную вами почту <span>{ response.customerEmail }</span>
                    </p>
                    <p className={css.docAbout}>
                      Если вы не получили письмо, проверьте не попало ли оно в папку <span>Спам</span>
                    </p>
                  </>
                )
                : (
                  <p className={css.docAbout}>
                    Вы не указали электронную почту при оформлении заказа. Чтобы получить копию накладной письмом,
                    сообщите об этом менеджеру, когда он свяжется с вами
                  </p>
                )
              }
            </div>
            <div className={css.right}>
              <button 
                className={css.downloadButton}
                onClick={downloadInvoice}
              >
                <FilePdfIcon className={css.icon} />
                <div className={css.text}>
                  <p className={css.title}>Накладная</p>
                  <p className={css.description}>Нажмите, чтобы загрузить файл в формате PDF</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Button
        text='Закрыть'
        size='tall'
        style='black'
        onClick={closeFn}
        className={css.closeButton}
      />

    </ModalWindow>
  );
};
