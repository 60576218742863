import React from 'react';
import { useSelector } from 'react-redux';
import { TMoldedSizeGroup, TProduct, TProductGroupSmall } from 'store/globalEntities';
import { TProduct_Tiny } from 'store/slices/productsAPI/entities';
import { InfoIcon } from 'resources/icons';
import { MoldedSizeUnitCard } from 'components';
import { useTooltip } from 'resources/helpers/hooks';
import { CartLocal } from 'store/slices';
import css from './index.module.scss';

type TProps = {
  sizeGroup: TMoldedSizeGroup,
  productGroup: TProductGroupSmall,
  product: TProduct | TProduct_Tiny,
};

export const MoldedSizeUnitsGroup = ({ sizeGroup, productGroup, product }: TProps) => {
  const isUseSale = useSelector(CartLocal.isGroupHasSale(productGroup.id));
  const beforeSale = useSelector(CartLocal.howMuchBeforeSale(productGroup.id, productGroup.saleLimit));

  const tooltipProps = useTooltip<HTMLDivElement>({
    content: (
      isUseSale
      ? <p>Вы приобретаете товары группы <span>{ productGroup.name }</span> со скидкой, удачных покупок!</p>
      : <p>Вы приобретаете товар по обычной цене. Добавьте в корзину ещё <span>{ `${beforeSale} пог.м. ` }</span>
        товаров группы <span>{ productGroup.name }</span> и получите на них скидку. Перерасчет произойдет автоматически</p>
    ),
    vertical: 'top',
    horizontal: 'right',
  });

  return (
    <div className={css.wrap}>
      <header className={css.header}>
        <div className={css.lengthInfo}>
          <p className={css.label}>Длина изделия</p>
          <p className={css.length}>
            { `${sizeGroup.startLength.toFixed(1)} - ${sizeGroup.finishLength.toFixed(1)} ` }
            <span>м</span>
          </p>
        </div>
        <div className={css.priceInfo}>
          <p className={css.label}>Со скидкой / Обычная цена</p>
          <div className={css.priceWrap}>
            <p className={css.price}>
              <span className={isUseSale ? css.active : ''}>{ sizeGroup.sale }</span>
              { ' / ' }
              <span className={!isUseSale ? css.active : ''}>{ sizeGroup.price }</span>
            </p>
            <p className={css.priceMeasureUnit}>
              <span>рублей</span>
              <span>пог.м.</span>
            </p>
            <div { ...tooltipProps }>
              <InfoIcon className={css.priceInfoIcon} />
            </div>
          </div>
        </div>
      </header>

      <div className={css.sizeUnits}>
        {
          sizeGroup.size_units.map(unit => !!unit.moldedLength &&
            <MoldedSizeUnitCard
              key={unit.id}  
              sizeUnit={unit}
              product={product}
              productGroup={productGroup}
            />
          )
        }
      </div>
    </div>
  );
};
