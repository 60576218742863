import React from 'react';

type TProps = {
  className?: string
}

export const PeopleIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="129" height="128" viewBox="0 0 129 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M89.0538 58.9227C92.302 53.3871 93.7048 46.9616 93.0591 40.576C92.1044 31.0613 86.7924 22.6507 78.1098 16.896L72.2164 25.7813C78.1844 29.7387 81.8164 35.376 82.4458 41.6427C82.736 44.5552 82.3734 47.4958 81.3844 50.2506C80.3954 53.0054 78.8048 55.5051 76.7284 57.568L70.3711 63.9253L79.0004 66.4587C101.571 73.072 101.832 95.7707 101.832 96H112.499C112.499 86.4587 107.4 67.8133 89.0538 58.9227Z" fill="#D7D7D7"/>
    <path d="M51.166 63.9999C62.9314 63.9999 72.4994 54.4319 72.4994 42.6666C72.4994 30.9013 62.9314 21.3333 51.166 21.3333C39.4007 21.3333 29.8327 30.9013 29.8327 42.6666C29.8327 54.4319 39.4007 63.9999 51.166 63.9999ZM51.166 31.9999C57.0487 31.9999 61.8327 36.7839 61.8327 42.6666C61.8327 48.5492 57.0487 53.3333 51.166 53.3333C45.2833 53.3333 40.4993 48.5492 40.4993 42.6666C40.4993 36.7839 45.2833 31.9999 51.166 31.9999ZM59.166 69.3332H43.166C25.518 69.3332 11.166 83.6852 11.166 101.333V106.667H21.8327V101.333C21.8327 89.5679 31.4007 79.9999 43.166 79.9999H59.166C70.9314 79.9999 80.4994 89.5679 80.4994 101.333V106.667H91.166V101.333C91.166 83.6852 76.814 69.3332 59.166 69.3332Z" fill="#D7D7D7"/>
  </svg>
);