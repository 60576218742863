import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CartLocal } from 'store/slices';
import { TProductGroup_Tiny } from 'store/slices/productGroupAPI/entities';
import { useBreakPoint, useTooltip } from 'resources/helpers/hooks';
import { InfoIcon } from 'resources/icons';
import { getMeasureUnit } from 'resources/helpers';
import { TProductGroupSmall } from 'store/globalEntities';
import css from './index.module.scss';

type TProps = {
  productGroup: TProductGroup_Tiny | TProductGroupSmall,
};

export const WholesaleProgressBar = ({ productGroup }: TProps) => {
  const [barPercent, setBarPercent] = useState(0);
  const isMobile = useBreakPoint(800);

  const cartGroup = useSelector(CartLocal.getGroup(productGroup.id)) || { commonVolume: 0 };
  const isUseSale = useSelector(CartLocal.isGroupHasSale(productGroup.id));
  const measureUnit = getMeasureUnit(productGroup.type);

  const descriptionText = (
    isUseSale 
    ? (
      <p className={css.text}>Вы добавили в корзину <span>{ `${productGroup.saleLimit.toLocaleString('ru')} ${measureUnit} ` }</span>
      или более товаров категории <span>{ productGroup.name }</span> и к ним применяется скидка</p>
    )
    : (
      <p className={css.text}>Добавьте в корзину <span>{ `${productGroup.saleLimit.toLocaleString('ru')} ${measureUnit} ` }</span>
      товаров категории <span>{ productGroup.name }</span> и 
      закажите их со скидкой, перерасчет произойдет автоматически</p>
    )
  );

  const saleDescriptionTooltipProps = useTooltip<HTMLDivElement>({
    content: descriptionText,
    vertical: 'bottom',
    horizontal: 'right',
  });

  useEffect(() => {
    const commonPercent = Math.round(100 * cartGroup.commonVolume / productGroup.saleLimit);
    setBarPercent(commonPercent > 100 ? 100 : commonPercent);
  }, [cartGroup.commonVolume, productGroup.saleLimit]);

  return (
    <div className={css.wrap}  { ...(isMobile ? saleDescriptionTooltipProps : {} ) }>
      <div className={css.barArea}>
        <div className={css.barLine} style={{ width: `${barPercent}%` }}/>
      </div>
      <div className={css.info}>
        <div className={css.description}>
          <InfoIcon className={css.icon} />
          { !isMobile && descriptionText }
        </div>
        <p className={css.barStatus}>
          { isMobile && !isUseSale && <span className={css.descForSmallScreen}>До скидки</span> }
          { 
            isUseSale
            ? 'Применена скидка'
            : `Осталось ${(Math.round(productGroup.saleLimit - cartGroup.commonVolume)).toLocaleString('ru')} ${measureUnit}` 
          }
        </p>
      </div>
    </div>
  );
};
