import React from 'react';
import { Link } from 'react-router-dom';
import { Title, Image } from 'components';
import { ArrowIcon } from 'resources/icons';
import { getNounProduct } from 'resources/helpers';
import { TProductGroup_Tiny } from 'store/slices/productGroupAPI/entities';
import css from './index.module.scss';

type TProps = {
  group: TProductGroup_Tiny,
  sectionKey: string,
};

export const ProductGroupCard = ({ group, sectionKey }: TProps) => {

  return (
    <Link 
      to={`/catalog/${sectionKey}/${group.key}`}
      className={css.wrap}
    >
      <div className={css.imgWrap}>
        <Image 
          className={css.img} 
          image={group.image} 
          title={group.name} 
        />
      </div>
      <div className={css.content}>
        <div className={css.textWrap}>
          <Title type='card' level='h3'>{ group.name }</Title>
          <p className={css.subtitle}>
            { `( ${group.amountProducts} ${getNounProduct(group.amountProducts)} )` }
          </p>
        </div>
        <ArrowIcon className={css.arrowIcon} />
      </div>
    </Link>
  );
};
