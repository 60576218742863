import { 
  T_RawResponse_GetProductsList, T_DoneResponse_GetProductsList,
  T_RawResponse_GetProductWithId, T_DoneResponse_GetProductWithId,
  T_RawResponse_SearchProducts, T_DoneResponse_SearchProducts,
} from "./entities";

export const transformGetProductsList = (
  response: T_RawResponse_GetProductsList
): T_DoneResponse_GetProductsList => {

  const transformedResponse: T_DoneResponse_GetProductsList = {
    data: response.data.map(product => ({
      id: product.id,
      ...product.attributes,
      images: product.attributes.images.data.map(image => ({
        url: image.attributes.url,
        thumbnail: image.attributes.formats.thumbnail.url
      }))
    })),
    pagination: response.meta.pagination
  }

  return transformedResponse;
}


export const transformGetProductWithId = (
  response: T_RawResponse_GetProductWithId
): T_DoneResponse_GetProductWithId => {

  if (response.data === null) {
    return { data: null, breadcrumbs: [] };
  }

  const transformedResponse: T_DoneResponse_GetProductWithId = {
    data: {
      ...response.data,
      images: response.data.images.map(image => ({
        url: image.url,
        thumbnail: image.formats.thumbnail.url,
      }))
    },
    breadcrumbs: response.breadcrumbs
  }

  return transformedResponse;
}


export const transformSearchProducts = (
  response: T_RawResponse_SearchProducts
): T_DoneResponse_SearchProducts => {

  const transformedResponse: T_DoneResponse_SearchProducts = {
    queryResults: [],
    keywordsResults: [],
  }

  transformedResponse.queryResults = response.data.queryResults.map(product => ({
    ...product,
    catalog_section: product.product_group.catalog_section,
  }));

  transformedResponse.keywordsResults = response.data.keywordsResults.map(product => ({
    ...product,
    catalog_section: product.product_group.catalog_section,
  }));

  return transformedResponse;
}