import React from 'react';

type TProps = {
  className?: string
}

export const BurgerMenuIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.66669 5.5H18.3334V7.33333H3.66669V5.5ZM3.66669 10.0833H18.3334V11.9167H3.66669V10.0833ZM3.66669 14.6667H18.3334V16.5H3.66669V14.6667Z" fill="#333333"/>
  </svg>
);