import { EProductGroupTypes, TProductGroupTypes } from "store/globalEntities";


// GET NOUN

const __getNoun = (number: number, oneUnit: string, twoUnits: string, fiveUnits: string ) => {
  let n = Math.abs(number);
  
  n %= 100;
  if (n >= 5 && n <= 20)
    return fiveUnits;

  n %= 10;
  if (n === 1)
    return oneUnit;

  if (n >= 2 && n <= 4)
    return twoUnits;

  return fiveUnits;
}

export const getNounGroups = (number: number) => __getNoun(number, 'группа', 'группы', 'групп');
export const getNounProduct = (number: number) => __getNoun(number, 'товар', 'товара', 'товаров');
export const getNounRuble = (number: number) => __getNoun(number, 'рубль', 'рубля', 'рублей');
export const getNounRubleFrom = (number: number) => __getNoun(number, 'рубля', 'рублей', 'рублей');


// GET ORDER NUMBER

export const getOrderNumber = (number: number) => `${number > 9 ? '' : '0'}${number}`;


// GET MEASURE UNIT FOR PRODUCT TYPE

const MeasureUnitsDictionary: { [key in keyof typeof EProductGroupTypes]: string } = {
  'molded': 'пог.м',
  'piece': 'шт',
  'weight': 'кг',
}

export const getMeasureUnit = (productType: TProductGroupTypes) => MeasureUnitsDictionary[productType];


// TRANSFORM TEL FOR MASK

export const transformTelForMask = (tel: string) => {
  let number = '';

  for (let i = tel.length - 1; i >= 0; i--) {
    if (tel[i] !== ' ' && !isNaN(+tel[i])) {
      number = tel[i] + number;
    }
  }

  number = number.slice(number.length - 10);
  return `+7 (${number.slice(0, 3)}) ${number.slice(3, 6)} ${number.slice(6, 8)} ${number.slice(8)}`;
}