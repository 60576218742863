import React, { useEffect } from 'react';
import { FormElements, Loader } from 'components';
import { useForm } from 'react-hook-form';
import { ChevronIcon } from 'resources/icons';
import { ModalWindow, TModalWindowProps } from 'modals';
import { T_Body_CallRequest } from 'store/slices/requestsAPI/entities';
import { useAlertContext } from 'app/context';
import { RequestsAPI } from 'store/slices';
import css from './index.module.scss';

type TProps = Omit<TModalWindowProps, 'title'>;

type TFormData = T_Body_CallRequest;

export const CallRequestModal = ({ isView, closeFn }: TProps) => {
  const { alert } = useAlertContext();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<TFormData>({
    mode: 'onBlur',
  });

  const [ request, { data: response, isLoading, isError, isSuccess } ] = RequestsAPI.useCallRequestMutation();

  const onClose = () => {
    reset();
    closeFn();
  }

  const onSubmit = (data: TFormData) => {
    request(data);
  }

  useEffect(() => {
    if (isError || response && !response.answer) {
      reset();
      onClose();
      alert({ text: 'При обработке заявки произошла ошибка, попробуйте позже или перезвоните нам' });
    }

    if (isSuccess && response && response.answer) {
      reset();
      onClose();
      alert({ text: 'Ваша заявка на звонок принята, мы перезвоним вам в ближайшее время' });
    }

  }, [response, isError, isSuccess]);

  return (
    <ModalWindow 
      title='Оставить заявку на звонок'
      isView={isView}
      closeFn={onClose}
    >
      <Loader isLoading={isLoading} className={css.loader} />
      { !isLoading && (
        <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
          <FormElements.Input 
            label='Как к вам обращаться?'
            type='text'
            placeholder='Александр Александрович'
            errorMessage={errors.name?.message}
            required
            register={register('name', {
              required: 'Это поле обязательно для заполнения',
              minLength: { value: 2, message: 'Минимальная длина 2 символа' },
              maxLength: { value: 50, message: 'Максимальная длина 30 символов' }
            })}
          />
          <FormElements.Input 
            label='Ваш номер телефона'
            type='tel'
            placeholder='8 (910) 888 1010'
            errorMessage={errors.phone?.message}
            required
            register={register('phone', {
              required: 'Это поле обязательно для заполнения',
              pattern: {
                value: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
                message: 'Некорректный формат телефонного номера'
              }
            })}
          />
          <FormElements.Textarea
            label='Опишите ваш вопрос'
            description='Это поможет нам скорее решить вашу проблему'
            placeholder='У меня вопрос по товару...'
            errorMessage={errors.message?.message}
            register={register('message', {
              maxLength: { value: 250, message: 'Максимальная длина 250 символов' }
            })}
          />
          <button type="submit" className={css.submitButton}>
            <span>Заказать звонок</span>
            <ChevronIcon className={css.icon} />
          </button>
        </form>
      )}
    </ModalWindow>
  );
};
