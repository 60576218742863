import React from 'react';
import classNames from 'classnames';
import { ChevronIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {
  text: string,
  onClick?: () => void,
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'],
  icon?: React.ReactNode | 'none',
  style?: 'base' | 'white' | 'accent' | 'highlight' | 'black' | 'light-accent',
  size?: 'base' | 'tall',
  maxWidth?: number | string,
  className?: string,
};

export const Button = ({ 
  text, onClick, icon, style = 'base', size = 'base', maxWidth, className = '', type = 'button' 
}: TProps) => {

  return (
    <button
      className={classNames(css.button, css[`color__${style}`], css[`size__${size}`], className)}
      style={{ maxWidth: maxWidth }}
      type={type}
      onClick={onClick}
    >
      <p className={css.text}>{ text }</p>
      { icon === 'none' ? undefined : icon || <ChevronIcon /> }
    </button>
  );
};
