import React from 'react';

type TProps = {
  className?: string
}

export const FilePdfIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.0452 39.1465C21.5546 39.1465 21.2239 39.1945 21.0532 39.2425V42.3838C21.2559 42.4318 21.5092 42.4451 21.8586 42.4451C23.1359 42.4451 23.9226 41.7998 23.9226 40.7091C23.9226 39.7331 23.2452 39.1465 22.0452 39.1465ZM31.3439 39.1785C30.8106 39.1785 30.4639 39.2265 30.2586 39.2745V46.2345C30.4639 46.2825 30.7946 46.2825 31.0932 46.2825C33.2719 46.2985 34.6906 45.0985 34.6906 42.5598C34.7066 40.3465 33.4132 39.1785 31.3439 39.1785Z" fill="#9F4545"/>
    <path d="M37.3332 5.3335H15.9998C14.5853 5.3335 13.2288 5.8954 12.2286 6.89559C11.2284 7.89579 10.6665 9.25234 10.6665 10.6668V53.3335C10.6665 54.748 11.2284 56.1045 12.2286 57.1047C13.2288 58.1049 14.5853 58.6668 15.9998 58.6668H47.9998C49.4143 58.6668 50.7709 58.1049 51.7711 57.1047C52.7713 56.1045 53.3332 54.748 53.3332 53.3335V21.3335L37.3332 5.3335ZM25.3278 43.1735C24.5038 43.9468 23.2878 44.2935 21.8718 44.2935C21.5973 44.2965 21.3228 44.2804 21.0505 44.2455V48.0482H18.6665V37.5522C19.7424 37.3917 20.8295 37.3185 21.9172 37.3335C23.4025 37.3335 24.4585 37.6162 25.1705 38.1842C25.8478 38.7228 26.3065 39.6055 26.3065 40.6455C26.3038 41.6908 25.9572 42.5735 25.3278 43.1735ZM35.4798 46.7868C34.3598 47.7175 32.6558 48.1602 30.5732 48.1602C29.3252 48.1602 28.4425 48.0802 27.8425 48.0002V37.5548C28.9188 37.3977 30.0055 37.3237 31.0932 37.3335C33.1118 37.3335 34.4238 37.6962 35.4478 38.4695C36.5545 39.2908 37.2478 40.6002 37.2478 42.4802C37.2478 44.5148 36.5038 45.9202 35.4798 46.7868ZM45.3332 39.3868H41.2478V41.8162H45.0665V43.7735H41.2478V48.0508H38.8318V37.4135H45.3332V39.3868ZM37.3332 24.0002H34.6665V10.6668L47.9998 24.0002H37.3332Z" fill="#9F4545"/>
  </svg>
);