import React from 'react';

type TProps = {
  className?: string
}

export const InfoIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2462 2.66675C8.78112 2.66675 2.70776 8.64808 2.70776 16.0001C2.70776 23.3521 8.78112 29.3334 16.2462 29.3334C23.7113 29.3334 29.7847 23.3521 29.7847 16.0001C29.7847 8.64808 23.7113 2.66675 16.2462 2.66675ZM16.2462 26.6667C10.2744 26.6667 5.41546 21.8814 5.41546 16.0001C5.41546 10.1187 10.2744 5.33341 16.2462 5.33341C22.218 5.33341 27.077 10.1187 27.077 16.0001C27.077 21.8814 22.218 26.6667 16.2462 26.6667Z" fill="#D7D7D7"/>
    <path d="M14.8923 14.6666H17.6V22.6666H14.8923V14.6666ZM14.8923 9.33325H17.6V11.9999H14.8923V9.33325Z" fill="#D7D7D7"/>
  </svg>
);