import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CartAPI, CartLocal } from 'store/slices';
import { TGroupDiscountInfo, calcCartGroupDiscounts } from 'resources/helpers/cartHelpers';
import { CartGroupSection, Title, CartController, MakeOrderController } from 'components';
import { EmptyCartTile } from 'tiles';
import css from './index.module.scss';
import { CartPageMetaTags, Header } from 'features';

type TProps = {};

export const CartPage = ({  }: TProps) => {
  const [makeOrderStatus, setMakeOrderStatus] = useState(false);
  const [discountsList, setDiscountsList] = useState<TGroupDiscountInfo[]>([]);

  const startMakeOrder = () => setMakeOrderStatus(true);
  const finishMakeOrder = () => setMakeOrderStatus(false);
  
  const isCartEmpty = useSelector(CartLocal.isCartEmpty);
  const cartIds = useSelector(CartLocal.getIdsForRequest);
  const localCart = useSelector(CartLocal.getCartState);
  const [ cartCheck, { data: serverCart } ] = CartAPI.useCartCheckMutation();

  useEffect(() => {
    if (!isCartEmpty) 
      cartCheck(cartIds);
  }, [isCartEmpty]);

  useEffect(() => {
    if (localCart && serverCart) {
      setDiscountsList(calcCartGroupDiscounts(localCart, serverCart));
    }
  }, [localCart, serverCart]);


  return (
    <div className={css.page}>
      <div className={css.headerWrap}><Header /></div>
      {
        isCartEmpty
        ? (
          <div className={css.emptyWrap}>
            <EmptyCartTile />
          </div>
        )
        : (
          <div className={css.wrap}>
            <Title level='h1' type='base_page'>Ваша корзина</Title>
            <div className={css.content}>
              <div className={css.groups}>
                {
                  !!serverCart && discountsList.map(group => (
                    <CartGroupSection 
                      key={group.id}
                      group={group}
                      units={serverCart.units.filter(unit => unit.groupId === group.id)}
                    />
                  ))
                }
              </div>
              <div className={css.controller}>
                <CartController discountsList={discountsList} startMakeOrder={startMakeOrder} />
              </div>
            </div>
          </div>
        )
      }

      <MakeOrderController inWork={makeOrderStatus} finishWork={finishMakeOrder} />

      {/* meta tags */}
      <CartPageMetaTags />
    </div>
  );
};
