import React from 'react';
import {Helmet} from "react-helmet";

export type TMetaTagsParams = {
  title?: string,
  description?: string,
}

export const MainPageMetaTags = () => (
  <Helmet>
    <title>Ecotrees - Изделия из древесины</title>
    <meta 
      name='description'
      content={`Широкий ассортимент отделочных материалов, мебели и другие изделий для бань и саун, \
дома и дачи из натуральной древесины с доставкой по всей России`}
    />
  </Helmet>
);

export const CatalogPageMetaTags = ({ description }: TMetaTagsParams) => (
  <Helmet>
    <title>Ecotrees - Каталог товаров</title>
    <meta 
      name='description' 
      content={`${ description ? `Мы предлагаем ${description.toLocaleLowerCase()}. ` : '' }\
В нашем каталоге представлены товары высокого качества, а наши специалисты всегда готовы \
помочь вам с выбором или с подсчетом необходимого количества наших изделий`} 
    />
  </Helmet>
);

export const CatalogSectionPageMetaTags = ({ title, description }: TMetaTagsParams) => (
  <Helmet>
    <title>{`${title}${ title ? ' - ' : ' ' }Ecotrees`}</title>
    { !!description && <meta name='description' content={`В нашем каталоге вы найдете ${description}`} /> }
  </Helmet>
);

export const ProductGroupPageMetaTags = ({ title, description }: TMetaTagsParams) => (
  <Helmet>
    <title>{`${title}${ title ? ' - ' : ' ' }Ecotrees`}</title>
    { !!description && <meta name='description' content={description} /> }
  </Helmet>
);

export const ProductPageMetaTags = ({ title, description }: TMetaTagsParams) => (
  <Helmet>
    <title>{`${title}${ title ? ' - ' : ' ' }Ecotrees`}</title>
    { !!description && <meta name='description' content={description} /> }
  </Helmet>
);

export const ContactsPageMetaTags = ({ description }: TMetaTagsParams) => (
  <Helmet>
    <title>Ecotrees - Контакты магазина</title>
    { !!description && <meta name='description' content={description} /> }
  </Helmet>
);

export const ServicesPageMetaTags = ({ description }: TMetaTagsParams) => (
  <Helmet>
    <title>Ecotrees - Наши услуги</title>
    { !!description && <meta name='description' content={description} /> }
  </Helmet>
);

export const GalleryPageMetaTags = () => (
  <Helmet>
    <title>Ecotrees - Наши работы</title>
    <meta name='description' content='Вы можете ознакомится с примерами наших работ и применением наших изделий в интерьере, а также отделке
     помещений бань, саун, частных домов и дач' />
  </Helmet>
);

export const GalleryImagePageMetaTags = ({ description }: TMetaTagsParams) => (
  <Helmet>
    <title>Ecotrees - Наши работы</title>
    { description 
      ? <meta name='description' content={'Отзыв нашего покупателя - ' + description} /> 
      : <meta name='description' content='Вы можете ознакомится с примерами наших работ и применением наших изделий в интерьере, а также отделке
      помещений бань, саун, частных домов и дач' />
    }
  </Helmet>
);

export const CartPageMetaTags = () => (
  <Helmet>
    <title>Ecotrees - Корзина</title>
  </Helmet>
);

export const UserPageMetaTags = () => (
  <Helmet>
    <title>Ecotrees - Ваш аккаунт</title>
  </Helmet>
);
