import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ModalWindow, TModalWindowProps } from 'modals';
import { FormElements, Loader } from 'components';
import { ChevronIcon } from 'resources/icons';
import { TUserData } from 'store/slices/userLocal/entities';
import { UserLocal } from 'store/slices';
import css from './index.module.scss';

type TProps = Omit<TModalWindowProps, 'title'> & {
  onSubmit: (data: TUserData) => void,
};

type TFormData = TUserData;

export const MakeOrderModal = ({ isView, closeFn, onSubmit }: TProps) => {
  const user = useSelector(UserLocal.getUserData);

  const { register, reset, handleSubmit, formState: { errors } } = useForm<TFormData>({
    mode: 'onBlur', defaultValues: user,
  });

  const formSubmit = (data: TFormData) => {
    onSubmit(data);
    reset();
  }

  const modalClose = () => {
    closeFn();
    reset();
  }

  return (
    <ModalWindow 
      title='Оформление заказа'
      closeFn={modalClose}
      isView={isView}
    >
      <form 
        onSubmit={handleSubmit(formSubmit)}
        className={css.form}
      >

        <FormElements.Input 
          type='text'
          label='Как к вам обращаться?'
          placeholder='Александр Александрович'
          errorMessage={errors.name?.message}
          required
          register={register('name', {
            required: 'Это поле обязательно для заполнения',
            minLength: { value: 2, message: 'Минимальная длина 2 символа' },
            maxLength: { value: 50, message: 'Максимальная длина 30 символов' }
          })}
        />

        <FormElements.Input 
          type='tel'
          label='Ваш номер телефона'
          placeholder='8 (910) 888 1010'
          errorMessage={errors.phone?.message}
          required
          register={register('phone', {
            required: 'Это поле обязательно для заполнения',
            pattern: {
              value: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
              message: 'Некорректный формат телефонного номера'
            }
          })}
        />

        <FormElements.Input
          type='text'
          label='Куда доставить заказ?'
          required
          placeholder='Нижний Новгород, улица Минина, д.1'
          description='Вы можете уточнить место доставки с менеджером по телефону'
          errorMessage={errors.place?.message}
          register={register('place', {
            required: true,
            minLength: { value: 5, message: 'Минимальная длина 5 символов' },
            maxLength: { value: 100, message: 'Максимальная длина 100 символов' }
          })}
        />

        <FormElements.Input 
          type='email'
          label='Электронная почта'
          description='Это необязательно, но если укажете, мы пришлем вам копию заказа'
          placeholder='alexander@mail.ru'
          errorMessage={errors.email?.message}
          register={register('email', {
            maxLength: { value: 50, message: 'Максимальная длина 30 символов' },
            pattern: {
              value: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
              message: 'Некорректный формат электронной почты'
            },
          })}
        />

        <button 
          type="submit"
          className={css.submitButton}
        >
          <span>Заказать</span>
          <ChevronIcon className={css.icon} />
        </button>

        </form>
    </ModalWindow>
  );
};
