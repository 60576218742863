import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl, prepareHeadersBase } from 'resources/api';
import { 
  T_DoneResponse_GetCatalogPageData,
  T_DoneResponse_GetCatalogSectionWithKey,
} from './entities';
import { transformGetCatalogPageData, transformGetCatalogSectionWithKey } from './transform';

export const CatalogAPI = createApi({
  reducerPath: 'CatalogAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    
    getCatalogPageData: builder.query<T_DoneResponse_GetCatalogPageData, void>({
      query: () => ({
        url: 'catalog-sections/page',
        params: {
          'pagination[pageSize]': 100,
        }
      }),
      transformResponse: transformGetCatalogPageData,
    }),

    getCatalogSectionWithKey: builder.query<T_DoneResponse_GetCatalogSectionWithKey, string>({
      query: (key: string) => ({
        url: `catalog-sections/key/${key}`,
      }),
      transformResponse: transformGetCatalogSectionWithKey,
    }),
  })
});