import React from 'react';
import { TProp } from 'store/globalEntities';
import css from './index.module.scss';
import classNames from 'classnames';

type TProps = {
  prop: TProp,
  isSmall?: boolean,
};

export const SizeUnitProp = ({ prop, isSmall }: TProps) => {

  return (
    <div className={classNames(css.wrap, { [css.small]: isSmall })}>
      <p className={css.value}>
        { prop.value }
        { 
          prop.measureUnit && 
          <span className={css.measureUnit}>
            { ` ${prop.measureUnit}` }
          </span> 
        }
      </p>
      <p className={css.name}>
        { prop.name }
      </p>
    </div>
  );
};
