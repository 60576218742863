import React from 'react';
import { ModalWindow, TModalWindowProps } from 'modals/ModalWindow';
import css from './index.module.scss';
import { BoxIcon, SadIcon, WarningIcon } from 'resources/icons';
import { Button } from 'components';

type TProps = Omit<TModalWindowProps, 'title'>;

export const MakeOrderErrorModal = ({ isView, closeFn }: TProps) => {

  return (
    <ModalWindow
      title='Произошла ошибка'
      isView={isView}
      closeFn={closeFn}
    >
      <div className={css.wrap}>
        <div className={css.icons}>
          <SadIcon />
          <WarningIcon />
          <BoxIcon />
        </div>
        <div className={css.text}>
          <p>Похоже, при оформлении заказа произошла ошибка и мы уже работаем над ее исправлением.</p>
          <p>Приносим свои извинения за доставленные неудобства, попробуйте оформить заказ позже</p>
        </div>
      </div>

      <Button
        text='Закрыть'
        size='tall'
        style='black'
        onClick={closeFn}
      />

    </ModalWindow>
  );
};
