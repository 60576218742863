import React from 'react';

type TProps = {
  className?: string
}

export const UserIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.0001 2.16667C7.12733 2.16667 2.16675 7.12725 2.16675 13C2.16675 18.8727 7.12733 23.8333 13.0001 23.8333C18.8728 23.8333 23.8334 18.8727 23.8334 13C23.8334 7.12725 18.8728 2.16667 13.0001 2.16667ZM13.0001 7.58333C14.871 7.58333 16.2501 8.96133 16.2501 10.8333C16.2501 12.7053 14.871 14.0833 13.0001 14.0833C11.1302 14.0833 9.75008 12.7053 9.75008 10.8333C9.75008 8.96133 11.1302 7.58333 13.0001 7.58333ZM7.46858 18.1697C8.44033 16.7397 10.061 15.7863 11.9167 15.7863H14.0834C15.9402 15.7863 17.5598 16.7397 18.5316 18.1697C17.1471 19.6517 15.183 20.5833 13.0001 20.5833C10.8172 20.5833 8.85308 19.6517 7.46858 18.1697Z" fill="#333333"/>
  </svg>
);