import React from 'react';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { BasketIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {};

export const EmptyCartTile = ({  }: TProps) => {
  const navigate = useNavigate();
  const goCatalog = () => navigate('/catalog');

  return (
    <div className={css.wrap}>
      <div className={css.content}>
        <BasketIcon className={css.icon} />
        <div className={css.text}>
          <h3>Ваша корзина сейчас пуста</h3>
          <p>Ознакомьтесь с нашим каталогом, у нас наверняка найдется то, что нужно именно вам</p>
        </div>
      </div>
      <div className={css.buttonWrap}>
        <Button 
          text='Перейти в каталог'
          style='highlight'
          onClick={goCatalog}
        />
      </div>
    </div>
  );
};
