import React from 'react';

type TProps = {
  className?: string
}

export const DecoBoxIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_862_81)">
      <path d="M12 0H10H0V48V50V60H48H50H60V12V10V0H12ZM48 39.045L39.045 48H30.56L48 30.56V39.045ZM48 41.873V48H41.873L48 41.873ZM27.731 48H19.246L48 19.246V27.731L27.731 48ZM48 16.418L16.418 48H12V43.933L43.932 12H48V16.418ZM12 41.104V32.619L32.619 12H41.104L12 41.104ZM12 29.791V21.305L21.305 12H29.791L12 29.791ZM12 18.477V12H18.477L12 18.477ZM2 2H10V12V20.477V34.62V43.105V48H2V2ZM48 58H2V50H10H12H17.246H25.731H39.873H48V58ZM58 58H50V48V39.873V25.731V17.246V12H58V58ZM50 10H48H43.104H34.619H20.477H12V2H58V10H50Z" fill="black"/>
      <path d="M55 5C55.5523 5 56 4.55228 56 4C56 3.44772 55.5523 3 55 3C54.4477 3 54 3.44772 54 4C54 4.55228 54.4477 5 55 5Z" fill="black"/>
      <path d="M51 5C51.5523 5 52 4.55228 52 4C52 3.44772 51.5523 3 51 3C50.4477 3 50 3.44772 50 4C50 4.55228 50.4477 5 51 5Z" fill="black"/>
      <path d="M55 9C55.5523 9 56 8.55228 56 8C56 7.44772 55.5523 7 55 7C54.4477 7 54 7.44772 54 8C54 8.55228 54.4477 9 55 9Z" fill="black"/>
      <path d="M51 9C51.5523 9 52 8.55228 52 8C52 7.44772 51.5523 7 51 7C50.4477 7 50 7.44772 50 8C50 8.55228 50.4477 9 51 9Z" fill="black"/>
      <path d="M56 56C56.5523 56 57 55.5523 57 55C57 54.4477 56.5523 54 56 54C55.4477 54 55 54.4477 55 55C55 55.5523 55.4477 56 56 56Z" fill="black"/>
      <path d="M56 52C56.5523 52 57 51.5523 57 51C57 50.4477 56.5523 50 56 50C55.4477 50 55 50.4477 55 51C55 51.5523 55.4477 52 56 52Z" fill="black"/>
      <path d="M52 56C52.5523 56 53 55.5523 53 55C53 54.4477 52.5523 54 52 54C51.4477 54 51 54.4477 51 55C51 55.5523 51.4477 56 52 56Z" fill="black"/>
      <path d="M52 52C52.5523 52 53 51.5523 53 51C53 50.4477 52.5523 50 52 50C51.4477 50 51 50.4477 51 51C51 51.5523 51.4477 52 52 52Z" fill="black"/>
      <path d="M5 57C5.55228 57 6 56.5523 6 56C6 55.4477 5.55228 55 5 55C4.44772 55 4 55.4477 4 56C4 56.5523 4.44772 57 5 57Z" fill="black"/>
      <path d="M9 57C9.55228 57 10 56.5523 10 56C10 55.4477 9.55228 55 9 55C8.44772 55 8 55.4477 8 56C8 56.5523 8.44772 57 9 57Z" fill="black"/>
      <path d="M5 53C5.55228 53 6 52.5523 6 52C6 51.4477 5.55228 51 5 51C4.44772 51 4 51.4477 4 52C4 52.5523 4.44772 53 5 53Z" fill="black"/>
      <path d="M9 53C9.55228 53 10 52.5523 10 52C10 51.4477 9.55228 51 9 51C8.44772 51 8 51.4477 8 52C8 52.5523 8.44772 53 9 53Z" fill="black"/>
      <path d="M4 6C4.55228 6 5 5.55228 5 5C5 4.44772 4.55228 4 4 4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6Z" fill="black"/>
      <path d="M4 10C4.55228 10 5 9.55228 5 9C5 8.44772 4.55228 8 4 8C3.44772 8 3 8.44772 3 9C3 9.55228 3.44772 10 4 10Z" fill="black"/>
      <path d="M8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6Z" fill="black"/>
      <path d="M8 10C8.55228 10 9 9.55228 9 9C9 8.44772 8.55228 8 8 8C7.44772 8 7 8.44772 7 9C7 9.55228 7.44772 10 8 10Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_862_81">
        <rect width="60" height="60" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);