import React from 'react';
import { Title } from 'components/Title';
import css from './index.module.scss';
import classNames from 'classnames';

type TProps = {
  title: string,
  controls?: React.ReactNode,
  className?: string,
} & React.PropsWithChildren;

export const PageSection = ({ children, title, controls, className = '' }: TProps) => {

  return (
    <div className={classNames(css.wrap, className)}>
      <div className={css.header}>
        <Title type='section' level='h2' >{ title }</Title>
        <div className={css.controls}>{ controls }</div>
      </div>
      { children }
    </div>
  );
};
