import React, { useEffect, useState } from 'react';
import { T_DoneResponse_SearchProducts } from 'store/slices/productsAPI/entities';
import { Loader } from 'components/Loader';
import { ProductCard } from 'components/ProductCard';
import { SadIcon } from 'resources/icons';
import { useNavigateContext } from 'app/context';
import { TPageLabelInfo, getRelevantSearchGroups } from 'resources/helpers/searchQueryConfiguration';
import css from './index.module.scss';
import { SearchLabels } from 'components/SearchLabels';

type TProps = {
  relevantPages: TPageLabelInfo[],
  results: T_DoneResponse_SearchProducts | undefined,
  isLoading: boolean,
};

export const SearchResults = ({ relevantPages, results, isLoading }: TProps) => {
  const [relevantGroupsPages, setRelevantGroupsPages] = useState<TPageLabelInfo[]>([]);
  const { currentSearchQuery, closeSearch } = useNavigateContext();

  const hasData = !!results;
  const hasQueryResults = hasData && !!results.queryResults.length;
  const hasKeywordsResults = hasData && !!results.keywordsResults.length;
  const hasRelevantPages = !!relevantPages.length;
  const hasRelevantGroups = !!relevantGroupsPages.length;

  useEffect(() => {
    if (results)
      setRelevantGroupsPages(getRelevantSearchGroups([ ...results.keywordsResults, ...results.queryResults ]));
  }, [results]);

  return (
    <div className={css.wrap}>
      <p className={css.title}>
        { `${isLoading ? 'Поиск' : 'Найдено'} по запросу ` }
        <span>{ `"${currentSearchQuery}":` }</span>
      </p>
      <Loader isLoading={isLoading} className={css.loader} />
      {
        !hasQueryResults && (
          <div className={css.noResultsLabel}>
            <SadIcon className={css.icon} />
            <p className={css.text}>Похоже, ничего не найдено...<br />Попробуйте ввести запрос иначе</p>
          </div>
        )
      }
      <ul className={css.list}>
        {
          hasData && results.queryResults.map(product => (
            <li className={css.product} key={product.id} onClick={closeSearch}>
              <ProductCard 
                product={product}
                groupKey={product.product_group.key}
                sectionKey={product.catalog_section.key}
                className={css.productCard}
              />
            </li>
          ))
        }
      </ul>
      {
        hasKeywordsResults && (
          <>
            <p className={css.title}>Возможно вы искали:</p>
            <ul className={css.list}>
              {
                results.keywordsResults.map(product => (
                  <li className={css.product} key={product.id} onClick={closeSearch}>
                    <ProductCard 
                      product={product}
                      groupKey={product.product_group.key}
                      sectionKey={product.catalog_section.key}
                      className={css.productCard}
                    />
                  </li>
                ))
              }
            </ul>
          </>
        )
      }
      {
        (hasRelevantGroups || hasRelevantPages) && (
          <>
            <p className={css.title}>Подходящие страницы:</p>
            <SearchLabels relevantBasePages={relevantPages} relevantGroupsPages={relevantGroupsPages} />
          </>
        )
      }
    </div>
  );
};
