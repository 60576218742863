export const downloadBase64File = async (base64: string, filename: string) => {
  await fetch(base64)
    .then(res => res.blob())
    .then(blob => {
      const url = window.URL || window.webkitURL;
      const invoiceFile = url.createObjectURL(blob);
      const workLink = document.createElement('a');
      workLink.href = invoiceFile;
      workLink.target = '_blank';
      workLink.download = filename;
      workLink.click();
    });
}