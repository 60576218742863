import React from 'react';

type TProps = {
  className?: string
}

export const LabelIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62.4377 12.2294C61.9431 11.7334 61.3553 11.34 60.7082 11.0719C60.0612 10.8037 59.3675 10.666 58.667 10.6668H32.0003C31.2999 10.666 30.6062 10.8037 29.9591 11.0719C29.312 11.34 28.7243 11.7334 28.2297 12.2294L12.2297 28.2294C11.7334 28.7238 11.3399 29.3115 11.0717 29.9587C10.8035 30.6058 10.666 31.2996 10.667 32.0001V58.6668C10.667 60.0854 11.227 61.4401 12.2297 62.4374L65.563 115.771C66.0572 116.268 66.6447 116.662 67.2918 116.931C67.9389 117.2 68.6328 117.339 69.3337 117.339C70.0345 117.339 70.7284 117.2 71.3755 116.931C72.0227 116.662 72.6102 116.268 73.1043 115.771L115.771 73.1041C116.267 72.6092 116.66 72.0215 116.928 71.3745C117.197 70.7274 117.335 70.0339 117.335 69.3334C117.335 68.633 117.197 67.9394 116.928 67.2924C116.66 66.6454 116.267 66.0576 115.771 65.5628L62.4377 12.2294ZM69.3337 104.459L21.3337 56.4588V34.2081L34.2083 21.3334H56.459L104.459 69.3334L69.3337 104.459Z" fill="#D7D7D7"/>
    <path d="M44.5496 53.3334C49.4009 53.3334 53.3336 49.4006 53.3336 44.5494C53.3336 39.6981 49.4009 35.7654 44.5496 35.7654C39.6984 35.7654 35.7656 39.6981 35.7656 44.5494C35.7656 49.4006 39.6984 53.3334 44.5496 53.3334Z" fill="#D7D7D7"/>
  </svg>
);