import React from 'react';
import classNames from 'classnames';
import { TGalleryImageMadeByTypes } from 'store/globalEntities';
import css from './index.module.scss';
import { BrushIcon, CalendarIcon, PhotoIcon, UserIcon } from 'resources/icons';

type TProps = {
  date: string,
  madeBy: TGalleryImageMadeByTypes,
};

export const GalleryImageLabels = ({ date, madeBy }: TProps) => {

  return (
    <div className={css.wrap}>
      <div className={classNames(css.label, css.date)}>
        <CalendarIcon className={css.icon} />
        <p className={css.text}>{ date }</p>
      </div>
      {
        madeBy === 'customer-photo' && (
          <div className={classNames(css.label, css.madeByCustomer)}>
            <UserIcon className={css.icon} />
            <p className={css.text}>Фото покупателя</p>
          </div>
        )
      }
      {
        madeBy === 'our-work' && (
          <div className={classNames(css.label, css.madeByUs)}>
            <BrushIcon className={css.icon} />
            <p className={css.text}>Выполнено нами</p>
          </div>
        )
      }
      {
        madeBy === 'sample' && (
          <div className={classNames(css.label, css.sample)}>
            <PhotoIcon className={css.icon} />
            <p className={css.text}>Выставочный образец</p>
          </div>
        )
      }
    </div>
  );
};
