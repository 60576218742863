import React from 'react';
import { Title } from 'components';
import { ArrowIcon, DeliveryIcon, MapIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {};

export const DeliveryScreen = ({  }: TProps) => {

  return (
    <div className={css.wrap}>
      <Title type='base_page' level='h2'>Доставка</Title>

      <div className={css.advantages}>
        <div className={css.item}>
          <DeliveryIcon className={css.icon} />
          <div className={css.content}>
            <p className={css.title}>
              Бесплатно
            </p>
            <p className={css.description}>
              Доставка бесплатна для любого заказа, место доставки обсуждается с менеджером при оформлении заказа
            </p>
          </div>
        </div>
        <div className={css.item}>
          <MapIcon className={css.icon} />
          <div className={css.content}>
            <p className={css.title}>
              По России
            </p>
            <p className={css.description}>
              Мы доставляем нашу продукцию по всей России, а основной наш склад расположен в Нижнем Новгороде
            </p>
          </div>
        </div>
      </div>

      <div className={css.questions}>
        <div className={css.item}>
          <ArrowIcon className={css.icon} />
          <div className={css.content}>
            <p className={css.title}>Условия осуществления доставки</p>
            <p className={css.description}>
              Доставка осуществляется после 100% предоплаты заказа. Мы производим доставку по стране попутным транспортом с сервиса
              <a href="https://ati.su/" target='_blank' rel="noreferrer"> ati.su </a>
              или транспортными компаниями
            </p>
          </div>
        </div>
        <div className={css.item}>
          <ArrowIcon className={css.icon} />
          <div className={css.content}>
            <p className={css.title}>Возврат товара</p>
            <p className={css.description}>
              Если товар имеет дефекты или потерял товарный вид при транспортировке, мы организуем и оплатим обратную доставку товара, и вернем вам деньги
            </p>
          </div>
        </div>
        <div className={css.item}>
          <ArrowIcon className={css.icon} />
          <div className={css.content}>
            <p className={css.title}>Целостность товара</p>
            <p className={css.description}>
              Мы отвечаем за сохранность товара, если товар будет поврежден, то мы вернем деньги
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};
