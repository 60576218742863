import React, { useState } from 'react';
import css from './index.module.scss';
import { Button, Title } from 'components';
import { ArrowIcon } from 'resources/icons';
import { CallRequestModal } from 'modals';
import { useNavigate } from 'react-router-dom';

type TProps = {};

export const QuestionsScreen = ({  }: TProps) => {
  const navigate = useNavigate();
  const [isCallRequestModalView, setIsCallRequestModalView] = useState(false);

  const openCallRequestModal = () => setIsCallRequestModalView(true);
  const closeCallRequestModal = () => setIsCallRequestModalView(false);
  const goToContacts = () => navigate('/contacts');

  return (
    <div className={css.wrap}>
      <div className={css.container}>
        <Title type='main_page_deco' level='h2' className={css.screenTitle}>Частые вопросы</Title>
        <div className={css.content}>
          <ul className={css.list}>
            <li className={css.item}>
              <ArrowIcon className={css.icon} />
              <div className={css.text}>
                <h4 className={css.question}>С какими видами древесины вы работаете?</h4>
                <p className={css.answer}>Мы изготавливаем наши изделия из липы и осины. Вид древесины указан на странице каждого товара</p>
              </div>
            </li>
            <li className={css.item}>
              <ArrowIcon className={css.icon} />
              <div className={css.text}>
                <h4 className={css.question}>Где располагается ваше производство?</h4>
                <p className={css.answer}>Наше производство и основной склад располагается в Нижегородской области</p>
              </div>
            </li>
            <li className={css.item}>
              <ArrowIcon className={css.icon} />
              <div className={css.text}>
                <h4 className={css.question}>Где можно приобрести ваши товары?</h4>
                <p className={css.answer}>Вы можете заказать наши товары в нашем интернет-магазине, мы экономим на аренде, а вы получаете товары дешевле</p>
              </div>
            </li>
            <li className={css.item}>
              <ArrowIcon className={css.icon} />
              <div className={css.text}>
                <h4 className={css.question}>Можно ли где то посмотреть продукцию вживую перед заказом?</h4>
                <p className={css.answer}>Пока нет, но на данный момент мы работаем над организацией доступного для посещения склада в Нижнем Новгороде</p>
              </div>
            </li>
          </ul>

          <div className={css.actions}>
            <div className={css.callRequestAction}>
              <p className={css.title}>Остались вопросы?<br />Мы перезвоним вам!</p>
              <Button 
                text='Оставить заявку'
                size='tall'
                style='base'
                onClick={openCallRequestModal}
                className={css.button}
              />
            </div>
            <div className={css.goContactsAction}>
              <p className={css.title}>Или свяжитесь с нами<br />так, как вам будет удобно</p>
              <Button 
                text='Наши контакты'
                size='tall'
                style='highlight'
                onClick={goToContacts}
              />
            </div>
          </div>
        </div>
      </div>

      <CallRequestModal isView={isCallRequestModalView} closeFn={closeCallRequestModal} />
    </div>
  );
};
