import { TMoldedSizeUnit, TProductGroupSmall, TSizeUnit } from "store/globalEntities";
import { T_Body_CartCheck, T_Response_CartCheck } from "store/slices/cartAPI/entities";
import { TLocalCartState } from "store/slices/cartLocal/entities";
import { getMeasureUnit } from "./transformData";

export const calcVolume = (
  unit: TSizeUnit | TMoldedSizeUnit, 
  amount: number) => {

  const unitVolume = unit.moldedLength || unit.weight || 1;
  return unitVolume * amount;
}


export const extractIdsFromCart = (cartState: TLocalCartState): T_Body_CartCheck => ({
  groups: cartState.groups.map(group => group.id),
  units: cartState.units.map(unit => unit.id),
});


export type TGroupDiscountInfo = TProductGroupSmall & {
  commonVolume: number,
  
  totalBasePrice: number,
  totalSalePrice: number,

  isHasDiscount: boolean,
  discountValue: number,

  amountBeforeSale: number,
  measureUnit: string,
}

export const calcCartGroupDiscounts = (
  localCart: TLocalCartState, serverCart: T_Response_CartCheck
): TGroupDiscountInfo[] => {

  const discounts: TGroupDiscountInfo[] = [];

  for (let localGroup of localCart.groups) {
    const serverGroup = serverCart.groups.find(group => group.id === localGroup.id);

    if (!serverGroup) continue;

    const serverUnits = serverCart.units.filter(unit => unit.groupId === localGroup.id);
    const localUnits = localCart.units.filter(unit => unit.groupId === localGroup.id);

    const commonVolume = localUnits.reduce((acc, current) => 
      acc + current.volume, 0);

    let totalBasePrice = 0, totalSalePrice = 0, amountUnits = 0;
    for (let localUnit of localUnits)
      for (let serverUnit of serverUnits)
        if (localUnit.id === serverUnit.id) {
          totalBasePrice += localUnit.amount * serverUnit.price;
          totalSalePrice += localUnit.amount * serverUnit.sale;
          amountUnits += calcVolume(serverUnit, localUnit.amount);
        }

    const isHasDiscount = serverGroup.saleLimit <= commonVolume;
    const discountValue = isHasDiscount ? totalBasePrice - totalSalePrice : 0;
    const amountBeforeSale = Math.round(serverGroup.saleLimit < amountUnits ? 0 : serverGroup.saleLimit - amountUnits);
    const measureUnit = getMeasureUnit(serverGroup.type);


    discounts.push({ 
      ...serverGroup, 
      commonVolume, 
      totalBasePrice, 
      totalSalePrice,
      isHasDiscount,
      discountValue,
      amountBeforeSale,
      measureUnit,
    });
  }

  return discounts;
}
