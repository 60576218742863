import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getNounProduct } from 'resources/helpers';
import { ChevronIcon } from 'resources/icons';
import { TGroupDiscountInfo } from 'resources/helpers/cartHelpers';
import { CartLocal } from 'store/slices';
import css from './index.module.scss';

type TProps = {
  discountsList: TGroupDiscountInfo[],
  startMakeOrder: () => void,
};

export const CartController = ({ discountsList, startMakeOrder }: TProps) => {
  const [isShowDiscounts, setIsShowDiscounts] = useState(true);
  const [isDiscountsExist, setIsDiscountsExist] = useState(false);
  
  const changeShowDiscounts = () => setIsShowDiscounts((prev) => !prev); 

  const commonAmount = useSelector(CartLocal.getAllUnitsAmount);

  const commonBasePrice = discountsList.reduce((acc, current) => acc + current.totalBasePrice, 0);
  const totalPrice = discountsList.reduce((acc, current) => 
    acc + (current.isHasDiscount ? current.totalSalePrice : current.totalBasePrice), 0
  );

  useEffect(() => {
    setIsDiscountsExist(false);

    for (let group of discountsList) {
      if (group.isHasDiscount) {
        setIsDiscountsExist(true);
        break;
      }
    }
  }, [discountsList]);

  return (
    <div className={css.wrap}>
      <div className={css.buttonWrap}>
        <button 
          className={css.makeOrder}
          onClick={startMakeOrder}
        >
          Оформить заказ
        </button>
      </div>

      <div className={css.orderInfo}>
        <p className={css.commonAmountPrice}>
          <span>{ `Всего ${commonAmount} ${getNounProduct(commonAmount)}` }</span>
          <span className={css.dots} />
          <span>{ `${commonBasePrice.toLocaleString('ru')}₽` }</span>
        </p>

        <div className={css.orderComposition}>
          <button 
            className={classNames(css.discountsButton, { [css.isShow]: isShowDiscounts })} 
            onClick={changeShowDiscounts}
          >
            <p className={css.text}>Скидки на группы товаров:</p>
            <ChevronIcon className={css.icon} />
          </button>
          
          <ul className={classNames(css.groupsDiscountsList, { [css.isShow]: isShowDiscounts })}>
            { !isDiscountsExist && <li className={css.withoutDiscounts}>Без скидок</li> }
            {
              discountsList.map(group => group.isHasDiscount && (
                <li className={css.item} key={group.id}>
                  <span className={css.name}>{ group.name }</span>
                  <span className={css.dots} />
                  <span className={css.discount}>{ `- ${group.discountValue}₽` }</span>
                </li>
              ))
            }
          </ul>
        </div>

        <div className={css.total}>
          <p className={css.text}>Итого:</p>
          <p className={css.price}>
            { totalPrice.toLocaleString('ru') }
            <span>₽</span>
          </p>
        </div>
      </div>
    </div>
  );
};
