import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductsAPI, SizeUnitsAPI } from 'store/slices';
import { AvailableSizes, Loader, PageSection, ProductAnnotation, ProductDescription, ProductProp, SizeUnitCard, Title, WholesaleProgressBar } from 'components';
import { BreadcrumbsBar, ImageSlider, ProductPageMetaTags } from 'features';
import css from './index.module.scss';

type TProps = {};

export const ProductPage = ({  }: TProps) => {
  const navigate = useNavigate();
  const { catalogSectionKey, productGroupKey, productId } = useParams();

  const [ fetchProduct, { data: product, isFetching: isProductLoading } 
    ] = ProductsAPI.useLazyGetProductWithIdQuery();

  useEffect(() => {
    if (!productId && isNaN(Number(productId)) ) 
      navigate('/catalog');
    else
      fetchProduct(Number(productId));
  }, [productId]);

  return (
    <div className={css.wrap} itemScope itemType='https://schema.org/Product'>
      {
        !!product && !!product.data && (
          <>
            <header className={css.header}>
              <BreadcrumbsBar breadcrumbs={product.breadcrumbs} currentCrumb={productId} />
              <Title type='product_page' level='h1' >{ product.data.name }</Title>
              <meta itemProp='name' content={product.data.name} />
            </header>

            <div className={css.info}>
              <div className={css.imagesWrap}>
                <ImageSlider images={product.data.images} />
              </div>
              <div className={css.infoWrap}>
                <ProductDescription>{ product.data.description }</ProductDescription>
                <meta itemProp='description' content={product.data.description} />
                <link itemProp='availability' href='https://schema.org/InStock' />
                {
                  !!product.data.props.length && (
                    <div className={css.props}>
                      {
                        product.data.props.map(prop => 
                          <ProductProp 
                            prop={prop} 
                            key={prop.id} 
                          />
                        )
                      }
                    </div>
                  )
                }
                {
                  !!product.data.annotations.length && (
                    <div className={css.annotations}>
                      {
                        product.data.annotations.map(annotation => 
                          <ProductAnnotation 
                            annotation={annotation}
                            key={annotation.id}
                          />
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>

            <AvailableSizes product={product.data} />
            <WholesaleProgressBar productGroup={product.data.product_group} />
          </>
        )
      }

      <Loader isLoading={isProductLoading} className={css.loader} />

      {/* meta tags */}
      <ProductPageMetaTags 
        title={ product?.data?.name || '' }
        description={ product?.data?.description || '' }
      />
    </div>
  );
};
