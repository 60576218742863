import { TService } from "store/slices/requestsAPI/entities";

export const groupServicesTwoColumns = (items: TService[]): [TService[], TService[]] => {
  const itemsVolumes = [0, 0];
  const groupedItems: [TService[], TService[]] = [[], []];
  const sortedItems = [ ...items ];
  sortedItems.sort((a, b) => (a.description.length > b.description.length) ? -1 : 1);
  
  for (let item of sortedItems) {
    const lowerVolumeColumnIndex = itemsVolumes.indexOf(Math.min(...itemsVolumes));
    groupedItems[lowerVolumeColumnIndex].push(item);
    itemsVolumes[lowerVolumeColumnIndex] += item.description.length;
  }

  return groupedItems;
}