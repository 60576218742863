import React from 'react';

type TProps = {
  className?: string
}

export const RequiredIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.9 16.95L2.625 14.575L6.5 9.7L0.5 7.95L1.75 4.1L7.6 6.275L7.375 -1.43051e-06H11.425L11.2 6.275L17.05 4.1L18.3 7.95L12.3 9.7L16.15 14.575L12.875 16.95L9.4 11.8L5.9 16.95Z" fill="#9F4545"/>
  </svg>
);