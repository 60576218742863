import React from 'react';
import { CallRequestTile, ContactsTile, SocialMediaTile } from 'tiles';
import { Loader, Title } from 'components';
import { RequestsAPI } from 'store/slices';
import { ContactsPageMetaTags } from 'features';
import { transformTelForMask } from 'resources/helpers';
import css from './index.module.scss';

type TProps = {};

export const ContactsPage = ({  }: TProps) => {
  const { data: contacts } = RequestsAPI.useGetShopContactsQuery();

  if (!contacts) 
    return (<div className={css.loaderWrap}><Loader isLoading /></div>);

  return (
    <div className={css.wrap}>
      <Title type='base_page' level='h1'>Контакты</Title>
      <div className={css.content}>
        <div className={css.leftColumn}>
        <p className={css.description}>
          Наши менеджеры охотно помогут вам с выбором товара, подсчетом требуемого объема отделочных материалов и другим вопросам по заказу
        </p>
          <SocialMediaTile 
            telegramLink={contacts.telegramLink}
            whatsappLink={contacts.whatsappLink}
            className={css.socialMedia}
          />
        </div>
        <div className={css.rightColumn}>
          <ContactsTile 
            tel={contacts.tel} 
            email={contacts.email} 
            className={css.contacts}
          />
          <CallRequestTile className={css.callRequest} />
        </div>
      </div>

      {/* meta tags */}
      <ContactsPageMetaTags 
        description={ !contacts ? '' : `Вы можете связаться с нами по телефону ${transformTelForMask(contacts.tel)} \
или написать нам на почту ${contacts.email}. Мы также есть в WhatsApp и Telegram, и, разумеется, вы можете заполнить \
форму на нашем сайте, чтобы оставить заявку на звонок, помощь в подборе товара или отделочные работы` }
      />
    </div>
  );
};
