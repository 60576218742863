import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductGroupAPI, ProductsAPI } from 'store/slices';
import { Image, Loader, PageSection, ProductCard, ProductDescription, ProductProp, Title, WholesaleProgressBar } from 'components';
import { BreadcrumbsBar, PaginationObserver, ProductGroupPageMetaTags } from 'features';
import { useIOPagination } from 'resources/helpers/hooks';
import { TProduct_Tiny } from 'store/slices/productsAPI/entities';
import css from './index.module.scss';

type TProps = {};

export const ProductGroupPage = ({  }: TProps) => {
  const navigate = useNavigate();
  const { catalogSectionKey, productGroupKey } = useParams();

  const [ fetchProductGroup, { data: productGroup, isFetching: isProductGroupLoading, isSuccess }
    ] = ProductGroupAPI.useLazyGetProductGroupWithKeyQuery();

  const [ fetchProductsList, { data: fetchedProducts, isFetching: isLoadingProducts }
    ] = ProductsAPI.useLazyGetProductsListQuery();

  const loadNextPage = (currentPage: number) => {
    if (productGroup?.data) fetchProductsList({ productGroupId: productGroup.data.id, currentPage });
  }

  const { paginationList, paginationObserverProps } = useIOPagination<TProduct_Tiny>({
    list: fetchedProducts,
    isLoading: isLoadingProducts,
    startCondition: !!productGroup?.data && isSuccess,
    loadNextPage
  });

  useEffect(() => {
    if (productGroupKey) fetchProductGroup(productGroupKey);
  }, [productGroupKey]);

  useEffect(() => {
    if (productGroup && isSuccess) {
      if (!productGroup.data) {
        navigate('/catalog');
      }
    }
  }, [productGroup, isSuccess]);

  return (
    <div className={css.wrap} itemScope itemType='https://schema.org/Product'>
      {
        !!productGroup && !!productGroup.data && (
          <>
            <header className={css.header}>
              <BreadcrumbsBar breadcrumbs={productGroup.breadcrumbs} currentCrumb={productGroupKey} />
              <Title type='product_page' level='h1'>{ productGroup.data.name }</Title>
              <meta itemProp='name' content={productGroup.data.name} />
            </header>

            <div className={css.groupInfo}>
              <div className={css.description}>
                <div className={css.descAndProps}>
                  <ProductDescription>{ productGroup.data.description }</ProductDescription>
                  <meta itemProp='description' content={productGroup.data.description} />
                  <link itemProp='availability' href='https://schema.org/InStock' />
                  <div className={css.props}>
                    { 
                      productGroup.data.props.map(prop => (
                        <ProductProp prop={prop} key={prop.id} />
                      ))
                    }
                  </div>
                </div>
                <Image className={css.image} image={productGroup.data.image} />
              </div>
              <WholesaleProgressBar productGroup={productGroup.data} />
            </div>
          </>
        )
      }

      <PageSection title='Товары'>
        <div className={css.products}>
          {
            paginationList.map(product => (
              <ProductCard 
                key={product.id} 
                product={product}
                sectionKey={catalogSectionKey || 'section'}
                groupKey={productGroupKey || 'group'}
              />
            ))
          }
        </div>
        <PaginationObserver { ...paginationObserverProps } />
      </PageSection>

      <Loader isLoading={isProductGroupLoading || isLoadingProducts} className={css.loader} />

      {/* meta tags */}
      <ProductGroupPageMetaTags 
        title={ productGroup?.data?.name || '' }
        description={ productGroup?.data?.description || '' }
      />
    </div>
  );
};
