import { TGalleryImage, TGalleryImagePageItem } from "store/globalEntities";
import { T_GetGalleryImage_RawResponse, T_GetGalleryPage_DoneResponse, T_GetGalleryPage_RawResponse } from "./entities";


// REQUEST: getGalleryPage

export const transformGetGalleryPageResponse = (response: T_GetGalleryPage_RawResponse): T_GetGalleryPage_DoneResponse => {
  const images: TGalleryImagePageItem[] = [];

  response.data.forEach(element => {
    images.push({
      ...element.attributes,
      id: element.id,
      image: {
        width: element.attributes.image.data.attributes.width,
        height: element.attributes.image.data.attributes.height,
        url: element.attributes.image.data.attributes.url,
        thumbnail: element.attributes.image.data.attributes.formats.thumbnail.url,
      },
      hasComment: !!element.attributes.comment.data,
      amountRelatedProducts: element.attributes.products.data.length
    });
  });

  return { images, pagination: response.meta.pagination };
}


// REQUEST: getGalleryImage

export const transformGetGalleryImageResponse = (response: T_GetGalleryImage_RawResponse): TGalleryImage | null => {
  if (!response.data) return null;

  const result: TGalleryImage = {
    ...response.data,
    products: response.data.products.map(pr => ({
      ...pr,
      product: {
        ...pr.product,
        catalog_section: pr.product.product_group.catalog_section,
      } 
    })),
  }

  return result;
}