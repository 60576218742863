import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl, prepareHeadersBase } from 'resources/api';
import { T_Body_CartCheck, T_Response_CartCheck } from './entities';

export const CartAPI = createApi({
  reducerPath: 'CartAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl(),
    prepareHeaders: prepareHeadersBase,
  }),
  endpoints: (builder) => ({
    
    cartCheck: builder.mutation<T_Response_CartCheck, T_Body_CartCheck>({
      query: (body: T_Body_CartCheck) => ({
        url: 'products/cart-check',
        method: 'post',
        body,
      }),
    }),
  })
});