import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from 'store/configureStore';
import { AppRouter } from './app/AppRouter';
import { PersistGate } from 'redux-persist/integration/react';
import { AlertState, NavigateState, TooltipState } from 'app/context';
import { Alert, CatalogSearch, HoverTooltip, MobileNavigatePanel } from 'features';
import 'app/index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>

        <TooltipState>
          <AlertState>
            <NavigateState>
              <AppRouter />
              <CatalogSearch />
              <MobileNavigatePanel />
            </NavigateState>

            <HoverTooltip />
            <Alert />
            
          </AlertState>
        </TooltipState>
        
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
