import React from 'react';

type TProps = {
  className?: string
}

export const MailIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.6668 8.3335H8.33342C6.0355 8.3335 4.16675 10.2022 4.16675 12.5002V37.5002C4.16675 39.7981 6.0355 41.6668 8.33342 41.6668H41.6668C43.9647 41.6668 45.8334 39.7981 45.8334 37.5002V12.5002C45.8334 10.2022 43.9647 8.3335 41.6668 8.3335ZM41.6668 12.5002V13.5647L25.0001 26.5293L8.33342 13.5668V12.5002H41.6668ZM8.33342 37.5002V18.8418L23.7209 30.8106C24.0857 31.0971 24.5362 31.2529 25.0001 31.2529C25.464 31.2529 25.9144 31.0971 26.2793 30.8106L41.6668 18.8418L41.6709 37.5002H8.33342Z" fill="#D7D7D7"/>
  </svg>
);