import React, { createContext, useContext, useEffect, useState } from 'react';
import { CheckIcon } from 'resources/icons';

type TAlertInfo = {
  text: string,
  icon?: React.ReactNode,
}

type TContextState = TAlertInfo & {
  alert: (arg: TAlertInfo) => void,
  mode: 'view' | 'hidden',
}

const initialState: TContextState = {
  text: '',
  icon: null,
  alert: (arg: TAlertInfo) => {},
  mode: 'hidden',
}

const AlertContext = createContext<TContextState>(initialState);


export const AlertState = ({ children }: React.PropsWithChildren) => {
  const [text, setText] = useState('');
  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [mode, setMode] = useState<TContextState['mode']>('hidden');

  const alert = ({ text, icon }: TAlertInfo) => {
    setText(text);
    setIcon(icon || <CheckIcon />)
    setMode('view');
  }

  useEffect(() => {
    let timer: NodeJS.Timeout | null;

    if (mode === 'view') {
      timer = setTimeout(() => setMode('hidden'), 3000);
    }

    return () => { 
      if (timer) clearTimeout(timer) 
    };
  }, [text, icon, mode]);

  return (
    <AlertContext.Provider
      value={{ text, icon, alert, mode }}
    >
      { children }
    </AlertContext.Provider>
  );
}

export const useAlertContext = () => useContext(AlertContext);