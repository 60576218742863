import { 
  T_RawResponse_GetSizeUnitsForProduct, T_DoneResponse_GetSizeUnitsForProduct,
  T_RawResponse_GetSizeGroupsForMoldedProduct, T_DoneResponse_GetSizeGroupsForMoldedProduct
} from "./entities";


export const transformGetSizeUnitsForProduct = (
  response: T_RawResponse_GetSizeUnitsForProduct
): T_DoneResponse_GetSizeUnitsForProduct => {

  return response.data;
}


export const transformGetSizeGroupsForMoldedProduct = (
  response: T_RawResponse_GetSizeGroupsForMoldedProduct
): T_DoneResponse_GetSizeGroupsForMoldedProduct => {

  return response.data;
}