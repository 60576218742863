import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Image, Loader, Title } from 'components';
import { GalleryAPI } from 'store/slices';
import { baseUrl } from 'resources/api';
import { TGalleryImagePageItem } from 'store/globalEntities';
import { groupGalleryImages } from 'resources/helpers';
import { AppPagesInfo } from 'app/AppRouter';
import { ArrowIcon, BoxIcon, CheckIcon, CommentIcon } from 'resources/icons';
import css from './index.module.scss';
import { useBreakPoint } from 'resources/helpers/hooks';
import { GalleryPageMetaTags, PaginationObserver } from 'features';

type TProps = {};

export const GalleryPage = ({  }: TProps) => {
  const [nextPageIndex, setNextPageIndex] = useState(1);
  const [columns, setColumns] = useState<TGalleryImagePageItem[][]>([]);
  const isUseTwoColumns = useBreakPoint(820);

  const [ fetchGalleryPage, { data, isFetching }] = GalleryAPI.useLazyGetGalleryPageQuery();
  
  const columnAmount = isUseTwoColumns ? 2 : 3;
  const wasLastPageLoaded = (data?.pagination.pageCount || Infinity) < nextPageIndex;

  const joinColumns = (newColumns: TGalleryImagePageItem[][]) => {
    const prevColumns = columns.slice();
    newColumns.forEach((column, index) => {
      if (!prevColumns[index]) prevColumns[index] = [];
      prevColumns[index] = [ ...prevColumns[index], ...column ];
    });
    setColumns(prevColumns);
  }

  const loadNextPage = () => {
    fetchGalleryPage({ page: nextPageIndex });
    setNextPageIndex(nextPageIndex + 1);
  }

  useEffect(() => {
    setColumns(groupGalleryImages(columns.flat(), columnAmount));
  }, [isUseTwoColumns]);

  useEffect(() => {
    if (data) joinColumns(groupGalleryImages(data.images, columnAmount));
  }, [data]);

  useEffect(() => {
    loadNextPage();
  }, []);


  return (
    <div className={css.wrap}>

      <Title type='base_page' level='h1'>Наши работы</Title>
      <div className={css.gallery} style={{ gridTemplateColumns: `repeat(${columnAmount}, 1fr)` }}>
        {
          columns.map((column, index) => (
            <div className={css.column} key={index}>
              {
                column.map(unit => (
                  <Link to={`${AppPagesInfo.ourWorks.path}/${unit.id}`} key={unit.id} className={css.imgContainer}>
                    <Image image={unit.image} className={css.image} />
                    <div className={css.info}>
                      <div className={css.attributes}>
                        {
                          unit.hasComment && (
                            <div className={classNames(css.prop, css.propComment)}>
                              <CommentIcon className={css.icon} />
                              <div className={css.mark}><CheckIcon className={css.icon} /></div>
                            </div>
                          )
                        }
                        {
                          !!unit.amountRelatedProducts && (
                            <div className={classNames(css.prop, css.propProducts)}>
                              <BoxIcon className={css.icon} />
                              <div className={css.mark}><p>{ unit.amountRelatedProducts }</p></div>
                            </div>
                          )
                        }
                      </div>
                      <div className={classNames(css.prop, css.propGo)}>
                        <ArrowIcon className={css.icon} />
                      </div>
                    </div>
                  </Link>
                ))
              }
            </div>
          ))
        }
      </div>

      <Loader isLoading={isFetching} className={css.loader} />
      <PaginationObserver isLoading={isFetching} onViewAction={loadNextPage} stopUse={wasLastPageLoaded} />

      {/* meta tags */}
      <GalleryPageMetaTags />
    </div>
  );
};
