import { useRef } from "react";
import { useTooltipContext } from "app/context";


type THookProps = {
  content: React.ReactNode, // can use <span> for highlighting text parts
  vertical?: 'top' | 'bottom',
  horizontal?: 'left' | 'right',
}

type TReturnProps <RefElementType> = {
  ref: React.RefObject<RefElementType>,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
}


export const useTooltip = <RefElementType extends HTMLElement>(
  { content, vertical = 'top', horizontal = 'right' }: THookProps
): TReturnProps<RefElementType> => {

  const { setTargetRef, setContent, setPosition, showTooltip, hideTooltip } = useTooltipContext();
  const targetRef = useRef<RefElementType>(null);

  const onMouseEnter = () => {
    if (!targetRef.current) return;

    setTargetRef(targetRef);
    setPosition({ vertical, horizontal });
    setContent(content);
    showTooltip();
  };

  const onMouseLeave = () => {
    hideTooltip();
  };

  return { ref: targetRef, onMouseEnter, onMouseLeave };
}