import React from 'react';
import css from './index.module.scss';
import { SizeUnitProp } from 'components/SizeUnitProp';
import { CrossIcon, MinusIcon, PlusIcon } from 'resources/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CartLocal } from 'store/slices';
import { baseUrl } from 'resources/api';
import { CartUnitPrice } from './CartUnitPrice';
import { TServerCartUnit } from 'store/slices/cartAPI/entities';
import { TGroupDiscountInfo } from 'resources/helpers/cartHelpers';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { AppPagesInfo } from 'app/AppRouter';

type TProps = {
  unit: TServerCartUnit,
  group: TGroupDiscountInfo,
  className?: string,
};

export const CartUnitCard = ({ unit, group, className }: TProps) => {
  const dispatch = useDispatch();

  const productUrl = `${AppPagesInfo.catalog.path}/${unit.catalogSectionKey}/${unit.productGroupKey}/${unit.productId}`;

  const localUnit = useSelector(CartLocal.getUnit(unit.id)) || { amount: 0 };

  const removeUnit = () => {
    dispatch(CartLocal.removeUnit({ 
      amount: localUnit.amount, 
      productGroup: group, 
      sizeUnit: unit, 
      productId: unit.productId }));
  }
  const decrementAmount = () => {
    dispatch(CartLocal.removeUnit({ 
      amount: unit.inPackage, 
      productGroup: group, 
      sizeUnit: unit, 
      productId: unit.productId }));
  }
  const incrementAmount = () => {
    dispatch(CartLocal.addUnit({ 
      amount: unit.inPackage, 
      productGroup: group, 
      sizeUnit: unit, 
      productId: unit.productId }));
  }

  if (localUnit.amount === 0)
    return <></>;

  return (
    <div className={classNames(css.wrap, className)}>
      <Link to={productUrl} className={css.imageWrap}>
        <img src={ baseUrl() + unit.imageUrl } alt="" className={css.image} />
      </Link>

      <div className={css.infoWrap}>
        <div className={css.info}>
          <Link to={productUrl} className={css.title}>{ unit.name }</Link>
          <div className={css.props}>
            {
              unit.props.map(prop => <SizeUnitProp key={prop.id} prop={prop} isSmall />)
            }
            {
              !!unit.moldedLength && <SizeUnitProp isSmall prop={{ 
                id: unit.id,
                name: 'Длина изделия', 
                value: `${unit.moldedLength}`, 
                measureUnit: 'м' }} 
              /> 
            }
          </div>
        </div>
        <div className={css.removeControl}>
          <button 
            className={css.removeButton}
            onClick={removeUnit}
          >
            <CrossIcon className={css.icon} />
          </button>
        </div>
      </div>

      <div className={css.controlWrap}>
        <CartUnitPrice
          unit={unit}
          isGroupHasSale={group.isHasDiscount}
          amountBeforeSale={group.amountBeforeSale}
          measureUnit={group.measureUnit}
        />
        <div className={css.amountControl}>
          <button 
            className={css.button}
            onClick={decrementAmount}
            disabled={localUnit.amount <= unit.inPackage}
          >
            <MinusIcon className={css.icon} />
          </button>
          <p className={css.amount}>{ `${ localUnit.amount } шт`}</p>
          <button 
            className={css.button}
            onClick={incrementAmount}
          >
            <PlusIcon className={css.icon} />
          </button>
        </div>
      </div>
    </div>
  );
};
