import React, { useEffect, useRef } from 'react';
import { useOnClickOutside } from 'resources/helpers/hooks';
import { Title } from 'components';
import { CrossIcon } from 'resources/icons';
import css from './index.module.scss';
import classNames from 'classnames';

export type TModalWindowProps = {
  title: string,
  isView: boolean,
  closeFn: () => void,

  scrollAreaClassName?: string,
  modalWindowClassName?: string,
} & React.PropsWithChildren;

export const ModalWindow = ({ 
  children, title, isView, closeFn, 
  modalWindowClassName = '', scrollAreaClassName = '',
}: TModalWindowProps) => {

  const modalWindowRef = useRef(null);
  
  useOnClickOutside({ 
    ref: modalWindowRef, 
    handler: closeFn 
  });

  useEffect(() => {
    if (isView) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }, [isView]);

  if (!isView) 
    return <></>;

  return (
    <div className={css.wrap}>
      <div className={classNames(css.scroll, scrollAreaClassName)}>
        <div className={classNames(css.modal, modalWindowClassName)} ref={modalWindowRef}>
          <div className={css.header}>
            <Title 
              type='modal' 
              level='h2'
              className={css.title}  
            >
              { title }
            </Title>
            <button 
              className={css.closeButton}
              onClick={closeFn}
            >
              <CrossIcon className={css.icon} />
            </button>
          </div>

          <div className={css.content}>
            { children }
          </div>
        </div>
      </div>
    </div>
  );
};
