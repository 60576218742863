import React from 'react';
import css from './index.module.scss';
import { TServerCartUnit } from 'store/slices/cartAPI/entities';
import { useSelector } from 'react-redux';
import { CartLocal } from 'store/slices';
import classNames from 'classnames';

type TProps = {
  unit: TServerCartUnit,
  isGroupHasSale: boolean,
  amountBeforeSale: number,
  measureUnit: string, 
};

export const CartUnitPrice = ({ unit, isGroupHasSale, amountBeforeSale, measureUnit }: TProps) => {

  const cartUnit = useSelector(CartLocal.getUnit(unit.id)) || { amount: 0 };
 
  const commonBasePrice: number = unit.price * cartUnit.amount;
  const commonSalePrice: number = unit.sale * cartUnit.amount;

  return (
    <div className={classNames(css.wrap, { [css.isSale]: isGroupHasSale })}>
      <p className={css.pastPrice}>
        { commonBasePrice.toLocaleString('ru') }
        <span className={css.ruble}>{ '₽' }</span>
      </p>
      <p className={css.currentPrice}>
        { isGroupHasSale ? commonSalePrice.toLocaleString('ru') : commonBasePrice.toLocaleString('ru') }
        <span className={css.ruble}>{ '₽' }</span>
      </p>
      <p className={css.comment}>{ `еще ${amountBeforeSale} ${measureUnit} до скидки` }</p>
    </div>
  );
};
