import { TGalleryImagePageItem } from "store/globalEntities";


const shuffleColumn = (column: TGalleryImagePageItem[]) => {
  for (let i = 0; i < column.length / 2; i += 2) {
    const unit = column[i];
    column[i] = column[column.length - 1 - i];
    column[column.length - 1 - i] = unit;
  }

  return column;
}


export const groupGalleryImages = (
  images: TGalleryImagePageItem[], amountColumns: number
): TGalleryImagePageItem[][] => {

  const units = images.map(img => ({ img, heightVolume: 0 }));
  const columnsHeightVolume: number[] = Array(amountColumns).fill(0);
  const columns: TGalleryImagePageItem[][] = [];
  for (let i = 0; i < amountColumns; i++) columns.push([]);

  for (let unit of units) {
    unit.heightVolume = unit.img.image.height / unit.img.image.width;
  }

  units.sort((u1, u2) => (u1.heightVolume || 0) > (u2.heightVolume || 0) ? -1 : 1);

  // group images
  for (let unit of units) {
    const lowerVolumeColumnIndex = columnsHeightVolume.indexOf(Math.min(...columnsHeightVolume));
    columns[lowerVolumeColumnIndex].push(unit.img);
    columnsHeightVolume[lowerVolumeColumnIndex] += unit.heightVolume || 0;
  }

  // shuffle images
  for (let i = 0; i < columns.length; i++) {
    const columnCount = i % 3;

    if (columnCount === 0) {
      shuffleColumn(columns[i]);
      columns[i] = columns[i].reverse();
    }

    if (columnCount === 1) {
      shuffleColumn(columns[i]);
    }

    if (columnCount === 2) {
      columns[i] = [
        ...columns[i].slice(0, columns[i].length / 2).reverse(),
        ...columns[i].slice(columns[i].length / 2).reverse(),
      ];
      shuffleColumn(columns[i]);
    }
  }

  return columns;
}