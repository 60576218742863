import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TMoldedSizeUnit, TProduct, TProductGroupSmall } from 'store/globalEntities';
import { TProduct_Tiny } from 'store/slices/productsAPI/entities';
import { MinusIcon, PlusIcon } from 'resources/icons';
import { CartLocal } from 'store/slices';
import css from './index.module.scss';

type TProps = {
  sizeUnit: TMoldedSizeUnit,
  productGroup: TProductGroupSmall,
  product: TProduct | TProduct_Tiny,
};

export const MoldedSizeUnitCard = ({ sizeUnit, productGroup, product }: TProps) => {
  const dispatch = useDispatch();
  
  const cartUnit = useSelector(CartLocal.getUnit(sizeUnit.id)) || { amount: 0 };
  const isUseSale = useSelector(CartLocal.isGroupHasSale(productGroup.id));

  const currentCommonPrice = cartUnit.amount * (isUseSale ? sizeUnit.sale : sizeUnit.price);

  const decrementAmount = () => {
    dispatch(CartLocal.removeUnit({ amount: product.inPackage, productGroup, sizeUnit, productId: product.id }));
  }
  const incrementAmount = () => {
    dispatch(CartLocal.addUnit({ amount: product.inPackage, productGroup, sizeUnit, productId: product.id }));
  }

  if (!sizeUnit.moldedLength)
    return <></>;

  return (
    <div className={css.wrap}>
      <div className={css.info}>
        <p className={css.length}>{ `${sizeUnit.moldedLength.toFixed(1)} м` }</p>
        <p className={css.price}>
          { `${ isUseSale ? sizeUnit.sale : sizeUnit.price } ₽ / шт` }
        </p>
      </div>

      {
        cartUnit.amount == 0
        ? (
          <button className={css.addButton} onClick={incrementAmount}>
            <PlusIcon className={css.icon} />
          </button>
        )
        : (
          <div className={css.controlArea}>
            <button className={css.button} onClick={decrementAmount}>
              <MinusIcon className={css.icon} />
            </button>

            <div className={css.controlInfo}>
              <span className={css.amount}>{ `${cartUnit.amount} шт` }</span>
              <span className={css.mainPrice}>{ `${currentCommonPrice} руб` }</span>
            </div>

            <button className={css.button} onClick={incrementAmount}>
              <PlusIcon className={css.icon} />
            </button>
          </div>
        )
      }
    </div>
  );
};
