import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import persistCongigStorage from "redux-persist/lib/storage";
import { useDispatch } from "react-redux";
import { 
  CatalogAPI, 
  ProductGroupAPI, 
  ProductsAPI, 
  SizeUnitsAPI,
  CartAPI,
  RequestsAPI,
  CartLocal,
  UserLocal, 
  OrdersLocal,
  GalleryAPI,
} from './slices';


const rootReducer = combineReducers({
  [CatalogAPI.reducerPath]: CatalogAPI.reducer,
  [ProductGroupAPI.reducerPath]: ProductGroupAPI.reducer,
  [ProductsAPI.reducerPath]: ProductsAPI.reducer,
  [SizeUnitsAPI.reducerPath]: SizeUnitsAPI.reducer,
  [CartAPI.reducerPath]: CartAPI.reducer,
  [RequestsAPI.reducerPath]: RequestsAPI.reducer,
  [GalleryAPI.reducerPath]: GalleryAPI.reducer,
  'CART': CartLocal.CartReducer,
  'USER': UserLocal.UserReducer,
  'ORDERS': OrdersLocal.OrdersReducer,
});

const persistConfig = {
  key: 'ecotrees-shop',
  storage: persistCongigStorage,
  whitelist: ['CART', 'USER', 'ORDERS'],
}

const persistRootReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistRootReducer,
  devTools: true,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
    .concat(CatalogAPI.middleware)
    .concat(ProductGroupAPI.middleware)
    .concat(ProductsAPI.middleware)
    .concat(SizeUnitsAPI.middleware)
    .concat(CartAPI.middleware)
    .concat(RequestsAPI.middleware)
    .concat(GalleryAPI.middleware)
});

export const persistor = persistStore(store);


export type TState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<TAppDispatch>();