import { createSelector } from "@reduxjs/toolkit";
import { extractIdsFromCart } from "resources/helpers/cartHelpers";
import { TState } from "store/configureStore";

const getState = (state: TState) => state;

// получение локальной корзины
export const getCartState = createSelector(
  getState, 
  state => state.CART
);

// пуста ли корзина
export const isCartEmpty = createSelector(
  getCartState,
  state => state.units.length === 0
);

// получить группу
export const getGroup = (groupId: number) => createSelector(
  getCartState,
  (state) => state.groups.find(group => group.id === groupId)
);

// получить список групп
export const getGroupsList = createSelector(
  getCartState,
  (state) => state.groups
);

// получить суммарный volume группы
export const getGroupCommonVolume = (groupId: number) => createSelector(
  getGroup(groupId),
  (group) => group?.commonVolume || 0
);

// узнать применяется ли скидка к элементам группы
export const isGroupHasSale = (groupId: number) => createSelector(
  getGroup(groupId),
  (group) => group ? group.commonVolume >= group.saleLimit : false
);

// сколько осталось до скидки
export const howMuchBeforeSale = (groupId: number, saleLimit: number) => createSelector(
  getGroup(groupId),
  (group) => {
    if (!group) return saleLimit;
    return group.commonVolume >= saleLimit ? 0 : saleLimit - group.commonVolume;
  }
);

// получить юнит
export const getUnit = (unitId: number) => createSelector(
  getCartState,
  (state) => state.units.find(unit => unit.id === unitId)
);

// получить список всех юнитов
export const getUnitsList = createSelector(
  getCartState,
  (state) => state.units
);

// получить список юнитов конкретной группы
export const getUnitsOfGroup = (groupId: number) => createSelector(
  getCartState,
  (state) => state.units.filter(unit => unit.groupId === groupId)
);

// количество уникальных юнитов в корзине
export const getUniqUnitsAmount = createSelector(
  getCartState,
  (state) => state.units.length
);

// общее количество юнитов в корзине
export const getAllUnitsAmount = createSelector(
  getCartState,
  (state) => state.units.reduce((acc, current) => acc + current.amount, 0)
);

// получить идентификаторы групп и юнитов для запроса корзины с сервера
export const getIdsForRequest = createSelector(
  getCartState,
  (state) => extractIdsFromCart(state)
);
