import React from 'react';

type TProps = {
  className?: string
}

export const ContactsIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.6093 16.3907C23.4856 16.2667 23.3387 16.1684 23.1769 16.1013C23.0152 16.0343 22.8418 15.9997 22.6667 15.9997C22.4916 15.9997 22.3182 16.0343 22.1564 16.1013C21.9947 16.1684 21.8477 16.2667 21.724 16.3907L19.5987 18.516C18.6133 18.2227 16.7747 17.556 15.6093 16.3907C14.444 15.2253 13.7773 13.3867 13.484 12.4013L15.6093 10.276C15.7333 10.1523 15.8316 10.0053 15.8987 9.84359C15.9657 9.68184 16.0003 9.50844 16.0003 9.33333C16.0003 9.15822 15.9657 8.98483 15.8987 8.82307C15.8316 8.66132 15.7333 8.51438 15.6093 8.39067L10.276 3.05733C10.1523 2.9334 10.0053 2.83509 9.84359 2.76801C9.68183 2.70093 9.50844 2.6664 9.33333 2.6664C9.15822 2.6664 8.98483 2.70093 8.82307 2.76801C8.66132 2.83509 8.51438 2.9334 8.39066 3.05733L4.77466 6.67333C4.268 7.18 3.98266 7.876 3.99333 8.58667C4.024 10.4853 4.52666 17.08 9.724 22.2773C14.9213 27.4747 21.516 27.976 23.416 28.008H23.4533C24.1573 28.008 24.8227 27.7307 25.3267 27.2267L28.9427 23.6107C29.0666 23.4869 29.1649 23.34 29.232 23.1783C29.2991 23.0165 29.3336 22.8431 29.3336 22.668C29.3336 22.4929 29.2991 22.3195 29.232 22.1577C29.1649 21.996 29.0666 21.849 28.9427 21.7253L23.6093 16.3907ZM23.44 25.34C21.776 25.312 16.0827 24.8653 11.6093 20.3907C7.12133 15.9027 6.68666 10.1893 6.66 8.55867L9.33333 5.88533L12.7813 9.33333L11.0573 11.0573C10.9006 11.2139 10.7854 11.4071 10.7221 11.6194C10.6587 11.8317 10.6493 12.0565 10.6947 12.2733C10.7267 12.4267 11.5093 16.0627 13.7227 18.276C15.936 20.4893 19.572 21.272 19.7253 21.304C19.9421 21.3506 20.167 21.3419 20.3795 21.2788C20.592 21.2157 20.7852 21.1001 20.9413 20.9427L22.6667 19.2187L26.1147 22.6667L23.44 25.34Z" fill="#D7D7D7"/>
  </svg>
);