import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header, Footer } from 'features';
import css from './index.module.scss';


export const AppOutlet = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={css.wrap}>
      <div className={css.outletWrap}>
        <div className={css.headerWrap}>
          <Header />
        </div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
