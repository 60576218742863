import React from 'react';
import classNames from 'classnames';
import { SearchIcon } from 'resources/icons';
import { useNavigateContext } from 'app/context';
import css from './index.module.scss';

type TProps = {};

export const SearchScreen = ({  }: TProps) => {

  const { openSearch } = useNavigateContext();
  
  return (
    <div className={css.wrap}>
      <div className={css.imagesContainer}>
        <img src="images/search-screen-img-left.png" className={classNames(css.img, css.imgL)} alt="" />
        <img src="images/search-screen-img-right-top.png" className={classNames(css.img, css.imgRT)} alt="" />
        <img src="images/search-screen-img-right-bottom.png" className={classNames(css.img, css.imgRB)} alt="" />
      </div>
      
      <h3 className={css.aboutShop}>Отделочные материалы, мебель и другие изделия из натуральной древесины</h3>
      <button className={css.search} onClick={openSearch}>
        <p className={css.pseudoInput}>Поиск...</p>
        <div className={css.pseudoBtn}><SearchIcon className={css.icon} /></div>
      </button>
      <p className={css.advantages}>
        <span>Красиво</span>
        <span>/</span>
        <span>Экологично</span>
        <span>/</span>
        <span>Доступно</span>
      </p>
    </div>
  );
};
