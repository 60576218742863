import React, { useState } from 'react';
import { SizeUnitAnnotation, SizeUnitProp } from 'components';
import { InfoIcon, MinusIcon, PlusIcon } from 'resources/icons';
import { getMeasureUnit } from 'resources/helpers';
import { useTooltip } from 'resources/helpers/hooks';
import { TProduct, TProductGroupSmall, TSizeUnit } from 'store/globalEntities';
import { TProduct_Tiny } from 'store/slices/productsAPI/entities';
import { CartLocal } from 'store/slices';
import { useDispatch, useSelector } from 'react-redux';
import css from './index.module.scss';

type TProps = {
  sizeUnit: TSizeUnit,
  productGroup: TProductGroupSmall,
  product: TProduct | TProduct_Tiny,
};

export const SizeUnitCard = ({ sizeUnit, productGroup, product }: TProps) => {
  const dispatch = useDispatch();
  const cartUnit = useSelector(CartLocal.getUnit(sizeUnit.id)) || { amount: 0 };
  const isUseSale = useSelector(CartLocal.isGroupHasSale(productGroup.id));
  const beforeSale = useSelector(CartLocal.howMuchBeforeSale(productGroup.id, productGroup.saleLimit));

  const measureUnit = getMeasureUnit(productGroup.type);
  const currentCommonPrice = cartUnit.amount * (isUseSale ? sizeUnit.sale : sizeUnit.price);

  const tooltipProps = useTooltip<HTMLDivElement>({
    content: (
      isUseSale
      ? <p>Вы приобретаете товары группы <span>{ productGroup.name }</span> со скидкой, удачных покупок!</p>
      : <p>Вы приобретаете товар по обычной цене. Добавьте в корзину ещё <span>{ `${beforeSale.toLocaleString('ru')} ${measureUnit} ` }</span>
        товаров группы <span>{ productGroup.name }</span> и получите на них скидку. Перерасчет произойдет автоматически</p>
    ),
    vertical: 'top',
    horizontal: 'right',
  });

  const decrementAmount = () => {
    dispatch(CartLocal.removeUnit({ amount: product.inPackage, productGroup, sizeUnit, productId: product.id }));
  }
  const incrementAmount = () => {
    dispatch(CartLocal.addUnit({ amount: product.inPackage, productGroup, sizeUnit, productId: product.id }));
  }

  return (
    <div className={css.wrap}>
      <div className={css.info}>
        {
          !!sizeUnit.props.length && (
            <div className={css.props}>
              {
                sizeUnit.props.map(prop => <SizeUnitProp prop={prop} key={prop.id} />)
              }
            </div>
          )
        }
        {
          !!sizeUnit.annotations.length && (
            <div className={css.annotations}>
              {
                sizeUnit.annotations.map(ann => <SizeUnitAnnotation annotation={ann} key={ann.id} />)
              }
            </div>
          )
        }
      </div>

      <div className={css.pricesInfo}>
        {
          sizeUnit.isContract
          ? (
            <>
              <p className={css.label}>Цена</p>
              <div className={css.info}>
                <p className={css.prices}>Договорная</p> 
              </div>
            </>
          )
          : (
            <>
              <p className={css.label}>Со скидкой / Обычная цена</p>
              <div className={css.info}>
                <p className={css.prices}>
                  <span className={isUseSale ? css.active : ''}>{ sizeUnit.sale.toLocaleString('ru') }</span>
                  { " / " }
                  <span className={!isUseSale ? css.active : ''}>{ sizeUnit.price.toLocaleString('ru') }</span>
                </p>

                <div className={css.pricesUnit}>
                  <span>руб</span>
                  <span>шт</span>
                </div>

                <div { ...tooltipProps }>
                  <InfoIcon className={css.infoIcon} />
                </div>
              </div>
            </>
          )
        }
      </div>

      {
        cartUnit.amount == 0
        ? (
          <button className={css.addButton} onClick={incrementAmount}>
            <PlusIcon className={css.icon} />
          </button>
        )
        : (
          <div className={css.controlArea}>
            <button className={css.button} onClick={decrementAmount}>
              <MinusIcon className={css.icon} />
            </button>

            <div className={css.controlInfo}>
              <span className={css.amount}>{ 
                productGroup.type === 'weight' ? `${(cartUnit.amount * (sizeUnit.weight || 1)).toLocaleString('ru')} кг` : `${cartUnit.amount} шт`}
              </span>
              <span className={css.mainPrice}>{ `${currentCommonPrice.toLocaleString('ru')} руб` }</span>
            </div>

            <button className={css.button} onClick={incrementAmount}>
              <PlusIcon className={css.icon} />
            </button>
          </div>
        )
      }
    </div>
  );
};
