import React from 'react';

type TProps = {
  className?: string
}

export const SadIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45 7.5C24.3225 7.5 7.5 24.3225 7.5 45C7.5 65.6775 24.3225 82.5 45 82.5C65.6775 82.5 82.5 65.6775 82.5 45C82.5 24.3225 65.6775 7.5 45 7.5ZM45 75C28.4588 75 15 61.5412 15 45C15 28.4588 28.4588 15 45 15C61.5412 15 75 28.4588 75 45C75 61.5412 61.5412 75 45 75Z" fill="#B9B9B9"/>
    <path d="M31.875 45C34.9816 45 37.5 42.4816 37.5 39.375C37.5 36.2684 34.9816 33.75 31.875 33.75C28.7684 33.75 26.25 36.2684 26.25 39.375C26.25 42.4816 28.7684 45 31.875 45Z" fill="#B9B9B9"/>
    <path d="M58.0988 44.9475C61.1909 44.9475 63.6975 42.4409 63.6975 39.3488C63.6975 36.2566 61.1909 33.75 58.0988 33.75C55.0066 33.75 52.5 36.2566 52.5 39.3488C52.5 42.4409 55.0066 44.9475 58.0988 44.9475Z" fill="#B9B9B9"/>
    <path d="M45 52.5C33.75 52.5 30 63.75 30 63.75H60C60 63.75 56.25 52.5 45 52.5Z" fill="#B9B9B9"/>
  </svg>
);