import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { GalleryImageComment, GalleryImageLabels, Image, Loader, PageSection, ProductCard } from 'components';
import { GalleryAPI } from 'store/slices';
import { AppPagesInfo } from 'app/AppRouter';
import { useOnClickOutside } from 'resources/helpers/hooks';
import { CheckIcon, ChevronIcon, UndoIcon } from 'resources/icons';
import css from './index.module.scss';
import { GalleryImagePageMetaTags } from 'features';

type TProps = {};

export const GalleryImagePage = ({  }: TProps) => {
  const navigate = useNavigate();
  const { imageId } = useParams();
  const imgContainerRef = useRef(null);
  const [selectedProductId, setSelectedProductId] = useState(0);

  const [ fetchImage, { data, isFetching } ] = GalleryAPI.useLazyGetGalleryImageQuery();
  
  const goGallery = () => navigate(AppPagesInfo.ourWorks.path);
  const clearProductSelect = () => setSelectedProductId(0);
  const selectProduct = (id: number) => () => {
    setSelectedProductId(id);

    const scrollTarget = document.getElementById(`related-product-card-${id}`);
    if (!scrollTarget) return;
    
    window.scrollBy({
      top: scrollTarget.getBoundingClientRect().top - 200,
      behavior: 'smooth'
    });
  }

  useOnClickOutside({
    ref: imgContainerRef,
    handler: clearProductSelect,
  });

  useEffect(() => {
    if (data === null) goGallery();
  }, [data]);

  useEffect(() => {
    if (!imageId || isNaN(+imageId)) goGallery();
    else fetchImage(+imageId);
  }, [imageId]);

  return (
    <div className={css.wrap}>
      {
        data && (
          <div className={css.container}>
            <div className={css.imageArea}>
              <div className={css.imgContainer} ref={imgContainerRef}>
                <Image image={data.image} className={css.image} />
                <div className={css.marks}>
                  {
                    data.products.map(pr => (
                      <button 
                        key={pr.product.id}
                        onClick={selectProduct(pr.product.id)}
                        className={classNames(css.mark, { [css.active]: pr.product.id === selectedProductId })}
                        style={{ top: `${pr.markOffsetTop}%`, left: `${pr.markOffsetLeft}%` }}
                      >
                        <div><CheckIcon className={css.icon} /></div>
                      </button>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className={css.contentArea}>
              <PageSection 
                title='Наши работы' 
                className={css.pageSection}
                controls={(
                  <button className={css.navigateBtn} onClick={goGallery}>
                    <UndoIcon className={css.icon} />
                  </button>
                )}
              >
                <GalleryImageLabels date={data.date} madeBy={data.madeBy} />
                <GalleryImageComment comment={data.comment} />
              </PageSection>
              <PageSection title={ data.products.length ? 'Товары на фото' : '' } className={css.pageSection}>
                <div className={css.list}>
                  {
                    data.products.map(pr => (
                      <div key={pr.product.id} className={css.item} id={`related-product-card-${pr.product.id}`}>
                        <Link 
                          to={`${AppPagesInfo.catalog.path}/${pr.product.catalog_section.key}/${pr.product.product_group.key}`}
                          className={css.groupLink}
                        >
                          <p className={css.text}>{ pr.product.product_group.name }</p>
                          <ChevronIcon className={css.icon} />
                        </Link>
                        <div className={css.cardWrap}>
                          <ProductCard 
                            className={css.productCard}
                            product={pr.product}
                            groupKey={pr.product.product_group.key}
                            sectionKey={pr.product.catalog_section.key}
                          />
                          <div className={classNames(css.checked, { [css.active]: pr.product.id === selectedProductId })}>
                            <CheckIcon className={css.icon} />
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </PageSection>
            </div>
          </div>
        )
      }
      <Loader isLoading={isFetching} className={css.loader} />

      {/* meta tags */}
      <GalleryImagePageMetaTags 
        description={ data?.comment ? `${data.comment.name}, ${data.comment.city}: ${data.comment.text}` : '' }
      />
    </div>
  );
};
