import React, { createContext, useContext, useState } from 'react';

type TContextState = {
  isMenuOpen: boolean,
  toggleMenuMode: () => void,

  isSearchOpen: boolean,
  openSearch: () => void,
  closeSearch: () => void,
  currentSearchQuery: string,
  setCurrentSearchQuery: (query: string) => void,
}

const initialState: TContextState = {
  isMenuOpen: false,
  toggleMenuMode: () => {},

  isSearchOpen: false,
  openSearch: () => {},
  closeSearch: () => {},
  currentSearchQuery: '',
  setCurrentSearchQuery: (query: string) => {},
}

const NavigateContext = createContext<TContextState>(initialState);


export const NavigateState = ({ children }: React.PropsWithChildren) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [currentSearchQuery, setCurrentSearchQuery] = useState('');

  const toggleMenuMode = () => setIsMenuOpen(currentValue => !currentValue);
  const openSearch = () => setIsSearchOpen(true);
  const closeSearch = () => setIsSearchOpen(false);

  return (
    <NavigateContext.Provider
      value={{ 
        isMenuOpen, toggleMenuMode, 
        isSearchOpen, openSearch, closeSearch, currentSearchQuery, setCurrentSearchQuery }}
    >
      { children }
    </NavigateContext.Provider>
  );
}

export const useNavigateContext = () => useContext(NavigateContext);