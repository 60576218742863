import React from 'react';

type TProps = {
  className?: string
}

export const ChevronIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.207 17.707L16.914 12L11.207 6.29297L9.79297 7.70697L14.086 12L9.79297 16.293L11.207 17.707Z" fill="#938264"/>
  </svg>
);