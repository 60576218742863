import React from 'react';
import css from './index.module.scss';

type TProps = {
  children: string
};

export const ProductDescription = ({ children }: TProps) => {
  const paragraphs = children.split('\n');

  return (
    <p className={css.text}>
      {
        paragraphs.map((paragraph, index) => (
          <span key={index}>
            { !!index && <><br /><br /></> }
            { paragraph }
          </span>
        ))
      }
    </p>
  );
};
