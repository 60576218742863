import { createSlice } from "@reduxjs/toolkit";
import { TLocalCartState } from "./entities";
import { CartSliceActions } from "./actions";

const initialState: TLocalCartState = {
  groups: [],
  units: [],
}

const CartSlice = createSlice({
  name: 'CartSlice',
  initialState,
  reducers: CartSliceActions
});


export const CartReducer = CartSlice.reducer;
export const { addUnit, removeUnit, clearCart } = CartSlice.actions;