import React from 'react';
import classNames from 'classnames';
import { CheckIcon } from 'resources/icons';
import { useAlertContext } from 'app/context';
import css from './index.module.scss';

type TProps = {};

export const Alert = ({  }: TProps) => {
  const { text, icon, mode } = useAlertContext();

  return (
    <div className={classNames(css.wrap, css[mode])}>
      { icon || <CheckIcon /> }
      <p>{ text }</p>
    </div>
  );
};
