import React, { useEffect, useState } from 'react';
import { UseFormRegisterReturn, useForm } from 'react-hook-form';
import { Button } from 'components';
import { CheckIcon, CrossIcon, EditIcon, UserIcon } from 'resources/icons';
import { TUserData } from 'store/slices/userLocal/entities';
import { useDispatch, useSelector } from 'react-redux';
import { UserLocal } from 'store/slices';
import css from './index.module.scss';


type TFormFieldProps = {
  label: string,
  placeholder: string,
  register: UseFormRegisterReturn<string>,
  error?: string,
};

const FormField = ({ label, register, error, placeholder }: TFormFieldProps) => (
  <label className={css.field}>
    <p className={css.label}>{ label }</p>
    <input placeholder={placeholder} { ...register } className={css.input} />
    <p className={css.error}>{ error }</p>
  </label>
);

export const UserDataForm = () => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const user = useSelector(UserLocal.getUserData);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<TUserData>({ mode: 'onBlur' });

  const setFormValuesFromStore = () => {
    setValue('name', user.name);
    setValue('phone', user.phone);
    setValue('email', user.email);
    setValue('place', user.place);
  }

  const changeDataHandler = () => { 
    setIsDisabled(false);
  }
  const cancelChangeHandler = () => {
    setFormValuesFromStore();
    setIsDisabled(true);
  }
  const saveChangeHandler = (newData: TUserData) => {
    dispatch(UserLocal.setData(newData));
    setIsDisabled(true);
  }

  useEffect(() => {
    setFormValuesFromStore();
  }, [user]);
  
  return (
    <div className={css.wrap}>
      <header className={css.header}>
        <UserIcon className={css.icon} />
        <div className={css.text}>
          <h2 className={css.title}>Ваши данные</h2>
          <p className={css.description}>Они будут использованы при оформлении заказа, но, при необходимости, вы сможете их изменить</p>
        </div>
      </header>
      <form className={css.form}>
        <div className={css.row}>
          <FormField 
            label='Ваше имя:'
            placeholder='Не указано'
            register={register('name', {
              disabled: isDisabled
            }) }
          />
        </div>
        <div className={css.row}>
          <FormField 
            label='Электронная почта:'
            placeholder='Не указана'
            error={errors.email?.message}
            register={register('email', {
              disabled: isDisabled,
              pattern: {
                value: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
                message: 'Некорректная почта'
              }
            }) }
          />
          <FormField 
            label='Номер телефона:'
            placeholder='Не указан'
            error={errors.phone?.message}
            register={register('phone', {
              disabled: isDisabled,
              pattern: {
                value: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
                message: 'Некорректный номер телефона'
              }
            }) } 
          />
        </div>
        <div className={css.row}>
          <label className={css.field}>
            <p className={css.label}>Адрес доставки по умолчанию:</p>
            <textarea 
              className={css.input}
              placeholder='Не указан' 
              rows={4}
              { ...register('place', {
                disabled: isDisabled
              }) } 
            />
          </label>
        </div>

        <div className={css.row}>
          {
            isDisabled
            ? (
              <Button
                className={css.button} style='accent' size='tall' text='Изменить данные'
                icon={ <EditIcon /> }
                onClick={changeDataHandler}
              />
            )
            : (
              <>
                <Button
                  className={css.button} style='accent' size='tall' text='Сохранить'
                  onClick={handleSubmit(saveChangeHandler)}
                  icon={ <CheckIcon /> }
                />
                <Button
                  className={css.buttonCancel} style='base' size='tall' text='Отменить'
                  onClick={cancelChangeHandler}
                  icon={ <CrossIcon /> }
                />
              </>
            )
          }
        </div>
      </form>
    </div>
  );
};
