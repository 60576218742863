import React from 'react';
import { FieldError, FieldErrorsImpl, Merge, UseFormRegisterReturn } from 'react-hook-form';
import { RequiredIcon } from 'resources/icons';
import css from './index.module.scss';

type TProps = {
  label: string,
  description?: string,
  placeholder?: string,
  required?: boolean,
  register: UseFormRegisterReturn<string>,
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>,
};

export const Textarea = ({ 
  label, description, placeholder, register, errorMessage, required = false
}: TProps) => {

  return (
    <label className={css.wrap}>
      <p className={css.name}>
        { required && <RequiredIcon className={css.icon} /> }
        { label }
      </p>
      { !!description && <p className={css.description}>{ description }</p> }
      <textarea
        className={css.input}
        placeholder={placeholder}
        { ...register } 
      />
      { !!errorMessage && <p className={css.error}>{ errorMessage?.toString() }</p> }
    </label>
  );
};
