import React from 'react';

type TProps = {
  className?: string
}

export const CrossIcon = ({ className = '' }: TProps) => (
  <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.7851 33.8388L27.9463 25L36.7851 16.1611L33.8388 13.2149L25 22.0537L16.1612 13.2149L13.2149 16.1611L22.0537 25L13.2149 33.8388L16.1612 36.7851L25 27.9463L33.8388 36.7851L36.7851 33.8388Z" fill="#D7D7D7"/>
  </svg>
);