import React, { useState } from 'react';
import { AdvantagesScreen, OffersScreen, OurWorksScreen, QuestionsScreen, SearchScreen } from './PageScreens';
import { Footer, Header, MainPageMetaTags } from 'features';
import css from './index.module.scss';

type TProps = {};

export const MainPage = ({  }: TProps) => {
  const [headerBgColor, setHeaderBgColor] = useState('#FFFFFF');
  
  return (
    <div className={css.wrap}>
      <div className={css.headerWrap}><Header /></div>
      <SearchScreen />
      <OffersScreen />
      <OurWorksScreen />
      <AdvantagesScreen />
      <QuestionsScreen />
      <Footer />

      {/* meta tags */}
      <MainPageMetaTags />
    </div>
  );
};
